import React from "react";
import { Button, ButtonGroup } from "react-bootstrap";

const CustomToggle = React.forwardRef(({ children, onClick, isAddFilter, onClose }, ref) => {
  const handleClick = (e) => {
    onClick(e);
  };

  if (isAddFilter) {
    return (
      <Button ref={ref} onClick={handleClick} variant="secondary">
        {children}
        <i className="fas fa-chevron-down"></i>
      </Button>
    );
  }

  return (
    <ButtonGroup>
      <Button ref={ref} onClick={handleClick} variant="">
        {children}
        <i className="fas fa-chevron-down"></i>
      </Button>
      <Button variant="" onClick={onClose}>
        <i className="ti-close"></i>
      </Button>
    </ButtonGroup>
  );
});

export default CustomToggle;
