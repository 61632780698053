import React, { useState, useEffect, useCallback } from "react";
import { RangeFilter, CheckboxTableFilter, CheckboxTableFilterCategory } from "components/Filters";

//Temp filter values are used to keep state until the user presses the "Apply" button. On "Apply" button press, the main filter state used in the request will be set by the onApply function, which will take temp filters state as input and set the main filters state
const ActiveFilters = ({
  activeFilters,
  simulationMessages,
  simulationCategories,
  requestFilterValues,
  onFilterClose,
  onApply,
  className,
  handleClearAllFilters,
}) => {
  const [filterValues, setFilterValues] = useState({
    reach: requestFilterValues.reach,
    leverageability: requestFilterValues.leverageability,
    adjustedLeverageability: requestFilterValues.adjustedLeverageability,
    //JSON.parse(JSON.stringify(object)) creates a deep copy of the provided object (slow but in this case it does not matter as for now there won't be more than 60-70 messages in the project at any time)
    messages: JSON.parse(JSON.stringify(simulationMessages)),
    categories: JSON.parse(JSON.stringify(simulationCategories)),
  });

  const parseCheckboxFilterValues = useCallback(
    ({ filterName }) => {
      switch (filterName) {
        case "messages":
          return simulationMessages.map((message) => ({
            ...message,
            isChecked: requestFilterValues.messageIds.split(",").includes(message.messageNumber.toString()),
          }));
        case "categories":
          return simulationCategories.map((category) => ({
            ...category,
            isChecked: requestFilterValues.categories.split(",").includes(category.categoryName),
          }));
        default:
          break;
      }
    },
    [simulationMessages, simulationCategories, requestFilterValues]
  );

  useEffect(() => {
    setFilterValues((prevState) => ({
      ...prevState,
      reach: requestFilterValues.reach,
      leverageability: requestFilterValues.leverageability,
      adjustedLeverageability: requestFilterValues.adjustedLeverageability,
    }));
  }, [requestFilterValues]);

  useEffect(() => {
    const messages = parseCheckboxFilterValues({ filterName: "messages" });
    const categories = parseCheckboxFilterValues({ filterName: "categories" });

    setFilterValues((prevState) => ({ ...prevState, messages, categories }));
  }, [parseCheckboxFilterValues]);

  const onDropdownClose = ({ filterName }) => {
    const resetFilterValue = parseCheckboxFilterValues({ filterName });

    setFilterValues((prevState) => ({ ...prevState, [filterName]: resetFilterValue }));
  };

  const handleFilterChange = (event) => {
    const eventTarget = event.target;
    let minValue;
    let maxValue;
    switch (eventTarget.name) {
      case "reachMin":
        if (eventTarget.value > 100) {
          minValue = 100;
        } else if (eventTarget.value >= 0) {
          minValue = eventTarget.value;
        } else {
          minValue = 0;
        }
        setFilterValues((prevState) => {
          return { ...prevState, reach: { ...prevState.reach, min: parseFloat(minValue) } };
        });
        break;
      case "reachMax":
        if (eventTarget.value > 100) {
          maxValue = 100;
        } else if (eventTarget.value >= 0) {
          maxValue = eventTarget.value;
        } else {
          maxValue = 1;
        }
        setFilterValues((prevState) => {
          return { ...prevState, reach: { ...prevState.reach, max: parseFloat(maxValue) } };
        });
        break;
      case "leverageabilityMin":
        if (eventTarget.value > 1000) {
          minValue = 1000;
        } else if (eventTarget.value >= 0) {
          minValue = eventTarget.value;
        } else {
          minValue = 0;
        }
        setFilterValues((prevState) => {
          return { ...prevState, leverageability: { ...prevState.leverageability, min: parseFloat(minValue) } };
        });
        break;
      case "leverageabilityMax":
        if (eventTarget.value > 1000) {
          maxValue = 1000;
        } else if (eventTarget.value >= 0) {
          maxValue = eventTarget.value;
        } else {
          maxValue = 1;
        }
        setFilterValues((prevState) => {
          return { ...prevState, leverageability: { ...prevState.leverageability, max: parseFloat(maxValue) } };
        });
        break;
      case "adj. leverageabilityMin":
        if (eventTarget.value > 1000) {
          minValue = 1000;
        } else if (eventTarget.value >= 0) {
          minValue = eventTarget.value;
        } else {
          minValue = 0;
        }
        setFilterValues((prevState) => {
          return { ...prevState, adjustedLeverageability: { ...prevState.adjustedLeverageability, min: parseFloat(minValue) } };
        });
        break;
      case "adj. leverageabilityMax":
        if (eventTarget.value > 1000) {
          maxValue = 1000;
        } else if (eventTarget.value >= 0) {
          maxValue = eventTarget.value;
        } else {
          maxValue = 1;
        }
        setFilterValues((prevState) => {
          return {
            ...prevState,
            adjustedLeverageability: { ...prevState.adjustedLeverageability, max: parseFloat(maxValue) },
          };
        });
        break;
      case "messages":
        let newMessages = [...filterValues.messages];
        newMessages = newMessages.filter((message) => {
          if (message.messageNumber === parseInt(eventTarget.value)) {
            message.isChecked = eventTarget.checked;
          }

          return message;
        });

        setFilterValues((prevState) => {
          return { ...prevState, messages: newMessages };
        });
        break;
      case "categories":
        let checkedCategory = eventTarget.value;
        //Toggle category checked state
        let newCategories = filterValues.categories.map((category) =>
          category.categoryName === checkedCategory ? { ...category, isChecked: !category.isChecked } : category
        );
        setFilterValues((prevState) => ({ ...prevState, categories: newCategories }));
        break;
      default:
        break;
    }
  };
  return (
    <div className={className}>
      {activeFilters.map((activeFilter) => {
        if (activeFilter === "reach") {
          return (
            <RangeFilter
              key={activeFilter}
              filterTitle={"Reach"}
              minValue={requestFilterValues[activeFilter].min}
              maxValue={requestFilterValues[activeFilter].max}
              onChange={handleFilterChange}
              onApply={() => onApply({ filterName: activeFilter, values: filterValues.reach })}
              onClose={() => onFilterClose({ filterName: activeFilter })}
              filterData={filterValues.reach}
            />
          );
        }
        if (activeFilter === "leverageability") {
          return (
            <RangeFilter
              key={activeFilter}
              filterTitle={"Leverageability"}
              minValue={requestFilterValues[activeFilter].min}
              maxValue={requestFilterValues[activeFilter].max}
              onChange={handleFilterChange}
              onApply={() => onApply({ filterName: activeFilter, values: filterValues.leverageability })}
              onClose={() => onFilterClose({ filterName: activeFilter })}
              filterData={filterValues.leverageability}
            />
          );
        }
        if (activeFilter === "adjustedLeverageability") {
          return (
            <RangeFilter
              key={activeFilter}
              filterTitle={"Adj. Leverageability"}
              minValue={requestFilterValues[activeFilter].min}
              maxValue={requestFilterValues[activeFilter].max}
              onChange={handleFilterChange}
              onApply={() => onApply({ filterName: activeFilter, values: filterValues.adjustedLeverageability })}
              onClose={() => onFilterClose({ filterName: activeFilter })}
              filterData={filterValues.adjustedLeverageability}
            />
          );
        }
        if (activeFilter === "messages") {
          const numberOfSelectedMessages = filterValues.messages.reduce((accumulator, message) => (message.isChecked ? accumulator + 1 : accumulator), 0);

          return (
            <CheckboxTableFilter
              key={activeFilter}
              name="messages"
              filterTitle={numberOfSelectedMessages === 1 ? "Message" : "Messages"}
              numberOfSelected={numberOfSelectedMessages}
              onChange={handleFilterChange}
              onApply={() => onApply({ filterName: activeFilter, values: filterValues.messages })}
              onClose={() => onFilterClose({ filterName: activeFilter })}
              filterData={filterValues.messages}
              tableHeaders={["Id", "Message"]}
              onDropdownClose={onDropdownClose}
            />
          );
        }
        if (activeFilter === "categories") {
          const numberOfSelectedCategories = filterValues.categories.reduce((accumulator, category) => (category.isChecked ? accumulator + 1 : accumulator), 0);
          return (
            <CheckboxTableFilterCategory
              key={activeFilter}
              name="categories"
              filterTitle={numberOfSelectedCategories === 1 ? "Category" : "Categories"}
              numberOfSelected={numberOfSelectedCategories}
              onChange={handleFilterChange}
              onApply={() => onApply({ filterName: activeFilter, values: filterValues.categories })}
              onClose={() => onFilterClose({ filterName: activeFilter })}
              filterData={filterValues.categories}
              tableHeaders={["Category"]}
              onDropdownClose={onDropdownClose}
            />
          );
        }
        return null;
      })}
      {activeFilters.length > 0 ? (
        <div className="cursor-pointer d-flex align-items-center mt-3" onClick={handleClearAllFilters}>
          <p className="text-primary text-underline">Clear all filters</p>
        </div>
      ) : null}
    </div>
  );
};

export default ActiveFilters;
