import React, { useState, useEffect } from "react";
import { Table, Spinner } from "react-bootstrap";
import { CustomButton } from "components/Button";
import { CustomInputField } from "components/Forms";
import classNames from "classnames";
import { changeQuotaGroupName } from "apis/projectApi";
import { ErrorText } from "components/Text";

export const QuotaGroupsUploadResultTableRow = ({ quotaGroup, draftId, handleQuotaGroupsData }) => {
  //Sets old quota group name for comparison
  const oldQuotaGroupName = quotaGroup.name;

  useEffect(() => {
    setQuotaGroupName(quotaGroup.name);
  }, [quotaGroup]);

  const [inputEnabled, setInputEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [quotaGroupName, setQuotaGroupName] = useState("");

  const handleInputChange = (event) => {
    setQuotaGroupName(event.target.value);
  };

  const handleButtonClick = () => {
    setInputEnabled(!inputEnabled);
    setErrorMessage("");
    if (quotaGroupName !== oldQuotaGroupName) {
      handleChangeQuotaGroupName();
    } else {
      setInputEnabled(!inputEnabled);
    }
  };

  const handleChangeQuotaGroupName = async () => {
    setIsLoading(true);
    let changeQuotaGroupNameResult = await changeQuotaGroupName({ draftId, quotaGroupId: quotaGroup.quotaGroupId, newQuotaGroupName: quotaGroupName });
    if (changeQuotaGroupNameResult.data) {
      switch (changeQuotaGroupNameResult.data.result) {
        case 0:
          handleQuotaGroupsData(changeQuotaGroupNameResult.data.quotaGroups);
          setInputEnabled(false);
          setIsLoading(false);
          setErrorMessage("");
          return;
        case 1:
          setErrorMessage("Undefined error");
          break;
        case 2:
          setErrorMessage("You are not authorized to delete data in this draft");
          break;
        case 3:
          setErrorMessage("The draft you are trying to delete data from does not exist");
          break;
        case 4:
          setErrorMessage("Error. Quota group does not exist");
          break;
        case 5:
          setErrorMessage("Changing Overall quota group is not allowed");
          break;
        default:
          break;
      }
      setQuotaGroupName(oldQuotaGroupName);
      //TODO: Handle axios error
      setIsLoading(false);
    }
  };

  const editButtonClasses = classNames("btn", inputEnabled ? "btn-primary" : "btn-secondary", "mt-0");

  return (
    <tr>
      <td>{quotaGroup.quotaGroupNumber}</td>
      <td>
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <CustomInputField value={quotaGroupName} disabled={!inputEnabled} handleInputChange={handleInputChange} />
            {errorMessage && <ErrorText text={errorMessage} />}
          </div>
          <div>
            <CustomButton className={editButtonClasses} onClick={handleButtonClick}>
              {!isLoading ? (
                <div>
                  <i className="fas fa-edit mr-2"></i>
                  {inputEnabled ? "Confirm" : "Edit"}
                </div>
              ) : (
                <Spinner size="sm" animation="border" />
              )}
            </CustomButton>
          </div>
        </div>
      </td>
    </tr>
  );
};

const QuotaGroupsUploadResultTable = ({ quotaGroups, handleQuotaGroupsData, draftId }) => {
  return (
    <Table responsive>
      <thead>
        <tr>
          <th>Quota Group ID</th>
          <th>Name</th>
        </tr>
      </thead>
      <tbody>
        {quotaGroups.map((quotaGroup, index) => {
          if (quotaGroup.quotaGroupNumber === 0) {
            return (
              <tr key={index}>
                <td>{quotaGroup.quotaGroupNumber}</td>
                <td>{quotaGroup.name}</td>
              </tr>
            );
          }
          return <QuotaGroupsUploadResultTableRow key={index} quotaGroup={quotaGroup} handleQuotaGroupsData={handleQuotaGroupsData} draftId={draftId} />;
        })}
      </tbody>
    </Table>
  );
};

export default QuotaGroupsUploadResultTable;
