import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { Row, Col, Button, Card, Tabs, Tab, Spinner } from "react-bootstrap";
import { getSimulations, deleteSimulation } from "apis/simulationApi";
import { EditProjectModal, ConfirmationModal } from "components/Modals";
import { deleteProject, getProjectById, changeProjectAccessStatus, changeProjectName, changeProjectPasswordAccess } from "apis/projectApi";
import { ToggleChartResults, ReachTable, ProjectMessagesTable, ProjectSimulationsTable, CustomEntriesDisplay, TablePagination } from "components/Tables";
import { useEffectOnce } from "react-use";
import { ErrorText } from "components/Text";
import { changeRoute } from "utility/routing";
import ROUTES from "constants/routes";
import { Chart } from "components/Chart";
import { CustomLink } from "components/CustomLink";
import { Helmet } from "react-helmet";
import { numberDisplayFormatting } from "utility/numberFormatting";
import { Avatar } from "components/Avatar";
import { useRouteId, useDebounceAfterMount } from "utility/hooks";

const ProjectSingle = ({ user }) => {
  const redirectTimeout = 5000;

  //Project data
  const projectId = useRouteId();
  const [project, setProject] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const userCanEditProject = user && user.currentUser && (user.currentUser.userType === 0 || user.currentUser.id === project.userId);

  //Edit project
  const [showEditProjectModal, setShowEditProjectModal] = useState(false);
  //Change project name
  const [changeProjectNameValue, setChangeProjectNameValue] = useState("");
  const [changeProjectNameIsLoading, setChangeProjectNameIsLoading] = useState(false);
  const [changeProjectNameErrorMessage, setChangeProjectNameErrorMessage] = useState("");

  useEffect(() => {
    setChangeProjectNameValue(project.name);
  }, [project]);

  const handleChangeProjectNameInput = (event) => {
    const inputValue = event.target.value;
    setChangeProjectNameValue(inputValue);
  };

  const handleChangeProjectName = async ({ projectId, newName }) => {
    setChangeProjectNameIsLoading(true);
    const response = await changeProjectName({ projectId, newName });
    setChangeProjectNameIsLoading(false);
    if (response.data) {
      switch (response.data.result) {
        case 0:
          setChangeProjectNameErrorMessage("");
          handleGetProject(projectId);
          break;
        case 1:
          setChangeProjectNameErrorMessage("Undefined error");
          break;
        case 2:
          setChangeProjectNameErrorMessage("You are not authorized to change the name of this project");
          break;
        case 3:
          setChangeProjectNameErrorMessage("Project does not exist");
          break;
        case 4:
          setChangeProjectNameErrorMessage("Project name cannot be empty");
          break;
        default:
          break;
      }
    } else {
      setChangeProjectNameErrorMessage("Server error");
    }
  };

  //Change project password access
  const [isProjectAccessLimited, setIsProjectAccessLimited] = useState(null);
  const [newPassword, setNewPassword] = useState("");
  const [projectAccessErrorMessage, setProjectAccessErrorMessage] = useState("");
  const [isToggleActive, setIsToggleActive] = useState(false);
  const [changeProjectPasswordAccessIsLoading, setChangeProjectPasswordAccessIsLoading] = useState(false);

  useEffect(() => {
    setIsProjectAccessLimited(project.isAccessLimited);
    setIsToggleActive(project.isAccessLimited);
  }, [project]);

  const handleToggleCheckbox = (event) => {
    const isChecked = event.target.checked;
    if (!isChecked) {
      setNewPassword("");
    }
    setIsToggleActive(isChecked);
  };

  const handleNewPasswordInputChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleChangeProjectPasswordAccess = async ({ projectId, newPassword }) => {
    setChangeProjectPasswordAccessIsLoading(true);
    const response = await changeProjectPasswordAccess({ projectId, newPassword, limitAccess: isToggleActive });
    setChangeProjectPasswordAccessIsLoading(false);
    if (response.data) {
      switch (response.data.result) {
        case 0:
          handleGetProject(projectId);
          setProjectAccessErrorMessage("");
          break;
        case 1:
          setProjectAccessErrorMessage("Failed to change project password access. Undefined error.");
          break;
        case 2:
          setProjectAccessErrorMessage("Failed to change project password access. You are not authorized to change the password access of this project.");
          break;
        case 3:
          setProjectAccessErrorMessage("Failed to change project password access. Project does not exist");
          break;
        case 4:
          setProjectAccessErrorMessage("Failed to change project password access. Password should be at least 5 characters long");
          break;
        case 5:
          setProjectAccessErrorMessage("Failed to change project password access. Password is the same as the existing password");
          break;
        default:
          break;
      }
    } else {
      setProjectAccessErrorMessage("Server errror");
    }
  };

  const handleEditModalClose = () => {
    setShowEditProjectModal(false);
    setIsToggleActive(isProjectAccessLimited);
    setChangeProjectNameErrorMessage("");
    setProjectAccessErrorMessage("");
  };
  //Delete project
  const [deleteIsLoading, setDeleteIsLoading] = useState(false);
  const [deleteProjectErrorMessage, setDeleteProjectErrorMessage] = useState("");
  const [showDeleteProjectConfirmationModal, setShowDeleteProjectConfirmationModal] = useState(false);
  const [isDeleteSuccessful, setIsDeleteSuccessful] = useState(false);

  //Change project access status
  const [changeProjectAccessStatusIsLoading, setChangeProjectAccessStatusIsLoading] = useState(false);
  const [changeProjectAccessStatusErrorMessage, setChangeProjectAccessStatusErrorMessage] = useState("");
  const handleChangeProjectAccessStatus = async () => {
    setChangeProjectAccessStatusIsLoading(true);
    let changeProjectAccessStatusResult = await changeProjectAccessStatus(projectId);
    setChangeProjectAccessStatusIsLoading(false);
    if (changeProjectAccessStatusResult.data) {
      switch (changeProjectAccessStatusResult.data.result) {
        case 0:
          handleGetProject(projectId);
          break;
        case 1:
          setChangeProjectAccessStatusErrorMessage("Failed to change project status. Undefined error.");
          break;
        case 2:
          setChangeProjectAccessStatusErrorMessage("Failed to change project status. You are not authorized to change the status of this project.");
          break;
        case 3:
          setChangeProjectAccessStatusErrorMessage("Failed to change project status. Project does not exist");
          break;
        default:
          break;
      }
    }
  };

  //Used for toggling between weighted/unweighted results in chart and table
  const [showWeightedResults, setShowWeightedResults] = useState(true);
  const [chartData, setChartData] = useState([]);

  //Simulations table state
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [totalSimulationsCount, setTotalSimulationsCount] = useState(null);
  const [simulations, setSimulations] = useState([]);
  const [orderIsDescending, setOrderIsDescending] = useState(true);
  const [activeColumn, setActiveColumn] = useState(2);
  const [simulationsTableIsLoading, setSimulationsTableIsLoading] = useState(false);

  const handleGetSimulations = async ({ projectId, page, pageSize, sortOrder, sortBy }) => {
    let getSimulationsResult = await getSimulations(projectId, page, pageSize, sortOrder, sortBy);
    setSimulationsTableIsLoading(false);
    if (getSimulationsResult.data) {
      switch (getSimulationsResult.data.result) {
        case 0:
          setSimulations(getSimulationsResult.data.items);
          setPage(getSimulationsResult.data.page);
          setPageSize(getSimulationsResult.data.pageSize);
          setTotalSimulationsCount(getSimulationsResult.data.count);
          break;
        case 1:
          setErrorMessage("Undefined error");
          break;
        case 2:
          setErrorMessage("Project does not exist");
          break;
        case 3:
          setErrorMessage("You are not authorized to access simulations in this project.");
          break;
        case 4:
          setErrorMessage(`This account has been deleted. You will be redirected to the login screen in ${redirectTimeout} seconds`);
          break;
        case 5:
          setErrorMessage("Page parameter error");
          break;
        case 6:
          setErrorMessage("Page size parameter error");
          break;
        default:
          break;
      }
    }
  };

  const handleModalOpen = (setShowModal) => {
    setShowModal(true);
  };

  const handleModalClose = (setShowModal, setErrorMessage, getSimulations) => {
    setShowModal(false);
    setErrorMessage("");
    if (getSimulations) {
      handleGetSimulations({ projectId, page, pageSize, sortOrder: orderIsDescending, sortBy: activeColumn });
    }
  };

  const handleDeleteSimulation = async (simulationId, setIsLoading, setIsDeleteSuccessful, setErrorMessage) => {
    setIsLoading(true);
    let deleteSimulationResult = await deleteSimulation(simulationId);
    setIsLoading(false);
    if (deleteSimulationResult.data) {
      switch (deleteSimulationResult.data.result) {
        //Draft deleted successfully -> new project is created and user is routed to first step of the wizard
        case 0:
          setIsDeleteSuccessful(true);
          break;
        case 1:
          setErrorMessage("You are not authorized to delete this simulation");
          break;
        case 2:
          //TODO: Maybe logout and reroute the user to a special screen saying their account does not exist anymore?
          //TODO: Create a function
          setErrorMessage(`This account has been deleted. You will be redirected to the login screen in ${redirectTimeout} seconds`);
          break;
        case 3:
          setErrorMessage("The project you are trying to delete does not exist");
          break;
        case 4:
          setErrorMessage("Undefined error");
          break;
        default:
          break;
      }
    }
  };

  const handlePageChange = (goToPage) => {
    setPage(goToPage);
    handleGetSimulations({ projectId, page: goToPage, pageSize, sortOrder: orderIsDescending, sortBy: activeColumn });
  };

  //Initial fetch of simulations data with default values and ordering
  useEffectOnce(() => {
    handleGetSimulations({ projectId, page, pageSize, sortOrder: orderIsDescending, sortBy: activeColumn });
  });

  const handleSelectSortingOrder = ({ selectedColumn }) => {
    setSimulationsTableIsLoading(true);
    //First part of if statement toggles the arrows on the selected column
    if (activeColumn === selectedColumn) {
      // sortProjects(activeColumn, !orderIsDescending, projects);
      setOrderIsDescending(!orderIsDescending);
    }
    //This sets the default arrow to ASCENDING when the focus is changed to another column
    else {
      // sortProjects(selectedColumn, false, projects);
      setActiveColumn(selectedColumn);
      setOrderIsDescending(false);
    }
  };

  const [,] = useDebounceAfterMount(
    () => {
      handleGetSimulations({ projectId, page, pageSize, sortOrder: orderIsDescending, sortBy: activeColumn });
    },
    1000,
    [activeColumn, orderIsDescending]
  );

  const handleDeleteProject = async (projectId) => {
    setDeleteIsLoading(true);
    let deleteProjectResult = await deleteProject(projectId);
    setDeleteIsLoading(false);
    if (deleteProjectResult.data) {
      switch (deleteProjectResult.data.result) {
        //Draft deleted successfully -> new project is created and user is routed to first step of the wizard
        case 0:
          setIsDeleteSuccessful(true);
          setTimeout(() => changeRoute(ROUTES.projects), redirectTimeout);
          break;
        case 1:
          setDeleteProjectErrorMessage("You are not authorized to delete this project");
          break;
        case 2:
          //TODO: Maybe logout and reroute the user to a special screen saying their account does not exist anymore?
          //TODO: Create a function
          setDeleteProjectErrorMessage(`This account has been deleted. You will be redirected to the login screen in ${redirectTimeout} seconds`);
          break;
        case 3:
          setDeleteProjectErrorMessage("The project you are trying to delete does not exist");
          break;
        case 4:
          setDeleteProjectErrorMessage("Undefined error");
          break;
        default:
          break;
      }
    }
  };
  const handleToggleResults = (value) => {
    setShowWeightedResults(value);
  };

  const handleGetProject = async (projectId) => {
    let getProjectByIdResult = await getProjectById(projectId);
    if (getProjectByIdResult.data) {
      switch (getProjectByIdResult.data.result) {
        case 0:
          setProject(getProjectByIdResult.data);
          break;
        //TODO: Add cases
        default:
          break;
      }
    }
  };
  useEffect(() => {
    handleGetProject(projectId);
  }, [projectId]);

  const generateChartData = useMemo(() => {
    if (!project) return;
    //Generate data arrays used for updating the chart when the user toggles the "Weighted/Unweighted" button
    let weightedData = [];
    let unweightedData = [];
    project.reachReportChartData.quotaGroups.forEach((quotaGroup) => {
      quotaGroup.weightedReachReportResults = quotaGroup.weightedReachReportResults.map((score) => {
        score = numberDisplayFormatting({ number: score, numberOfDecimalPlaces: 2 });
        return score;
      });
      weightedData.push(quotaGroup.weightedReachReportResults);
      quotaGroup.unweightedReachReportResults = quotaGroup.unweightedReachReportResults.map((score) => {
        score = numberDisplayFormatting({ number: score, numberOfDecimalPlaces: 2 });
        return score;
      });
      unweightedData.push(quotaGroup.unweightedReachReportResults);
    });
    return { weightedData, unweightedData };
  }, [project]);

  const generateInitialChartDataSets = useMemo(() => {
    if (!project) return;
    //TODO: Check with Dennis to maybe select 20-30 colors to use in graphs in situations with a large number of quota groups
    const colors = ["#f3c74d", "#00b068", "pink", "#4ac7ec", "#1c2d41", "#874df3", "#e95151", "#7286a2"];
    let initialDataset = [];
    project.reachReportChartData.quotaGroups.forEach((quotaGroup, index) => {
      initialDataset.push({
        label: quotaGroup.name,
        //Chart displays weighted values by default
        data: quotaGroup.weightedReachReportResults,
        backgroundColor: colors[index],
        borderColor: colors[index],
        fill: false,
      });
    });
    return initialDataset;
  }, [project]);

  useEffect(() => {
    if (!project) return;
    const { weightedData, unweightedData } = generateChartData;
    showWeightedResults ? setChartData(weightedData) : setChartData(unweightedData);
  }, [showWeightedResults, project, generateChartData]);

  return (
    <>
      <Helmet title={`${project && project.name}`} />
      {showDeleteProjectConfirmationModal && (
        <ConfirmationModal
          showModal={showDeleteProjectConfirmationModal}
          isLoading={deleteIsLoading}
          errorMessage={errorMessage}
          onCancel={() => handleModalClose(setShowDeleteProjectConfirmationModal, setErrorMessage)}
          onConfirm={() => handleDeleteProject(projectId, setDeleteIsLoading, setIsDeleteSuccessful, setErrorMessage)}
          success={isDeleteSuccessful}
          confirmationMessage="Yes, delete it!"
          questionMessage="Are you sure?"
          warningMessage="You won't be able to revert this!"
          errorTitle="Error"
          successTitle="Deleted!"
          successSubtitle={`Project successfully queued for deletion. You will be redirected to the projects list in ${redirectTimeout / 1000} seconds`}
        />
      )}
      {userCanEditProject && showEditProjectModal && (
        <EditProjectModal
          showModal={showEditProjectModal}
          onClose={handleEditModalClose}
          //Change project name
          changeProjectNameValue={changeProjectNameValue}
          editProjectErrorMessage={changeProjectNameErrorMessage}
          handleChangeProjectNameInput={handleChangeProjectNameInput}
          handleChangeProjectName={() => handleChangeProjectName({ projectId, newName: changeProjectNameValue })}
          changeProjectNameIsLoading={changeProjectNameIsLoading}
          //Change project password access
          isProjectAccessLimited={isProjectAccessLimited}
          projectAccessErrorMessage={projectAccessErrorMessage}
          isToggleActive={isToggleActive}
          handleToggleCheckbox={handleToggleCheckbox}
          newPasswordValue={newPassword}
          handleChangeProjectPasswordAccess={() => handleChangeProjectPasswordAccess({ projectId, newPassword })}
          handleNewPasswordInputChange={handleNewPasswordInputChange}
          changeProjectPasswordAccessIsLoading={changeProjectPasswordAccessIsLoading}
        />
      )}
      <div className="container-fluid">
        <Row>
          <Col sm={12}>
            <div className="page-title-box">
              <div className="float-right">
                <ol className="breadcrumb">
                  {project ? (
                    <>
                      <li className="breadcrumb-item active">
                        <CustomLink href={ROUTES.projects}>Projects</CustomLink>
                      </li>
                      <li className="breadcrumb-item active">{project.name}</li>
                    </>
                  ) : (
                    <Spinner animation="border" variant="primary" size="sm" />
                  )}
                </ol>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Row>
                  {project ? (
                    <>
                      <Col sm={6}>
                        <div className="inline-buttons">
                          <Avatar name={project.name} size="lg"></Avatar>

                          <h4 className="page-title">
                            {project.name}
                            {project.status === 100 && <span className="text-danger"> (Closed)</span>}
                          </h4>
                        </div>
                      </Col>
                      {user && user.currentUser && user.currentUser.userType !== 2 && (
                        <Col sm={6} className="d-flex justify-content-end align-items-center">
                          <ErrorText text={deleteProjectErrorMessage} className="mb-0 mr-3" />
                          <ErrorText text={changeProjectAccessStatusErrorMessage} className="mb-0 mr-3" />
                          <div className="inline-buttons">
                            {userCanEditProject && (
                              <Button variant="btn btn-secondary" className="mr-3" onClick={() => handleModalOpen(setShowEditProjectModal)}>
                                Edit
                              </Button>
                            )}

                            <Button variant="btn btn-secondary" className="mr-3" onClick={() => handleChangeProjectAccessStatus(projectId)}>
                              {changeProjectAccessStatusIsLoading ? (
                                <Spinner animation="border" size="sm" />
                              ) : project.status !== 100 ? (
                                "Close Project"
                              ) : (
                                "Reopen Project"
                              )}
                            </Button>
                            <Button variant="btn btn-secondary" onClick={() => setShowDeleteProjectConfirmationModal(true)}>
                              {deleteIsLoading ? <Spinner animation="border" size="sm" /> : "Delete"}
                            </Button>
                          </div>
                        </Col>
                      )}
                    </>
                  ) : (
                    <Spinner animation="border" variant="primary" />
                  )}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col sm={9}>
            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <h5 className="float-left">Reach report</h5>
                    <div className="float-right eco-revene-history justify-content-end">
                      {project ? (
                        <ToggleChartResults showWeightedResults={showWeightedResults} handleToggleResults={handleToggleResults} />
                      ) : (
                        <Spinner animation="border" variant="primary" />
                      )}
                    </div>

                    <div className="clearfix"></div>
                    <Tabs className="tab-content tabs tabs__header m-0" defaultActiveKey="chart">
                      <Tab eventKey="chart" title="Chart">
                        <div className="tab-pane pt-3">
                          {project ? (
                            <Chart
                              chartType="line"
                              initialChartData={generateInitialChartDataSets}
                              chartData={chartData}
                              displayLegend={true}
                              legendPosition="top"
                              legendAlignment="center"
                              legendLabelBoxWidth={40}
                              maxMessageSet={project && project.maxMessageSet}
                            />
                          ) : (
                            <div className="d-flex justify-content-center">
                              <Spinner animation="border" variant="primary" />
                            </div>
                          )}
                        </div>
                      </Tab>
                      <Tab eventKey="table" title="Table">
                        <div className="tab-pane p-3">
                          {project ? (
                            <ReachTable tableData={project.reachReportTableData} weighted={showWeightedResults} />
                          ) : (
                            <div className="d-flex justify-content-center">
                              <Spinner animation="border" variant="primary" />
                            </div>
                          )}
                        </div>
                      </Tab>
                    </Tabs>
                  </Card.Body>
                </Card>

                <Card>
                  <Card.Body>
                    <div className="inline-buttons">
                      <h5>Simulations</h5>
                      {project && (
                        <span className="d-inline-block">
                          <div className="d-flex justify-content-between align-items-center">
                            {project.status !== 100 && (
                              <Button className="btn btn-primary px-4 mt-0 ml-2" onClick={() => changeRoute(ROUTES.createSimulation(projectId))}>
                                <i className="mdi mdi-plus-circle-outline mr-2"></i>
                                New Simulation
                              </Button>
                            )}
                          </div>
                        </span>
                      )}
                    </div>
                    {project ? (
                      <ProjectSimulationsTable
                        data={simulations}
                        projectId={projectId}
                        projectStatus={project.status}
                        activeColumn={activeColumn}
                        orderIsDescending={orderIsDescending}
                        handleDeleteSimulation={handleDeleteSimulation}
                        onModalOpen={handleModalOpen}
                        onModalClose={handleModalClose}
                        handleSelectSortingOrder={handleSelectSortingOrder}
                        tableIsLoading={simulationsTableIsLoading}
                      />
                    ) : (
                      <div className="d-flex justify-content-center">
                        <Spinner animation="border" variant="primary" />
                      </div>
                    )}
                    <div className="d-flex justify-content-between">
                      <CustomEntriesDisplay totalEntries={totalSimulationsCount} page={page} pageSize={pageSize} />

                      <TablePagination
                        currentPage={page}
                        pageSize={pageSize}
                        sortingOrder={orderIsDescending}
                        sortBy={activeColumn}
                        totalEntries={totalSimulationsCount}
                        onChange={handlePageChange}
                      />
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col sm={3}>
            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <Row>
                      <Col>
                        {project ? (
                          <>
                            <h4>{project.messages.length} messages in this project</h4>
                            <ProjectMessagesTable messages={project.messages} />
                          </>
                        ) : (
                          <div className="d-flex justify-content-center">
                            <Spinner animation="border" variant="primary" />
                          </div>
                        )}
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({ user: state.user });
export default connect(mapStateToProps)(ProjectSingle);
