import React, { useState, useRef, useEffect } from "react";
import { Row, Col, ProgressBar, Spinner } from "react-bootstrap";
import { FileUploadErrors } from "components/Wizards";
import { DropzoneCreateProject } from "components/Forms";
import { ReachUploadResultTable } from "components/Tables";
import { DeleteButton } from "components/Button";
import { uploadReach, deleteReach } from "apis/projectApi";
import { useScrollToElement } from "utility/hooks";

const ReachStep = ({ reachData, draftId, handleReachData }) => {
  const tableHeader = reachData[0] ? [reachData[0].reachThreshold + "%", reachData[0].secondCell, reachData[0].thirdCell, ...reachData[0].messageNumbers] : [];
  const tableRows = reachData[1];
  const redirectTimeout = 5000;
  const [fileIsUploading, setFileIsUploading] = useState(false);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [fileName, setFileName] = useState("");
  const [fileSize, setFileSize] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleFileUpload = async (file, draftId) => {
    setFileIsUploading(true);
    setShowProgressBar(true);
    let uploadReachResult = await uploadReach(file, draftId);
    setFileIsUploading(false);
    if (uploadReachResult.data) {
      const errorCellAddress = uploadReachResult.data.errorCellAddress;
      const errorValue = uploadReachResult.data.errorValue;
      switch (uploadReachResult.data.result) {
        case 0:
          handleReachData(uploadReachResult.data.reach);
          break;
        case 1:
          setErrorMessage("File not uploaded.");
          break;
        case 2:
          setErrorMessage("You are not authorized to upload a file to this draft.");
          break;
        case 3:
          setErrorMessage(`This account has been deleted. You will be redirected to the login screen in ${redirectTimeout} seconds`);
          break;
        case 4:
          setErrorMessage("Undefined error");
          break;
        case 5:
          setErrorMessage("File you are trying to upload is too large. File size cannot exceed 4MB");
          break;
        case 6:
          setErrorMessage("File upload failed");
          break;
        case 7:
          setErrorMessage("The draft you are trying to upload files to does not exist.");
          break;
        case 8:
          setErrorMessage("Reach file has already been uploaded");
          break;
        case 9:
          setErrorMessage(`Reach threshold at cell ${errorCellAddress} is missing`);
          break;
        case 10:
          setErrorMessage(`Reach threshold format error at cell ${errorCellAddress}`);
          break;
        case 11:
          setErrorMessage(`Header title at cell ${errorCellAddress} is incorrect. The value that triggered the error is: ${errorValue}.`);
          break;
        case 12:
          setErrorMessage(`Message number at cell ${errorCellAddress} is missing.`);
          break;
        case 13:
          setErrorMessage(
            `Message format error at cell ${errorCellAddress}. The value that triggered the error is: ${errorValue}.  Message values should be integers only.`
          );
          break;
        case 14:
          setErrorMessage(`Wrong number of messages in the file. The file should contain ${errorValue} message columns.`);
          break;
        case 15:
          setErrorMessage(
            `Message order error at cell ${errorCellAddress}. The value that triggered the error is: ${errorValue}.  Messages should be ordered in ascending order.`
          );
          break;
        case 16:
          setErrorMessage(`Respondent ID at cell ${errorCellAddress} is missing.`);
          break;
        case 17:
          setErrorMessage(`Respondent ID at cell ${errorCellAddress} is a duplicate. Duplicate value is ${errorValue}. Respondent IDs must be unique`);
          break;
        case 18:
          setErrorMessage(`Quota group at cell ${errorCellAddress} is a duplicate. Duplicate value is ${errorValue}. Quota group values must be unique`);
          break;
        case 19:
          setErrorMessage(
            `Quota group format error at cell ${errorCellAddress}. The value that triggered the error is: ${errorValue}.  Quota group values should be integers only.`
          );
          break;
        case 20:
          setErrorMessage(`Quota group at cell ${errorCellAddress} does not exist in the project. The value that triggered the error is: ${errorValue}.`);
          break;
        case 21:
          setErrorMessage(
            `Weight format error at cell ${errorCellAddress}. The value that triggered the error is: ${errorValue}.  Weight values should be numerics only.`
          );
          break;
        case 22:
          setErrorMessage(`Reach score at cell ${errorCellAddress} is empty. Reach score should be 0 or 1 only.`);
          break;
        case 23:
          setErrorMessage(
            `Reach score format error at cell ${errorCellAddress}. The value that triggered the error is: ${errorValue}.  Reach score should be 0 or 1 only.`
          );
          break;
        case 24:
          setErrorMessage(`The following quota groups were not found in the Reach file: ${errorValue}.`);
          break;
        case 25:
          setErrorMessage(`Wrong file type. File worksheet name is supposed to be called "Leveragability Upload". Uploaded worksheet name: "${errorValue}"`);
          break;
        default:
          break;
      }
    }
  };

  const handleDeleteReach = async () => {
    setIsLoading(true);
    let deleteReachResult = await deleteReach(draftId);
    if (deleteReachResult.data) {
      switch (deleteReachResult.data.result) {
        case 0:
          handleReachData();
          setShowProgressBar(false);
          setErrorMessage("");
          break;
        case 1:
          setErrorMessage("You are not authorized to delete a file in this draft");
          break;
        case 2:
          setErrorMessage(`This account has been deleted. You will be redirected to the login screen in ${redirectTimeout} seconds`);
          break;
        case 3:
          setErrorMessage("The file you are trying to delete does not exits");
          break;
        case 4:
          setErrorMessage("The draft you are trying to upload files to does not exist");
          break;
        case 5:
          setErrorMessage("Undefined error");
          break;
        default:
          break;
      }
    }
    setIsLoading(false);
  };

  const handleFileInfo = (fileName, fileSize) => {
    setFileName(fileName);
    if (fileSize >= 1000000) {
      setFileSize(`${Math.ceil(fileSize / 1000000).toFixed(2)} MB`);
    } else {
      setFileSize(`${Math.ceil(fileSize / 1000)} KB`);
    }
  };

  const handleErrorMessages = (errorMessage) => {
    if (!errorMessage) {
      setErrorMessage("");
    } else {
      setErrorMessage(errorMessage);
    }
  };

  //If current step data exists -> display progress bar and the delete icon
  useEffect(() => {
    reachData.length > 0 ? setShowProgressBar(true) : setShowProgressBar(false);
  }, [reachData]);

  const progressBarRef = useRef(null);
  useScrollToElement({ elementRef: progressBarRef, startScroll: reachData.length > 0, offset: 100 });

  return (
    <div className="pt-4">
      <div className="pt-5">
        {reachData.length < 1 && !showProgressBar && (
          <DropzoneCreateProject
            text="reach data"
            handleFileUpload={handleFileUpload}
            handleFileInfo={handleFileInfo}
            draftId={draftId}
            handleErrorMessages={handleErrorMessages}
            setShowProgressBar={setShowProgressBar}
          />
        )}

        {(reachData.length > 0 || showProgressBar) && (
          <div className="pt-5">
            <Row>
              <Col sm={11}>
                <div ref={progressBarRef}>
                  <ProgressBar now={fileIsUploading ? 65 : 100} label={fileIsUploading ? "Uploading" : "Upload complete"} />
                </div>
              </Col>
              <Col sm={1} className="d-flex align-items-center">
                {isLoading ? <Spinner animation="border" size="sm" variant="danger" /> : <DeleteButton className="font-18" onClick={handleDeleteReach} />}
              </Col>
            </Row>
            {showProgressBar && fileName !== "" && (
              <Row className="pt-2 pb-5">
                <Col sm={11} className="d-flex justify-content-end">
                  <p>{`${fileName} - ${fileSize} total`}</p>
                </Col>
              </Row>
            )}
          </div>
        )}
        {fileIsUploading && (
          <div className="d-flex justify-content-center">
            <Spinner size="lg" animation="border" variant="primary" />
          </div>
        )}
        {reachData.length > 0 && <ReachUploadResultTable headerRow={tableHeader} tableRows={tableRows} />}
        {errorMessage.length > 0 && <FileUploadErrors errorMessage={errorMessage} />}
      </div>
    </div>
  );
};

export default ReachStep;
