import { typeSimulation } from "constants/actionTypes";
import { getQueuedFilesByUser } from "apis/simulationApi";

export const openDownloadPanel = () => {
  return async (dispatch) => {
    dispatch({
      type: typeSimulation.openDownloadPanel,
      payload: {
        isOpen: true,
      },
    });
  };
};

export const closeDownloadPanel = () => {
  return async (dispatch) => {
    dispatch({
      type: typeSimulation.closeDownloadPanel,
      payload: {
        isOpen: false,
      },
    });
    dispatch({
      type: typeSimulation.setFileListErrorMessage,
      payload: {
        fileListErrorMessage: "",
      },
    });
  };
};

export const getDownloadFilesQueue = () => {
  return async (dispatch) => {
    let result = await getQueuedFilesByUser();
    if (result.data) {
      switch (result.data.result) {
        case 0:
          if (result.data.result.length === 0) {
            dispatch({
              type: typeSimulation.closeDownloadPanel,
              payload: {
                isOpen: false,
              },
            });
          }
          dispatch({
            type: typeSimulation.setDownloadFileList,
            payload: {
              files: result.data.files,
            },
          });
          break;
        case 1:
          dispatch({
            type: typeSimulation.setFileListErrorMessage,
            payload: {
              fileListErrorMessage: "Undefined error",
            },
          });
          break;
        default:
          break;
      }
    } else {
      dispatch({
        type: typeSimulation.setDownloadFileList,
        payload: {
          files: null,
        },
      });
      dispatch({
        type: typeSimulation.setFileListErrorMessage,
        payload: {
          fileListErrorMessage: "Undefined error",
        },
      });
    }
  };
};

export const setFileDownloadList = (files) => {
  return async (dispatch) => {
    dispatch({
      type: typeSimulation.setDownloadFileList,
      payload: {
        files: files,
      },
    });
  };
};

export const setFileListErrorMessage = ({ fileListErrorMessage }) => {
  return async (dispatch) => {
    dispatch({
      type: typeSimulation.setFileListErrorMessage,
      payload: {
        fileListErrorMessage: fileListErrorMessage,
      },
    });
  };
};
