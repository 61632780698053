import { typeAdmin } from "constants/actionTypes";

const initialState = {
  users: {
    items: [],
    count: null,
    page: 1,
    pageSize: 10,
  },
  response: {
    result: null,
    errorMessage: "",
  },
  loading: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    //GET USERS
    case typeAdmin.getUsersStart:
      return {
        ...state,
        loading: true,
      };
    case typeAdmin.getUsersEnd:
      return {
        ...state,
        users: {
          ...state.users,
          items: payload.users,
          count: payload.count,
          page: payload.page,
          pageSize: payload.pageSize,
        },
        loading: false,
      };
    case typeAdmin.getUsersError:
      return {
        ...state,
        response: {
          result: payload.response.result,
          errorMessage: payload.response.errorMessage,
        },
        loading: false,
      };
    //CHANGE USER APPROVAL
    case typeAdmin.changeUserApprovalStart:
      return {
        ...state,
        loading: true,
      };
    case typeAdmin.changeUserApprovalEnd:
      return {
        ...state,
        loading: false,
      };
    case typeAdmin.changeUserApprovalError:
      return {
        ...state,
        response: {
          result: payload.response.result,
          errorMessage: payload.response.errorMessage,
        },
        loading: false,
      };
    //ADD USER
    case typeAdmin.addUserStart:
      return {
        ...state,
        loading: true,
      };
    case typeAdmin.addUserEnd:
      return {
        ...state,
        response: {
          result: payload.response.result,
        },
        loading: false,
      };
    case typeAdmin.addUserError:
      return {
        ...state,
        response: {
          result: payload.response.result,
          errorMessage: payload.response.errorMessage,
        },
        loading: false,
      };
    //EDIT USER
    case typeAdmin.editUserStart:
      return {
        ...state,
        loading: true,
      };
    case typeAdmin.editUserEnd:
      return {
        ...state,
        response: {
          result: payload.response.result,
        },
        loading: false,
      };
    case typeAdmin.editUserError:
      return {
        ...state,
        response: {
          result: payload.response.result,
          errorMessage: payload.response.errorMessage,
        },
        loading: false,
      };
    //RESET RESPONSE
    case typeAdmin.resetResponse:
      return {
        ...state,
        response: {
          result: null,
          errorMessage: "",
        },
      };
    default:
      break;
  }
  return state;
};
