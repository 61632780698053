import React from "react";
import { Button, Table } from "react-bootstrap";

const ReviewAndCompleteTableRow = ({ sectionTitle, text, handleSelectStep, step }) => {
  return (
    <tr>
      <td>{sectionTitle}</td>
      <td className="d-flex justify-content-between">
        {text} <Button onClick={() => handleSelectStep(step)}>View/Edit</Button>
      </td>
    </tr>
  );
};

const ReviewAndCompleteTable = ({ numberOfMessages, numberOfQuotaGroups, numberOfRespondents, handleSelectStep }) => {
  return (
    <Table responsive>
      <thead>
        <tr>
          <th>Step</th>
          <th>Details</th>
        </tr>
      </thead>
      <tbody>
        <ReviewAndCompleteTableRow sectionTitle="Messages" text={`${numberOfMessages} messages uploaded`} handleSelectStep={handleSelectStep} step={1} />
        <ReviewAndCompleteTableRow
          sectionTitle="Quota Groups"
          text={`Data for ${numberOfQuotaGroups} quota groups uploaded`}
          handleSelectStep={handleSelectStep}
          step={2}
        />
        <ReviewAndCompleteTableRow
          sectionTitle="Leverageability"
          text={`${numberOfQuotaGroups} leverageability files uploaded`}
          handleSelectStep={handleSelectStep}
          step={3}
        />
        <ReviewAndCompleteTableRow
          sectionTitle="Reach"
          text={`Data for ${numberOfRespondents} respondents uploaded`}
          handleSelectStep={handleSelectStep}
          step={4}
        />
      </tbody>
    </Table>
  );
};

export default ReviewAndCompleteTable;
