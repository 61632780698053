import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { CustomLink } from "components/CustomLink";
import { Button, Card } from "react-bootstrap";
import { userLogin } from "actions/userAuthenticationActions";
import ROUTES from "constants/routes";

// whole user state is available here because of connect(), also userLogin method is available
// be sure to use React Dev Tools if you are not sure what props you have here
// Destructure props only if the components doesn't mimic an API response
// If the component mimics API response, you can just add single prop for that response data to avoid having to update components all over the app if the response changes
const Home = ({ user }) => {
  return (
    <>
      <h1>Compel Web App</h1>
      <CustomLink href={ROUTES.testPage}>Another Route</CustomLink>
      <Button onClick={userLogin} disabled={user.authenticationInProgress}>
        {!user.authenticationInProgress ? "Test Redux" : "Authentication In Progress"}
      </Button>
      <hr />
      <Card variant="top" style={{ width: "18rem" }}>
        <Card.Img />
        <Card.Body>
          <Card.Title>Card Title</Card.Title>
          <Card.Text>Some quick example text to build on the card title and make up the bulk of the card's content.</Card.Text>
          <Button variant="primary">Go somewhere</Button>
        </Card.Body>
      </Card>
      <CustomLink href={ROUTES.login}>
        <Button>Login</Button>
      </CustomLink>
      <CustomLink href={ROUTES.register}>
        <Button>Register</Button>
      </CustomLink>
    </>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ userLogin }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
