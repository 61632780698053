import React, { useState, useEffect } from "react";
import { CustomMenu, CustomToggle } from "components/Filters";
import { Dropdown, Row, Col, FormControl, Button } from "react-bootstrap";

const RangeFilter = ({ filterTitle, minValue, maxValue, onChange, onClose, onApply, filterData }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    switch (filterTitle) {
      case "Reach":
        if (minValue === 0 && maxValue === 100) {
          setShowDropdown(true);
        }
        break;
      case "Leverageability" || "Adj. Leverageability":
        if (minValue === 0 && maxValue === 1000) {
          setShowDropdown(true);
        }
        break;
      default:
        break;
    }
  }, [filterTitle, minValue, maxValue]);

  const onDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <Dropdown className="mx-2 my-2 custom-select-dropdown" show={showDropdown} onToggle={onDropdownToggle}>
      <Dropdown.Toggle onClose={onClose} as={CustomToggle}>
        {`${filterTitle} ${minValue}${minValue >= 0 || maxValue >= 0 ? "-" : ""}${maxValue} `}
      </Dropdown.Toggle>

      <Dropdown.Menu as={CustomMenu} backgroundClassName="select-filter-background" listClassName="select-filter__list">
        <Row>
          <Col sm={6}>
            <span>Min</span>
            <FormControl
              autoFocus
              onFocus={(event) => event.target.select()}
              onChange={onChange}
              name={`${filterTitle.toLowerCase()}Min`}
              value={filterData.min}
            />
          </Col>
          <Col sm={6}>
            <span>Max</span>
            <FormControl onFocus={(event) => event.target.select()} onChange={onChange} name={`${filterTitle.toLowerCase()}Max`} value={filterData.max} />
            <div className="d-flex justify-content-end">
              <Button
                className="mt-3"
                variant="outline-primary"
                onClick={() => {
                  onApply();
                  onDropdownToggle();
                }}
              >
                Apply
              </Button>
            </div>
          </Col>
        </Row>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default RangeFilter;
