import React from "react";
import classNames from "classnames";

const ErrorText = ({ text, hasIcons, className }) => {
  if (hasIcons) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <i className="fas fa-times text-danger pr-1"></i>
        <p className="text-danger pt-3">{text}</p>
      </div>
    );
  }
  const classesString = classNames("text-danger", "text-center", className);

  return <span className={classesString}>{text}</span>;
};

export default ErrorText;
