import React from "react";
import { Table } from "react-bootstrap";

const ReachUploadResultTable = ({ headerRow, tableRows }) => {
  return (
    <Table responsive>
      <thead>
        <tr>
          {headerRow.map((columnHeader, index) => (
            <th key={index}>{columnHeader}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableRows.map((tableRow) => (
          <tr key={tableRow.respondentId}>
            <td>{tableRow.respondentId}</td>
            <td>{tableRow.respondentQuotaGroups.join(",")}</td>
            <td>{tableRow.respondentWeight.toFixed(2)}</td>
            {tableRow.reachScores.map((reachScore, index) => (
              <td key={"reachScore" + index}>{reachScore}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default ReachUploadResultTable;
