import React, { useCallback, useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ROUTES from "constants/routes";
import { UsersTable, CustomEntriesDisplay, TablePagination, CustomEntriesSelect } from "components/Tables";
import AddNewUserModal from "components/Modals/AddNewUserModal";
import { getUsers, deleteUser } from "apis/adminApi";
import { Row, Button, Col } from "react-bootstrap";
import { adminResetResponse } from "actions/adminActions";
import { CustomLink } from "components/CustomLink";
import { forgotPassword } from "apis/usersApi";
import { ErrorText, SuccessText } from "components/Text";
import { EditUserModal } from "components/Modals";
import { getUserForEdit } from "apis/adminApi";

export const ResetPassword = () => {
  const [passwordResetErrorMessage, setPasswordResetErrorMessage] = useState("");
  const [passwordResetSuccessMessage, setPasswordResetSuccessMessage] = useState("");

  const handlePasswordReset = async () => {
    setPasswordResetSuccessMessage("");
    setPasswordResetErrorMessage("");
    let passwordResetResult = await forgotPassword();
    if (passwordResetResult.data) {
      if (passwordResetResult.data.success) {
        setPasswordResetSuccessMessage("Password reset link sent successfully. Please check your inbox.");
      } else {
        setPasswordResetErrorMessage("Password reset failed. Unknown error");
      }
    }
  };
  return (
    <div>
      <h5 className="mt-4">Press the button below to reset your password</h5>
      <div className="d-flex justify-content-start align-items-center">
        <Button className="mt-2" onClick={handlePasswordReset}>
          Reset Password
        </Button>
        <div className="d-flex align-items-center justify-content-center">
          {passwordResetSuccessMessage && <SuccessText className="mt-4 ml-3" text={passwordResetSuccessMessage} />}
          {passwordResetErrorMessage && <ErrorText className="mt-4 ml-3" text={passwordResetErrorMessage} />}
        </div>
      </div>
    </div>
  );
};

const Users = ({ adminResetResponse }) => {
  const [isAddUserModalActive, setIsAddUserModalActive] = useState(false);
  const [userToEdit, setUserToEdit] = useState(null);
  const [isEditUserModalActive, setIsEditUserModalActive] = useState(false);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [users, setUsers] = useState(null);
  const [usersCount, setUsersCount] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
  };

  const handlePageChange = (goToPage) => {
    setPage(goToPage);
  };

  const fetchUsers = useCallback(async () => {
    const getUsersResult = await getUsers(page, pageSize);

    if (getUsersResult.data) {
      let responseData = getUsersResult.data;
      switch (getUsersResult.data.result) {
        case 0:
          setUsers(responseData.users);
          setUsersCount(responseData.count);
          return;
        case 1:
          setErrorMessage("Undefined error");
          break;
        case 9:
          setErrorMessage("You are not authorized to perform this action");
          break;
        default:
          break;
      }
    }

    // This handles axios error
    else if (getUsersResult) {
      setErrorMessage(getUsersResult.error);
    } else {
      setErrorMessage("Undefined error");
    }
  }, [page, pageSize]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const handleUserDelete = async ({ userId }) => {
    const response = await deleteUser({ userId });

    if (response.data) {
      switch (response.data.result) {
        case 0:
          fetchUsers(page, pageSize);
          break;
        case 1:
          setErrorMessage("Undefined error");
          break;
        case 2:
          setErrorMessage("You are not authorized to delete a user");
          break;
        case 3:
          setErrorMessage("User you are trying to delete does not exist");
          break;
        default:
          break;
      }
    }
  };

  const handleEditUserModalOpen = async (userId) => {
    var result = await getUserForEdit(userId);
    setUserToEdit(result.data.item);
    setIsEditUserModalActive(true);
    //Reset result & error message in admin reducer

    adminResetResponse();
  };

  const handleAddUserModalOpen = () => {
    setIsAddUserModalActive(true);
    //Reset result & error message in admin reducer
    adminResetResponse();
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12">
          <div className="page-title-box">
            <div className="float-right">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <CustomLink href={ROUTES.index}>Compel</CustomLink>
                </li>
                <li className="breadcrumb-item">
                  <CustomLink href={ROUTES.users}>Users</CustomLink>
                </li>
              </ol>
            </div>
            <h4 className="page-title">Users</h4>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <Row className="my-2">
                <Col sm={12} md={6}>
                  <CustomEntriesSelect onChange={handlePageSizeChange} />
                </Col>
                <Col sm={12} md={6} className="d-flex justify-content-end align-items-center">
                  <ErrorText text={errorMessage} className="mr-3" />
                  <Button className="btn btn-primary px-4 btn-rounded float-right mt-0 mb-1" onClick={handleAddUserModalOpen}>
                    + Add New User
                  </Button>
                </Col>
              </Row>
              <UsersTable users={users} handleEditUserModalOpen={handleEditUserModalOpen} handleDeleteUser={handleUserDelete} onUserStatusChange={fetchUsers} />
              <CustomEntriesDisplay totalEntries={usersCount} page={page} pageSize={pageSize} />
              <div className="col-sm-12 col-md-7 d-flex justify-content-end">
                <TablePagination currentPage={page} pageSize={pageSize} onChange={handlePageChange} totalEntries={usersCount} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddNewUserModal isAddUserModalActive={isAddUserModalActive} setIsAddUserModalActive={setIsAddUserModalActive} />
      {isEditUserModalActive && userToEdit && (
        <EditUserModal user={userToEdit} isEditUserModalActive={isEditUserModalActive} setIsEditUserModalActive={setIsEditUserModalActive} />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ adminResetResponse }, dispatch);
};

export default connect(undefined, mapDispatchToProps)(Users);
