import { typeSimulation } from "constants/actionTypes";

const initialState = {
  downloadPanel: {
    isOpen: false,
  },
  files: null,
  fileListErrorMessage: "",
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case typeSimulation.openDownloadPanel:
      return {
        ...state,
        downloadPanel: {
          isOpen: payload.isOpen,
        },
      };
    case typeSimulation.closeDownloadPanel:
      return {
        ...state,
        downloadPanel: {
          isOpen: payload.isOpen,
        },
      };
    case typeSimulation.setDownloadFileList:
      return {
        ...state,
        files: payload.files,
      };
    case typeSimulation.setFileListErrorMessage:
      return {
        ...state,
        fileListErrorMessage: payload.fileListErrorMessage,
      };
    default:
      break;
  }
  return state;
};
