const ROUTES = {
  index: "/",
  register: "/register",
  login: "/login",
  forgotPassword: function() {
    return `${this.login}/forgotPassword`;
  },
  resetPassword: function() {
    return `${this.login}/resetPassword/`;
  },
  notApproved: "/notApproved",
  projects: "/projects",
  createProject: function() {
    return `${this.projects}/create`;
  },
  singleProject: function(id) {
    return `${this.projects}/${id}`;
  },
  createSimulation: function(id) {
    return `${this.projects}/${id}/createSimulation`;
  },
  simulation: function(id) {
    return `/simulation/${id}`;
  },
  users: "/users",
  account: "/account",
  support: "/support",
  testPage: "/test-route",
};

export default ROUTES;
