export const numberDisplayFormatting = ({ number, numberOfDecimalPlaces = 2 }) => {
  number = number.toString();
  if (number.includes(".")) {
    number =
      number.split(".")[0] +
      "." +
      number
        .toString()
        .split(".")[1]
        .substring(0, numberOfDecimalPlaces);
  } else {
    number = number + ".00";
  }
  return number;
};
