import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { numberDisplayFormatting } from "utility/numberFormatting";
import { useEffect } from "react";

const ReachTable = ({ tableData, weighted }) => {
  const { quotaGroupLabels, threshold, weightedData, unweightedData } = tableData;
  const [data, setData] = useState([]);
  useEffect(() => {
    let formattedData;
    if (weighted) {
      formattedData = weightedData.map((scoreArray) => {
        scoreArray = scoreArray.map((score) => {
          return numberDisplayFormatting({ number: score });
        });
        return scoreArray;
      });
      setData(formattedData);
    } else {
      formattedData = unweightedData.map((scoreArray) => {
        scoreArray = scoreArray.map((score) => {
          return numberDisplayFormatting({ number: score });
        });
        return scoreArray;
      });
      setData(formattedData);
    }
  }, [weighted, unweightedData, weightedData]);

  return (
    <div className="dash-social">
      <Table responsive className="mt-4">
        <thead className="thead-light">
          <tr>
            <th>Threshold: {threshold}%</th>
            {quotaGroupLabels.map((label) => (
              <th key={label}>{label}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {data.map((messageGroup, index) => (
            <tr key={index + messageGroup}>
              <td>{index + 1} message</td>
              {messageGroup.map((quotaGroupScore, index) => (
                <td key={index + quotaGroupScore}>{quotaGroupScore}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default ReachTable;
