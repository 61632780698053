import React from "react";

const BulkFileUploadErrors = ({ errorMessages }) => {
  return (
    <div>
      {errorMessages.map((errorMessage, index) => (
        <p key={index} className="text-danger">
          {errorMessage}
        </p>
      ))}
    </div>
  );
};

export default BulkFileUploadErrors;
