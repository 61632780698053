import React, { useState } from "react";
import { Table, Button, Spinner } from "react-bootstrap";
import { LeverageabilityUploadTableRow } from "components/Tables";
import { deleteLeverageability, getLeverageabilityData } from "apis/projectApi";
import { ViewLeverageabilityDataModal } from "components/Modals";

const LeverageabilityUploadTable = ({
  draftId,
  quotaGroupsData,
  changeLeverageabilityValidation,
  handleQuotaGroupsData,
  handleReachData,
  handleDropzoneErrorMessages,
}) => {
  const redirectTimeout = 5000;
  const [errorMessage, setErrorMessage] = useState("");
  const [getLeverageabilityDataErrorMessage, setGetLeverageabilityDataErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [leverageabilityDataIsLoading, setLeverageabilityDataIsLoading] = useState(false);
  const [leverageabilityDataIsLoadingQuotaGroupId, setleverageabilityDataIsLoadingQuotaGroupId] = useState("");
  const [leverageabilityModalData, setLeverageabilityModalData] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleLeverageabilityDelete = async (draftId, setErrorMessage, setIsLoading, quotaGroupId = null) => {
    setIsLoading(true);
    let deleteLeverageabilityResponse = await deleteLeverageability(draftId, quotaGroupId);
    if (deleteLeverageabilityResponse.data) {
      switch (deleteLeverageabilityResponse.data.result) {
        case 0:
          handleQuotaGroupsData(deleteLeverageabilityResponse.data.quotaGroups);
          handleDropzoneErrorMessages({});
          handleReachData();
          break;
        case 1:
          setErrorMessage("You are not authorized to delete a file in this draft");
          break;
        case 2:
          setErrorMessage(`This account has been deleted. You will be redirected to the login screen in ${redirectTimeout} seconds`);
          break;
        case 3:
          setErrorMessage("The file you are trying to delete does not exits");
          break;
        case 4:
          setErrorMessage("The draft you are trying to upload files to does not exist");
          break;
        case 5:
          setErrorMessage("Undefined error");
          break;
        default:
          break;
      }
    }
    setIsLoading(false);
  };

  const handleGetLeverageabilityData = async (quotaGroupId) => {
    setLeverageabilityDataIsLoading(true);
    setleverageabilityDataIsLoadingQuotaGroupId(quotaGroupId);
    let getLeverageabilityDataResponse = await getLeverageabilityData(quotaGroupId);
    setLeverageabilityDataIsLoading(false);
    if (getLeverageabilityDataResponse.data) {
      switch (getLeverageabilityDataResponse.data.result) {
        case 0:
          setLeverageabilityModalData({
            leverageabilityRows: getLeverageabilityDataResponse.data.leverageabilityRows,
            quotaGroupName: getLeverageabilityDataResponse.data.quotaGroupName,
          });
          setShowModal(true);
          break;
        case 1:
          setGetLeverageabilityDataErrorMessage("Undefined error");
          break;
        case 2:
          setGetLeverageabilityDataErrorMessage("You are not authorized to delete a file in this draft");
          break;
        case 3:
          setGetLeverageabilityDataErrorMessage(`This account has been deleted. You will be redirected to the login screen in ${redirectTimeout} seconds`);
          break;
        case 4:
          setGetLeverageabilityDataErrorMessage("Quota group does not exist");
          break;
        default:
          break;
      }
    }
  };

  const handleShowModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <ViewLeverageabilityDataModal showModal={showModal} handleShowModal={handleShowModal} tableData={leverageabilityModalData} />
      <Table className="table mt-3">
        <thead>
          <tr>
            <td>Name</td>
            <td className="text-center">File</td>
            <td className="text-center">Status</td>
          </tr>
        </thead>
        <tbody>
          {quotaGroupsData.map((quotaGroup) => (
            <LeverageabilityUploadTableRow
              key={quotaGroup.quotaGroupId}
              draftId={draftId}
              quotaGroup={quotaGroup}
              changeLeverageabilityValidation={changeLeverageabilityValidation}
              handleLeverageabilityDelete={handleLeverageabilityDelete}
              handleQuotaGroupsData={handleQuotaGroupsData}
              handleReachData={handleReachData}
              handleGetLeverageabilityData={handleGetLeverageabilityData}
              getLeverageabilityDataErrorMessage={getLeverageabilityDataErrorMessage}
              leverageabilityDataIsLoading={leverageabilityDataIsLoading && quotaGroup.quotaGroupId === leverageabilityDataIsLoadingQuotaGroupId}
              handleDropzoneErrorMessages={handleDropzoneErrorMessages}
            />
          ))}
          <tr>
            <td></td>
            <td className="d-flex justify-content-end mt-2">{errorMessage}</td>
            <td className="text-center">
              <Button variant="danger" onClick={() => handleLeverageabilityDelete(draftId, setErrorMessage, setIsLoading)}>
                {isLoading ? <Spinner animation="border" size="sm" /> : "Delete all files"}
              </Button>
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default LeverageabilityUploadTable;
