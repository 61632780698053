import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ROUTES from "constants/routes";
import { Button } from "react-bootstrap";
import { adminResetResponse } from "actions/adminActions";
import { CustomLink } from "components/CustomLink";
import { forgotPassword } from "apis/usersApi";
import { ErrorText, SuccessText } from "components/Text";

export const ResetPassword = () => {
  const [passwordResetErrorMessage, setPasswordResetErrorMessage] = useState("");
  const [passwordResetSuccessMessage, setPasswordResetSuccessMessage] = useState("");

  const handlePasswordReset = async () => {
    setPasswordResetSuccessMessage("");
    setPasswordResetErrorMessage("");
    let passwordResetResult = await forgotPassword();
    if (passwordResetResult.data) {
      if (passwordResetResult.data.success) {
        setPasswordResetSuccessMessage("Password reset link sent successfully. Please check your inbox.");
      } else {
        setPasswordResetErrorMessage("Password reset failed. Unknown error");
      }
    }
  };
  return (
    <div>
      <h5 className="mt-4">Press the button below to reset your password</h5>
      <div className="d-flex justify-content-start align-items-center">
        <Button className="mt-2" onClick={handlePasswordReset}>
          Reset Password
        </Button>
        <div className="d-flex align-items-center justify-content-center">
          {passwordResetSuccessMessage && <SuccessText className="mt-4 ml-3" text={passwordResetSuccessMessage} />}
          {passwordResetErrorMessage && <ErrorText className="mt-4 ml-3" text={passwordResetErrorMessage} />}
        </div>
      </div>
    </div>
  );
};

const Account = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-sm-12">
          <div className="page-title-box">
            <div className="float-right">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <CustomLink href={ROUTES.index}>Compel</CustomLink>
                </li>
                <li className="breadcrumb-item">
                  <CustomLink href={ROUTES.account}>Account</CustomLink>
                </li>
              </ol>
            </div>
            <h4 className="page-title">Account</h4>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <ResetPassword />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ adminResetResponse }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
