import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Form, Row, Col, FormControl, FormGroup, Button, Card, Spinner } from "react-bootstrap";
import { selectUserType, registerUser, resetResponse } from "actions/userRegisterActions";
import { validEmailRegex } from "utility/validation";
import { CustomLink } from "components/CustomLink";
import { useEffect } from "react";
import ROUTES from "constants/routes";

const returnToUserSelection = (selectUserType) => {
  selectUserType(null);
};

const RegistrationForm = ({ userRegistration, selectUserType, registerUser, resetResponse }) => {
  //If true, form can be submitted to backend.
  const [formIsValid, setFormIsValid] = useState(false);

  //Validation will not be triggered until this is set to true
  const [firstValidation, setFirstValidation] = useState(false);

  const [formData, setFormData] = useState({
    projectNumber: "",
    firstName: "",
    lastName: "",
    email: "",
    repeatEmail: "",
    password: "",
    repeatPassword: "",
    projectTeam: "",
    companyName: "",
    userType: userRegistration.userType,
  });

  const [inputValidation, setInputValidation] = useState({
    projectNumber: false,
    projectTeam: false,
    companyName: false,
    firstName: false,
    lastName: false,
    email: false,
    repeatEmail: false,
    password: false,
    repeatPassword: false,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    validateField(name, value);

    setFormData((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    //Triggers validation of form
    setFirstValidation(true);
    //Submits form to backend
    if (formIsValid) {
      registerUser(formData);
    }
  };

  const validateField = (fieldName, fieldValue) => {
    //All input fields should be filled in
    if (fieldValue.length < 1) {
      setInputValidation((prevState) => ({ ...prevState, [fieldName]: false }));
    } else {
      setInputValidation((prevState) => ({ ...prevState, [fieldName]: true }));
    }

    //Special cases
    switch (fieldName) {
      case "email":
        if (!validEmailRegex.test(fieldValue)) {
          setInputValidation((prevState) => ({ ...prevState, [fieldName]: false }));
        } else {
          setInputValidation((prevState) => ({ ...prevState, [fieldName]: true }));
        }
        break;
      case "password":
        if (fieldValue.length < 5) {
          setInputValidation((prevState) => ({ ...prevState, [fieldName]: false }));
        } else {
          setInputValidation((prevState) => ({ ...prevState, [fieldName]: true }));
        }
        break;
      default:
        break;
    }
  };

  //Resets previous server response to inital value
  useEffect(() => {
    resetResponse();
  }, [resetResponse]);

  //Checks if any value in validation fields object is false. If it is, form is not considered valid and user can't submit the form.
  useEffect(() => {
    switch (userRegistration.userType) {
      //Employee
      case 1:
        //Next line creates a new object from the input validation object, but without the company name property (employee does not have company name)
        const { companyName, projectNumber, ...employeeInputValidation } = inputValidation;
        if (!Object.values(employeeInputValidation).includes(false)) {
          setFormIsValid(true);
        } else {
          setFormIsValid(false);
        }
        break;
      //Client
      case 2:
        //Next line creates a new object from the input validation object, but without the company name property(client does not have project team)
        const { projectTeam, ...clientInputValidation } = inputValidation;
        if (!Object.values(clientInputValidation).includes(false)) {
          setFormIsValid(true);
        } else {
          setFormIsValid(false);
        }
        break;
      default:
        break;
    }
  }, [inputValidation, userRegistration.userType]);

  return (
    <Card className="card auth-card shadow-lg">
      <Card.Body className="card-body">
        <div className="px-3">
          <Form noValidate onSubmit={handleSubmit} className="form-horizontal auth-form my-4">
            {/* First row */}
            {/* Employee */}
            {userRegistration.userType === 1 && (
              <Row>
                <Col xs="12">
                  <Form.Group>
                    <Form.Label>Project team</Form.Label>
                    <FormControl
                      type="text"
                      name="projectTeam"
                      onChange={handleInputChange}
                      className={firstValidation && inputValidation.projectTeam ? "is-valid" : ""}
                      value={formData.projectTeam}
                      isInvalid={firstValidation && !inputValidation.projectTeam ? true : false}
                    />
                    <Form.Control.Feedback type="invalid">
                      {userRegistration.userType === 1 && !inputValidation.projectTeam && "Project team is required!"}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            )}

            {/* Client */}
            {userRegistration.userType === 2 && (
              <Row>
                <Col xs="6">
                  <Form.Group>
                    <Form.Label>Company name</Form.Label>
                    <FormControl
                      type="text"
                      name="companyName"
                      onChange={handleInputChange}
                      className={firstValidation && inputValidation.companyName ? "is-valid" : ""}
                      value={formData.companyName}
                      isInvalid={firstValidation && userRegistration.userType === 2 && !inputValidation.companyName ? true : false}
                    />
                    <Form.Control.Feedback type="invalid">
                      {userRegistration.userType === 2 && !inputValidation.companyName && "Company name is required!"}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col xs="6">
                  <Form.Group>
                    <Form.Label>Project number</Form.Label>
                    <FormControl
                      type="text"
                      name="projectNumber"
                      onChange={handleInputChange}
                      className={firstValidation && inputValidation.projectNumber ? "is-valid" : ""}
                      value={formData.projectNumber}
                      isInvalid={firstValidation && !inputValidation.projectNumber ? true : false}
                    />
                    <Form.Control.Feedback type="invalid">{!inputValidation.projectNumber && "Project number is required!"}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            )}
            {/* Third row */}
            <Row>
              <Col xs="6">
                <Form.Group>
                  <Form.Label>First name</Form.Label>
                  <FormControl
                    type="text"
                    name="firstName"
                    onChange={handleInputChange}
                    className={firstValidation && inputValidation.firstName && "is-valid"}
                    value={formData.firstName}
                    isInvalid={firstValidation && !inputValidation.firstName}
                  />
                  <Form.Control.Feedback type="invalid">First name is required!</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs="6">
                <Form.Group>
                  <Form.Label>Last name</Form.Label>
                  <FormControl
                    type="text"
                    name="lastName"
                    onChange={handleInputChange}
                    className={firstValidation && inputValidation.lastName && "is-valid"}
                    value={formData.lastName}
                    isInvalid={firstValidation && !inputValidation.lastName}
                  />
                  <Form.Control.Feedback type="invalid">Last name is required!</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            {/* Fourth row */}
            <Row>
              <Col xs="6">
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    onChange={handleInputChange}
                    className={firstValidation && inputValidation.email && "is-valid"}
                    value={formData.email}
                    isInvalid={(firstValidation && !inputValidation.email) || (firstValidation && userRegistration.response.result === 4)}
                  />
                  <Form.Control.Feedback type="invalid">{!inputValidation.email && "Please enter a valid email!"}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs="6">
                <Form.Group>
                  <Form.Label>Repeat email</Form.Label>
                  <Form.Control
                    type="email"
                    name="repeatEmail"
                    onChange={handleInputChange}
                    className={firstValidation && inputValidation.repeatEmail && formData.repeatEmail.length > 0 && "is-valid"}
                    value={formData.repeatEmail}
                    isInvalid={
                      (firstValidation && !inputValidation.email) ||
                      (firstValidation && formData.repeatEmail.length < 1) ||
                      (firstValidation && !inputValidation.repeatEmail) ||
                      (firstValidation && userRegistration.response.result === 4)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {firstValidation && !inputValidation.email
                      ? "Please enter a valid email"
                      : firstValidation && formData.repeatEmail < 1
                      ? "Please repeat the email address"
                      : firstValidation && !inputValidation.repeatEmail
                      ? "Emails do not match!"
                      : ""}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            {/* Fifth row */}
            <Row>
              <Col xs="6">
                <Form.Group>
                  <Form.Label>Password</Form.Label>
                  <FormControl
                    type="password"
                    name="password"
                    onChange={handleInputChange}
                    className={firstValidation && inputValidation.password ? "is-valid" : ""}
                    value={formData.password}
                    isInvalid={firstValidation && !inputValidation.password}
                  />
                  <Form.Control.Feedback type="invalid">{!inputValidation.password ? "Password is too short!" : "Password is required"}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs="6">
                <Form.Group>
                  <Form.Label>Repeat password</Form.Label>
                  <FormControl
                    type="password"
                    name="repeatPassword"
                    onChange={handleInputChange}
                    className={firstValidation && inputValidation.repeatPassword && formData.repeatPassword.length > 0 ? "is-valid" : ""}
                    value={formData.repeatPassword}
                    isInvalid={
                      (firstValidation && !inputValidation.password) ||
                      (firstValidation && formData.repeatPassword.length < 1) ||
                      (firstValidation && !inputValidation.repeatPassword)
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {firstValidation && formData.repeatPassword.length < 1
                      ? "Please repeat the password!"
                      : firstValidation && !inputValidation.repeatPassword
                      ? "Passwords do not match!"
                      : firstValidation && !inputValidation.password
                      ? "Password is too short"
                      : ""}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            {userRegistration.response.result === 4 && <p className="text-danger text-center">{userRegistration.response.message}</p>}
            {userRegistration.response.result === 1 && <p className="text-danger text-center">{userRegistration.response.message}</p>}
            <FormGroup className="mb-0">
              <div className="col-12 mt-2">
                <Button type="submit" className="btn btn-primary  btn-block waves-effect waves-light">
                  {userRegistration.registrationInProgress ? <Spinner as="div" animation="border" variant="light" /> : "Register"}
                </Button>
              </div>
            </FormGroup>
          </Form>
          <div className="m3 text-center">
            <p>
              <CustomLink onClick={() => returnToUserSelection(selectUserType)} href={ROUTES.register} className="text-primary ml-2">
                Go back
              </CustomLink>
            </p>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = (state) => ({ userRegistration: state.userRegistration });

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ selectUserType, registerUser, resetResponse }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationForm);
