import { typeUser } from "constants/actionTypes";

const initialState = {
  authenticationInProgress: false,
  isLoggedIn: false,
  isApproved: false,
  userRole: null,
  response: {
    result: null,
    message: "",
  },
  //TODO: Add username
  //TODO: Add user type
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case typeUser.currentData:
      return {
        ...state,
        currentUser: payload,
      };
    case typeUser.loginStart:
      return {
        ...state,
        authenticationInProgress: true,
      };
    case typeUser.loginEnd:
      return {
        ...state,
        authenticationInProgress: false,
        isLoggedIn: payload.isLoggedIn,
        isApproved: payload.isApproved,
        response: {
          result: payload.response.result,
          message: payload.response.message,
        },
      };
    case typeUser.getUserApprovalStatus:
      return {
        ...state,
        isApproved: payload.isUserApproved,
      };
    case typeUser.resetResponse:
      return {
        ...state,
        response: {
          result: null,
          message: "",
        },
      };
    default:
      break;
  }
  return state;
};
