import React, { useRef, useEffect } from "react";
import ChartJS from "chart.js";
import classNames from "classnames";

const Chart = ({
  initialChartData,
  chartData,
  chartType,
  className,
  showXLabels,
  displayLegend,
  legendLabelBoxWidth,
  legendPosition,
  legendAlignment,
  maxMessageSet,
}) => {
  //Holds reference to the canvas element
  const canvasRef = useRef(null);
  const chartInstanceRef = useRef(null);

  useEffect(() => {
    //Generate chart labels depending on the number of message sets
    const labels = [];
    for (let messageSet = 1; messageSet <= maxMessageSet; messageSet++) {
      labels.push(`${messageSet} Message`);
    }
    //Initialize ChartJS
    if (!canvasRef.current) return;

    const chartConfig = {
      type: chartType,
      data: {
        labels: labels,
        datasets: initialChartData,
      },
      options: {
        legend: {
          display: true,
          position: legendPosition,
          align: legendAlignment,
          labels: {
            boxWidth: legendLabelBoxWidth,
          },
        },
        scales: {
          xAxes: [
            {
              ticks: {
                display: false, //this will remove only the label
              },
            },
          ],
        },
        aspectRatio: 3,
      },
    };

    const newChart = new ChartJS(canvasRef.current, chartConfig);
    chartInstanceRef.current = newChart;
    return () => {
      // DESTROY CHART.JS when component unmounts to prevent memory leaks from re-initializing Chart.js (IF NEEDED)
      // if destroying, use newChart since it's locally scoped to this useEffect, while ref is not
    };
  }, [chartType, initialChartData, showXLabels, displayLegend, legendLabelBoxWidth, legendAlignment, legendPosition, maxMessageSet]);

  useEffect(() => {
    let chart = chartInstanceRef.current;
    //Replace current data from each dataset
    chart.data.datasets.forEach((dataset, index) => {
      dataset.data = chartData[index];
    });

    //Update chart
    chart.update();
  }, [chartData]);

  const classesString = classNames(className);

  return <canvas className={classesString} ref={canvasRef}></canvas>;
};

export default Chart;
