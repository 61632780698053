import React, { useEffect, useState } from "react";
import { Form, Col, Button, ToggleButtonGroup, ToggleButton, Table, Row } from "react-bootstrap";
import { connect } from "react-redux";
import CustomInputField from "./CustomInputField";
import { ErrorText, SuccessText } from "components/Text";
import { CustomSelect } from "components/Forms";
import { getAllProjects } from "apis/projectApi";
import { Icon } from "components/Icon";

const EditUserForm = ({
  currentUserEmail,
  isUserApproved,
  isFormValid,
  admin,
  newData,
  firstValidation,
  inputValidation,
  handleInputChange,
  handleUserSelect,
  handleApprovalSelect,
  handleResetPassword,
  resetPasswordMailIsSent,
}) => {
  const [availableProjects, setAvailableProjects] = useState([]);

  const fetchProjects = async () => {
    var result = await getAllProjects();
    if (result && result.data) {
      setAvailableProjects(result.data.items || []);
    }
  };
  useEffect(() => {
    fetchProjects();
  }, []);

  return (
    <Form noValidate>
      <Table>
        <tbody>
          <tr>
            <td>Change user approval status</td>
            <td>
              <ToggleButtonGroup type="radio" name="isApproved" onChange={handleApprovalSelect} defaultValue={isUserApproved}>
                <ToggleButton className="mr-2 cursor-pointer" variant="outline-primary" value={true}>
                  Approved
                </ToggleButton>
                <ToggleButton className="mx-2 cursor-pointer" variant="outline-primary" value={false}>
                  Not approved
                </ToggleButton>
              </ToggleButtonGroup>
            </td>
          </tr>
          <tr>
            <td>Change user type</td>
            <td>
              <ToggleButtonGroup type="radio" name="userType" onChange={handleUserSelect} defaultValue={newData.userType}>
                <ToggleButton className="mx-2" variant="outline-primary cursor-pointer" value={0}>
                  Admin
                </ToggleButton>
                <ToggleButton variant="outline-primary cursor-pointer" className="mx-2" value={1}>
                  Employee
                </ToggleButton>
                <ToggleButton variant="outline-primary cursor-pointer" className="mx-2" value={2}>
                  Client
                </ToggleButton>
              </ToggleButtonGroup>
            </td>
          </tr>

          <tr>
            <td>First name</td>
            <td>
              <CustomInputField
                type="text"
                name="firstName"
                handleInputChange={handleInputChange}
                className={firstValidation && inputValidation.firstName && "is-valid"}
                value={newData.firstName}
                isInvalid={firstValidation && !inputValidation.firstName}
                errorMsg="First name is required"
              />
            </td>
          </tr>
          <tr>
            <td>Last name</td>
            <td>
              <CustomInputField
                type="text"
                name="lastName"
                disabled={newData.userType === null}
                handleInputChange={handleInputChange}
                className={firstValidation && inputValidation.lastName && "is-valid"}
                value={newData.lastName}
                isInvalid={firstValidation && !inputValidation.lastName}
                errorMsg="Last name is required"
              />
            </td>
          </tr>
          {newData.userType === 2 && (
            <tr>
              <td>Company name</td>
              <td>
                <CustomInputField
                  type="text"
                  name="companyName"
                  handleInputChange={handleInputChange}
                  className={firstValidation && inputValidation.companyName ? "is-valid" : ""}
                  value={newData.companyName}
                  isInvalid={firstValidation && !inputValidation.companyName ? true : false}
                  errorMsg="Company name is required"
                />
              </td>
            </tr>
          )}
          {newData.userType === 2 && (
            <>
              <tr>
                <td>Assigned Projects</td>
                <td>
                  <Col>
                    <CustomSelect
                      options={availableProjects
                        .filter((project) => {
                          if (!newData.projects) {
                            return true;
                          }
                          let exists = false;
                          newData.projects.forEach((element) => {
                            if (element.id === project.id) {
                              exists = true;
                            }
                          });
                          return !exists;
                        })
                        .map((project) => {
                          return { value: project, label: project.name };
                        })}
                      onChange={(selected) => {
                        let newProjects = [...newData.projects.filter((project) => project.id !== selected.value.id)];
                        handleInputChange({
                          target: {
                            name: "projects",
                            value: [...newProjects, selected.value],
                          },
                        });
                      }}
                      //styles={customSelectSyles}
                      //defaultValue={selectMessageGroupsOptions.length > 0 && selectMessageGroupsOptions[0]}
                    />
                  </Col>
                </td>
              </tr>
              {newData.projects && newData.projects.length > 0 && (
                <tr>
                  <td colSpan="2">
                    {newData.projects.map((project) => {
                      return (
                        <Button
                          variant="light"
                          className="mr-2 mb-2"
                          key={project.id}
                          onClick={() => {
                            handleInputChange({
                              target: {
                                name: "projects",
                                value: [...newData.projects.filter((existingProject) => existingProject.id !== project.id)],
                              },
                            });
                          }}
                        >
                          <Icon name="fa-trash-alt" context="danger" className="mr-2" />
                          {project.name}
                        </Button>
                      );
                    })}
                  </td>
                </tr>
              )}
            </>
          )}

          {newData.userType !== 2 && (
            <tr>
              <td>Project team</td>
              <td>
                <Col>
                  <CustomInputField
                    type="text"
                    name="projectTeam"
                    handleInputChange={handleInputChange}
                    className={firstValidation && inputValidation.projectTeam ? "is-valid" : ""}
                    value={newData.projectTeam}
                    isInvalid={firstValidation && !inputValidation.projectTeam ? true : false}
                    errorMsg="Project team is required"
                  />
                </Col>
              </td>
            </tr>
          )}
          <tr>
            <td>Email</td>
            <td>
              <Col>
                <CustomInputField
                  type="text"
                  name="newEmail"
                  disabled={newData.userType === null}
                  handleInputChange={handleInputChange}
                  className={firstValidation && inputValidation.newEmail && "is-valid"}
                  value={newData.newEmail}
                  isInvalid={(firstValidation && !inputValidation.newEmail) || (firstValidation && admin.response.result === 4)}
                  errorMsg={!inputValidation.newEmail && "Please enter a valid email!"}
                />
              </Col>
            </td>
          </tr>
          <tr>
            <td>Reset user password</td>
            <td>
              <Row>
                <Col sm={6}>
                  <Button variant="secondary" onClick={handleResetPassword}>
                    Reset user password
                  </Button>
                </Col>
                <Col sm={6}>
                  {resetPasswordMailIsSent !== null && resetPasswordMailIsSent && <SuccessText text="Password reset link sent successfully." />}
                  {resetPasswordMailIsSent !== null && !resetPasswordMailIsSent && <ErrorText text="Password reset failed. Unknown error" />}
                </Col>
              </Row>
            </td>
          </tr>
        </tbody>
      </Table>
      {admin.response.result === 0 && <SuccessText text="User successfully edited!" />}
      <ErrorText text={admin.response.errorMessage} />
    </Form>
  );
};

const mapStateToProps = (state) => ({ admin: state.admin });

export default connect(mapStateToProps, null)(EditUserForm);
