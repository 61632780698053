import React from "react";
import classNames from "classnames";

const RoundButton = ({ buttonClasses, iconClasses, onClick }) => {
  const buttonClassnames = classNames("btn btn-circle", buttonClasses);
  return (
    <button className={buttonClassnames} onClick={onClick}>
      <i className={iconClasses}></i>
    </button>
  );
};

export default RoundButton;
