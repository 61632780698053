import axios from "axios";
import { authCookieName } from "constants/general";
import { getCookie } from "utility/cookie";

const getBaseUrl = () => {
  return axios.create({
    baseURL: window.location.origin + "/api/project",
    headers: { Authorization: "Bearer " + getCookie(authCookieName) },
  });
};

export const getDrafts = async () => {
  return getBaseUrl()
    .get("/getDrafts")
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};

export const createProject = async () => {
  return getBaseUrl()
    .post("/create")
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};

export const deleteProject = async (projectId) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return getBaseUrl()
    .post("/delete", projectId, { headers })
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};

export const changeProjectAccessStatus = async (projectId) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return getBaseUrl()
    .post("/changeProjectAccessStatus", projectId, { headers })
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};

export const uploadMessages = async (file, draftId) => {
  let formData = new FormData();
  formData.append("messagesFile", file);
  formData.append("projectId", draftId);
  return getBaseUrl()
    .post("/upload/messages", formData)
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};

export const deleteMessages = async (draftId) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return getBaseUrl()
    .post("/delete/messages", draftId, { headers })
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};

export const uploadQuotaGroups = async (file, draftId) => {
  let formData = new FormData();
  formData.append("quotaGroupsFile", file);
  formData.append("projectId", draftId);
  return getBaseUrl()
    .post("/upload/quotaGroups", formData)
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};

export const changeQuotaGroupName = async ({ draftId, quotaGroupId, newQuotaGroupName }) => {
  return getBaseUrl()
    .post("/changeQuotaGroupName", { projectId: draftId, quotaGroupId, newQuotaGroupName })
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};

export const deleteQuotaGroups = async (draftId) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return getBaseUrl()
    .post("/delete/quotaGroups", draftId, { headers })
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};

export const uploadLeverageability = async (draftId, quotaGroupId, file) => {
  let formData = new FormData();
  formData.append("leverageabilityFile", file);
  formData.append("projectId", draftId);
  formData.append("quotaGroupId", quotaGroupId);
  return getBaseUrl()
    .post("/upload/leverageability", formData)
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};

export const uploadLeverageabilityBulk = async ({ draftId, files }) => {
  let formData = new FormData();
  files.forEach((file) => {
    formData.append("leverageabilityFiles", file);
  });
  formData.append("projectId", draftId);
  return getBaseUrl()
    .post("/upload/leverageabilityBulk", formData)
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};

export const getLeverageabilityData = async (quotaGroupId) => {
  const params = {
    quotaGroupId,
  };
  return getBaseUrl()
    .get("/getLeverageabilityData", { params })
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};

//Each leverageability file is connected with exactly one quota group
export const deleteLeverageability = async (projectId, quotaGroupId) => {
  return getBaseUrl()
    .post("/delete/leverageability", { projectId, quotaGroupId })
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};

export const uploadReach = async (file, draftId) => {
  let formData = new FormData();
  formData.append("reachFile", file);
  formData.append("projectId", draftId);
  return getBaseUrl()
    .post("/upload/reach", formData)
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};

export const deleteReach = async (draftId) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return getBaseUrl()
    .post("/delete/reach", draftId, { headers })
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};

export const completeWizard = ({ projectId, projectName, projectPassword, maxMessageSet, resultsSetSize }) => {
  return getBaseUrl()
    .post("/completeWizard", { projectId, projectName, projectPassword, maxMessageSet, resultsSetSize })
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};

export const getProjects = async (page, pageSize, orderIsDescending, sortBy, searchString, showClosedProjects = false) => {
  const params = {
    page,
    pageSize,
    orderIsDescending,
    sortBy,
    searchString,
    showClosedProjects,
  };
  return getBaseUrl()
    .get("/getProjects", { params })
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};

export const getAllProjects = async () => {
  return getBaseUrl()
    .get("/GetAllProjects")
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};

export const getProjectById = async (id) => {
  const params = {
    id,
  };
  return getBaseUrl()
    .get("/getProjectById", { params })
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};

export const requestAccess = async (projectId, password) => {
  return getBaseUrl()
    .post("/RequestAccess", { projectId, password })
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};

export const changeProjectName = async ({ projectId, newName }) => {
  return getBaseUrl()
    .post("/changeProjectName", { projectId, newName })
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};

export const changeProjectPasswordAccess = async ({ projectId, newPassword, limitAccess }) => {
  return getBaseUrl()
    .post("/changeProjectPasswordAccess", { projectId, newPassword, limitAccess })
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};
