import React from "react";
import { Modal, Spinner } from "react-bootstrap";

const ThemeModalIcon = ({ errorMessage, success }) => {
  if (errorMessage) {
    return (
      <div className="swal2-icon swal2-error swal2-animate-error-icon d-flex">
        <span className="swal2-x-mark">
          <span className="swal2-x-mark-line-left"></span>
          <span className="swal2-x-mark-line-right"></span>
        </span>
      </div>
    );
  }
  if (success) {
    return (
      <div className="swal2-icon swal2-success">
        <div className="swal2-success-circular-line-left" style={{ or: "rgb(255, 255, 255)" }}></div>
        <span className="swal2-success-line-tip"></span> <span className="swal2-success-line-long"></span>
        <div className="swal2-success-ring"></div> <div className="swal2-success-fix" style={{ or: "rgb(255, 255, 255)" }}></div>
        <div className="swal2-success-circular-line-right" style={{ or: "rgb(255, 255, 255)" }}></div>
      </div>
    );
  }
  return (
    <div className="swal2-icon swal2-warning swal2-animate-warning-icon d-flex">
      <span className="swal2-icon-text">!</span>
    </div>
  );
};

const ModalButtons = ({ onConfirm, onCancel, isLoading, confirmationMessage, success, errorMessage }) => {
  if (!success && !errorMessage) {
    return (
      <>
        <button
          className="swal2-confirm swal2-styled"
          onClick={(event) => {
            event.preventDefault();
            onConfirm();
          }}
          aria-label=""
        >
          {isLoading ? <Spinner animation="border" size="sm" variant="primary" /> : confirmationMessage}
        </button>
        <button variant="danger" onClick={() => onCancel(false)} className="swal2-cancel swal2-styled d-inline" aria-label="">
          Cancel
        </button>
      </>
    );
  }

  return (
    <button className="swal2-confirm swal2-styled" onClick={() => onCancel(true)} aria-label="">
      OK
    </button>
  );
};

const ConfirmationModal = ({
  showModal,
  onConfirm,
  onCancel,
  isLoading,
  success,
  errorMessage,
  confirmationMessage,
  questionMessage,
  warningMessage,
  errorTitle,
  successTitle,
  successSubtitle,
}) => {
  return (
    <Modal show={showModal} centered onHide={onCancel}>
      <Modal.Body className="p-0">
        <div
          aria-labelledby="swal2-title"
          aria-describedby="swal2-content"
          className="swal2-popup swal2-modal swal2-show d-flex"
          tabIndex="-1"
          role="dialog"
          aria-live="assertive"
          aria-modal="true"
        >
          <div className="swal2-header">
            <ThemeModalIcon errorMessage={errorMessage} success={success} />
            <h2 className="swal2-title d-flex">
              {!errorMessage && !success && questionMessage}
              {errorMessage && errorTitle}
              {success && successTitle}
            </h2>
          </div>
          <div className="swal2-content">
            <div id="swal2-content">
              {!success && !errorMessage && warningMessage}
              {success && successSubtitle}
              {errorMessage}
            </div>
          </div>
          <div className="swal2-actions d-flex">
            <ModalButtons
              onConfirm={onConfirm}
              onCancel={onCancel}
              isLoading={isLoading}
              confirmationMessage={confirmationMessage}
              success={success}
              errorMessage={errorMessage}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmationModal;
