import React from "react";

const CustomCheckbox = ({ type = "checkbox", disabled, name, checked, value, onChange, id, classNameInput, classNameLabel }) => {
  return (
    <>
      <input type={type} name={name} checked={checked} value={value} onChange={onChange} className={classNameInput} id={id} disabled={disabled} />
      <label className={classNameLabel} htmlFor={id}></label>
    </>
  );
};

export default CustomCheckbox;
