import React from "react";
import { Modal, Table, Button } from "react-bootstrap";

const ViewLeverageabilityDataModal = ({ tableData, showModal, handleShowModal }) => {
  if (!tableData) return null;

  return (
    <Modal show={showModal} centered onHide={handleShowModal} dialogClassName="modal--85w">
      <Modal.Body>
        <Table responsive>
          <thead>
            <tr>
              <th>{tableData.quotaGroupName}</th>
              <th></th>
              {tableData.leverageabilityRows.map((row, index) => (
                <th key={index}>{index + 1}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.leverageabilityRows.map((row, index) => {
              const emptyCells = [];
              for (let emptyCell = 0; emptyCell < index; emptyCell++) {
                emptyCells.push(<td></td>);
              }
              return (
                <tr>
                  <td>{row.messageNumber}</td>
                  <td>{Math.round(row.messageValue)}</td>
                  {emptyCells}
                  {row.correlationCoefficients.map((coefficient) => (
                    <td>{coefficient.toFixed(2)}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </Table>
        <div className="d-flex justify-content-end mt-3">
          <Button variant="primary" onClick={handleShowModal}>
            Close
          </Button>
        </div>
      </Modal.Body>
      {/* <Modal.Footer>
      </Modal.Footer> */}
    </Modal>
  );
};

export default ViewLeverageabilityDataModal;
