import React, { useState, useEffect } from "react";
import { LeverageabilityUploadTable } from "components/Tables";
import { DropzoneCreateProject } from "components/Forms";
import { uploadLeverageabilityBulk } from "apis/projectApi";
import BulkFileUploadErrors from "components/Wizards/CreateProjectWizard/BulkFileUploadErrors";

const LeverageabilityStep = ({ draftId, quotaGroupsData, changeLeverageabilityValidation, handleQuotaGroupsData, handleReachData }) => {
  const redirectTimeout = 5000;
  const maxFileSize = 20000000;
  const [someQuotaGroupsUploaded, setSomeQuotaGroupsUploaded] = useState(false);

  //Array of objects
  const [dropzoneErrorMessages, setDropzoneErrorMessages] = useState([]);

  const handleDropzoneErrorMessages = ({ fileUploadErrors = [] }) => {
    if (fileUploadErrors.length === 0) {
      setDropzoneErrorMessages("");
    } else {
      const errorMessages = fileUploadErrors.map((error) => {
        switch (error.result) {
          case 2:
            return `Upload of file ${error.fileName} failed. Max. file size is ${maxFileSize / 1000000} MB.`;
          case 3:
            return `Upload of file ${error.fileName}. Quota group in cell A1 does not exist in this project`;
          case 4:
            return `Upload of file ${error.fileName}. Quota Group label at cell A1 is empty`;
          case 5:
            return `Upload of file ${error.fileName} failed. Quota group label format error at cell A1. Values in cell A1 have to correspond to quota group id numbers.`;
          case 17:
            return `Upload failed. Multiple files uploaded for quota group ${error.errorValue}.`;
          case 18:
            return `Upload of file ${error.fileName} failed. File must be an Excel spreadsheet.`;
          case 19:
            return `Upload of file ${error.fileName} failed. Wrong file type. File worksheet name is supposed to be called "Leveragability Upload". Uploaded worksheet name: "${error.errorValue}"`;
          default:
            break;
        }
        return "";
      });
      setDropzoneErrorMessages(errorMessages);
    }
  };

  const handleBulkFileUpload = async ({ files, draftId }) => {
    let bulkUploadResult = await uploadLeverageabilityBulk({ draftId, files });
    if (bulkUploadResult.data) {
      switch (bulkUploadResult.data.result) {
        case 0:
          handleQuotaGroupsData(bulkUploadResult.data.quotaGroups);
          handleDropzoneErrorMessages({ fileUploadErrors: bulkUploadResult.data.rejectedFiles });
          break;
        case 1:
          setDropzoneErrorMessages(["Undefined error"]);
          break;
        case 2:
          setDropzoneErrorMessages(["You are not authorized to upload a file to this draft."]);
          break;
        case 3:
          setDropzoneErrorMessages([`This account has been deleted. You will be redirected to the login screen in ${redirectTimeout} seconds`]);
          break;
        case 4:
          setDropzoneErrorMessages(["The draft you are trying to upload files to does not exist."]);
          break;
        case 5:
          setDropzoneErrorMessages(["No files have been uploaded."]);
          break;
        case 6:
          setDropzoneErrorMessages(["File upload failed."]);
          break;
        case 7:
          setDropzoneErrorMessages([
            `Too many files uploaded. Please upload exactly ${quotaGroupsData.length} leverageability files - one for each quota group`,
          ]);
          break;
        default:
          break;
      }
    }
  };

  const handleFileInfo = (fileSize) => {
    if (fileSize >= 2000000) {
      return `${Math.ceil(fileSize / 2000000).toFixed(2)} MB`;
    } else {
      return `${Math.ceil(fileSize / 1000)} KB`;
    }
  };

  useEffect(() => {
    if (quotaGroupsData.some((quotaGroup) => quotaGroup.leverageabilityUploaded === true)) {
      setSomeQuotaGroupsUploaded(true);
    } else {
      setSomeQuotaGroupsUploaded(false);
    }
  }, [quotaGroupsData]);

  return (
    <div className="pt-4">
      <div className="py-5">
        <h6>Now, you need to upload the leverageability table for each quota group. Each file will be verified as you upload it.</h6>
      </div>
      {!someQuotaGroupsUploaded && (
        <DropzoneCreateProject
          text={"quota group leverageability data"}
          handleFileUpload={handleBulkFileUpload}
          handleFileInfo={handleFileInfo}
          draftId={draftId}
          handleErrorMessages={handleDropzoneErrorMessages}
          multiple
        />
      )}
      {dropzoneErrorMessages.length > 0 && <BulkFileUploadErrors errorMessages={dropzoneErrorMessages} />}
      <LeverageabilityUploadTable
        draftId={draftId}
        quotaGroupsData={quotaGroupsData}
        changeLeverageabilityValidation={changeLeverageabilityValidation}
        handleQuotaGroupsData={handleQuotaGroupsData}
        handleReachData={handleReachData}
        handleDropzoneErrorMessages={handleDropzoneErrorMessages}
      />
    </div>
  );
};

export default LeverageabilityStep;
