import React from "react";
import UsersTableActions from "./UsersTableActions";

const UsersTableRow = ({ user, handleEditUserModalOpen, handleDeleteUser, onStatusChange }) => {
  const parseUserType = (userType) => {
    let parsedType = "";

    switch (userType) {
      case 0:
        parsedType = "Admin";
        break;
      case 1:
        parsedType = "Employee";
        break;
      case 2:
        parsedType = "Client";
        break;
      default:
        break;
    }
    return parsedType;
  };

  return (
    <tr role="row" className="odd">
      <td tabIndex="0" className="sorting_1">
        {`${user.firstName} ${user.lastName}`}
      </td>
      <td className="">{user.email}</td>
      <td>{parseUserType(user.userType)}</td>
      <td>{user.isApproved ? <span>Approved</span> : <span className="text-danger">Waiting for approval</span>}</td>
      <UsersTableActions user={user} handleEditUserModalOpen={handleEditUserModalOpen} handleDeleteUser={handleDeleteUser} onStatusChange={onStatusChange} />
    </tr>
  );
};

export default UsersTableRow;
