import React from "react";
import { Modal, Button } from "react-bootstrap";
import { ErrorText } from "components/Text";

const CreateNewProjectModal = ({ showModal, onModalClose, onUseDraft, onDiscardDraft, errorMessage }) => {
  return (
    <Modal size="lg" centered show={showModal} onHide={onModalClose}>
      <Modal.Header closeButton>
        <Modal.Title className="text-center w-100">
          <h3>You have an existing draft.</h3>
        </Modal.Title>
      </Modal.Header>

      {errorMessage && (
        <Modal.Body>
          <ErrorText text={errorMessage} />
          <Button onClick={onModalClose}>Close</Button>
        </Modal.Body>
      )}

      {!errorMessage && (
        <Modal.Body className="d-flex justify-content-around">
          <Button variant="btn btn-outline-primary" onClick={onDiscardDraft}>
            Discard Draft and create a New Project
          </Button>
          <Button onClick={onUseDraft}>Continue with Draft</Button>
        </Modal.Body>
      )}
    </Modal>
  );
};

export default CreateNewProjectModal;
