import { typeUserRegistration } from "constants/actionTypes";
import { register } from "apis/usersApi";
import { authCookieName, authCookieDuration } from "constants/general";
import { setCookie } from "utility/cookie";
import { changeRoute } from "utility/routing";
import ROUTES from "constants/routes";

export const selectUserType = (selectedType) => {
  return (dispatch) => {
    dispatch({
      type: typeUserRegistration.selectUserType,
      payload: selectedType,
    });
  };
};

export const registerUser = (registrationData) => {
  return async (dispatch) => {
    dispatch({
      type: typeUserRegistration.registerUserStart,
    });

    let errorMessage = "";

    const registrationResult = await register(registrationData);

    if (registrationResult.data) {
      switch (registrationResult.data.result) {
        case 0:
          setCookie(authCookieName, registrationResult.data.bearerToken, authCookieDuration);
          dispatch({
            type: typeUserRegistration.registerUserEnd,
            payload: {
              response: {
                result: registrationResult.data.result,
                message: "",
              },
            },
          });
          changeRoute(ROUTES.notApproved);
          break;
        case 1:
          errorMessage = "Unknown error";
          break;
        case 3:
          errorMessage = "Password needs to be at least five characters long";
          break;
        case 4:
          errorMessage = `User with the email ${registrationData.email} already exists`;
          break;
        case 8:
          errorMessage = "Please fill out all form fields";
          break;
        default:
          break;
      }
    } else if (registrationResult) {
      errorMessage = "Unknown error";
    } else {
      errorMessage = "Unknown error";
    }
    dispatch({
      type: typeUserRegistration.registerUserEnd,
      payload: {
        response: {
          result: registrationResult.data ? registrationResult.data.result : 99,
          message: errorMessage,
        },
      },
    });
  };
};

export const resetResponse = () => {
  return (dispatch) => {
    dispatch({
      type: typeUserRegistration.resetResponse,
    });
  };
};
