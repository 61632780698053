import React from "react";
import classNames from "classnames";

const TableHeaderCell = ({ title, handleSelectSortingOrder, currentColumn, activeColumn, orderIsDescending, isLastColumn, className }) => {
  const headerClassesString = classNames("cursor-pointer", "table-header-with-sort", className, currentColumn === activeColumn && "active");

  const iconClassesString = classNames(
    "triangle",
    orderIsDescending && currentColumn === activeColumn && "transformX-180 ",
    !orderIsDescending && currentColumn === activeColumn && "",
    "ml-2"
  );
  const handleClick = () => {
    if (handleSelectSortingOrder) {
      handleSelectSortingOrder({ selectedColumn: currentColumn });
    }
  };

  return (
    <th className={headerClassesString} style={isLastColumn ? { width: 300 } : {}} onClick={handleClick}>
      <span>{title}</span>
      <i className={iconClassesString}></i>
    </th>
  );
};

export default TableHeaderCell;
