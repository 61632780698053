import React from "react";
import { Row, Col } from "react-bootstrap";

const FileUploadErrors = ({ errorMessage }) => {
  return (
    <Row>
      <Col>
        <p>The following errors were found while verifying the file:</p>
        <ol className="ordered-list-alignment-inside">
          <li className="text-danger">{errorMessage}</li>
        </ol>
        <p className="font-weight-bold">Click the Trash Can icon above to remove this file!</p>
        <p className="font-weight-bold">Correct the issue with the file and re-import it.</p>
      </Col>
    </Row>
  );
};

export default FileUploadErrors;
