import React, { useState, useEffect } from "react";
import { Card, Spinner } from "react-bootstrap";
import { RoundButton } from "components/Button";
import classNames from "classnames";
import { Icon } from "components/Icon";
import { ErrorText } from "components/Text";
import { connect } from "react-redux";
import { ConfirmationModal } from "components/Modals";
import { downloadResultsFile, cancelDownloadResultsFile, retryFileDownload, cancelAllDownloads } from "apis/simulationApi";
import { createAnchorAndDownloadFile } from "utility/fileDownload";
import { bindActionCreators } from "redux";
import { setFileDownloadList, closeDownloadPanel, setFileListErrorMessage, getDownloadFilesQueue } from "actions/simulationActions";

export const DownloadItem = ({ fileId, fileName, downloadStatus, handleDownloadFile, handleCancelFileDownload, handleRetryFileDownload }) => {
  const [showCancelButton, setShowCancelButton] = useState(false);
  return (
    <div className="d-flex justify-content-between align-items-center py-2 download-panel__download-item">
      <p className="mb-0 pb-0">{fileName}</p>

      {/* Downloading */}
      {(downloadStatus === 0 || downloadStatus === 1) && (
        <div className="d-flex justify-content-end w-100" onMouseEnter={() => setShowCancelButton(true)} onMouseLeave={() => setShowCancelButton(false)}>
          {!showCancelButton ? (
            <Spinner variant="primary" animation="border" size="sm" className="mt-1" />
          ) : (
            <RoundButton iconClasses="fa fa-times" buttonClasses="btn-secondary mt-1" onClick={() => handleCancelFileDownload({ fileId })} />
          )}
        </div>
      )}

      {/* Downloaded */}
      {downloadStatus === 2 && (
        <div className="d-flex">
          <div>
            <RoundButton iconClasses="fas fa-download" buttonClasses="btn-secondary mr-2 mt-1" onClick={() => handleDownloadFile({ fileId })} />
          </div>
          <div onMouseEnter={() => setShowCancelButton(true)} onMouseLeave={() => setShowCancelButton(false)}>
            {showCancelButton ? (
              <RoundButton iconClasses="fa fa-times" buttonClasses="btn-secondary mt-1" onClick={() => handleCancelFileDownload({ fileId })} />
            ) : (
              <RoundButton iconClasses="fas fa-check" buttonClasses="btn-primary mt-1" />
            )}
          </div>
        </div>
      )}

      {/* Error */}
      {downloadStatus === 3 && (
        <div className="d-flex justify-content-end align-items-center">
          <ErrorText className="mx-3 pb-0 mb-0" text="Download Failed" />
          <RoundButton iconClasses="fas fa-undo" buttonClasses="btn-secondary mr-2" onClick={() => handleRetryFileDownload({ fileId })} />
          <RoundButton iconClasses="fa fa-times" buttonClasses="btn-secondary" onClick={() => handleCancelFileDownload({ fileId })} />
        </div>
      )}
    </div>
  );
};

const DownloadPanel = ({ files, setFileDownloadList, closeDownloadPanel, getDownloadFilesQueue, fileListErrorMessage, setFileListErrorMessage }) => {
  //Visual state
  const [isMinimized, setIsMinimized] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showDownloadErrorModal, setShowDownloadErrorModal] = useState(false);
  const [downloadErrorMessage, setDownloadErrorMessage] = useState("");
  const [confirmationModalIsLoading, setConfirmationModalIsLoading] = useState(false);

  const [filesAreLoading, setFilesAreLoading] = useState(false);
  useEffect(() => {
    if (!files) return;

    setFilesAreLoading(files.some((file) => file.status === 0 || file.status === 1));
  }, [files]);

  //Handler functions
  const handleToggleDownloadPanel = () => {
    setIsMinimized(!isMinimized);
  };

  const handleDownloadFile = async ({ fileId }) => {
    const response = await downloadResultsFile({ fileId });
    if (response.error) {
      switch (response.errorMessage.response.status) {
        case 401:
          setDownloadErrorMessage("You are not authorized to download this file");
          setShowDownloadErrorModal(true);
          break;
        case 404:
          setDownloadErrorMessage("File not found");
          setShowDownloadErrorModal(true);
          break;
        default:
          break;
      }
    } else {
      const data = response.data;

      createAnchorAndDownloadFile({ data, response });
    }
  };

  const handleCancelFileDownload = async ({ fileId }) => {
    const response = await cancelDownloadResultsFile({ fileId });
    const data = response.data;

    if (response && data) {
      switch (data.result) {
        case 0:
          setFileDownloadList(data.files);
          if (data.files.length === 0) {
            closeDownloadPanel();
          }
          setFileListErrorMessage("");
          break;
        case 1:
          setDownloadErrorMessage("Undefined error");
          setShowDownloadErrorModal(true);
          break;
        case 2:
          setDownloadErrorMessage("You are not authorized to cancel download of this file");
          setShowDownloadErrorModal(true);
          break;
        case 3:
          setDownloadErrorMessage("File not found");
          setShowDownloadErrorModal(true);
          break;
        default:
          setFileDownloadList(data.files);
          break;
      }
    }
  };

  const handleCloseDownloadPanel = () => {
    if (files && files.length > 0) {
      setShowConfirmationModal(!showConfirmationModal);
    } else {
      closeDownloadPanel();
    }
  };

  const handleCloseDownloadErrorModal = () => {
    setShowDownloadErrorModal(false);
    setDownloadErrorMessage("");
    if (!filesAreLoading) {
      getDownloadFilesQueue();
    }
  };

  const handleCancelAllDownloads = async () => {
    setConfirmationModalIsLoading(true);
    const response = await cancelAllDownloads();
    const data = response.data;
    setConfirmationModalIsLoading(false);
    if (response && data) {
      switch (data.result) {
        case 0:
          setFileDownloadList(data.files);
          if (data.files.length === 0) {
            closeDownloadPanel();
          }
          setFileListErrorMessage("");
          break;
        case 1:
          setFileListErrorMessage("Undefined error");
          break;
        default:
          setFileListErrorMessage("Undefined error");
          break;
      }
    }
  };

  const handleRetryFileDownload = async ({ fileId }) => {
    const response = await retryFileDownload({ fileId });
    const data = response.data;

    if (response && data) {
      switch (data.result) {
        case 0:
          setFileDownloadList(data.files);
          if (data.files.length === 0) {
            closeDownloadPanel();
          }
          setFileListErrorMessage("");
          break;
        case 1:
          setDownloadErrorMessage("Undefined error");
          setShowDownloadErrorModal(true);
          break;
        case 2:
          setDownloadErrorMessage("You are not authorized to cancel download of this file");
          setShowDownloadErrorModal(true);
          break;
        case 3:
          setDownloadErrorMessage("File not found");
          setShowDownloadErrorModal(true);
          break;
        default:
          setFileDownloadList(data.files);
          break;
      }
    }
  };

  const downloadPanelBodyClasses = classNames(
    "download-panel__body",
    "px-3",
    "scrollable",
    !isMinimized && "mt-2",
    isMinimized && "download-panel__body--collapsed"
  );
  const panelCollapseIconClasses = classNames(isMinimized ? "fas fa-angle-up" : "fas fa-angle-down");

  return (
    <>
      {/* Cancel all downloads confirmation */}
      {showConfirmationModal && (
        <ConfirmationModal
          showModal={showConfirmationModal}
          onConfirm={handleCancelAllDownloads}
          onCancel={handleCloseDownloadPanel}
          questionMessage="Are you sure you wish to proceed?"
          warningMessage="This will remove all of your prepared files and cancel all your pending downloads"
          confirmationMessage="Yes, cancel all downloads"
          isLoading={confirmationModalIsLoading}
        />
      )}

      {/* Download error modal */}
      {showDownloadErrorModal && (
        <ConfirmationModal
          showModal={showDownloadErrorModal}
          onConfirm={handleCloseDownloadErrorModal}
          onCancel={handleCloseDownloadErrorModal}
          errorTitle="Download error"
          confirmationMessage="Ok"
          errorMessage={downloadErrorMessage}
        />
      )}
      <Card className="download-panel" border="dark">
        <Card.Header className="download-panel__header d-flex justify-content-between">
          {files && files.some((file) => file.status === 0 || file.status === 1) ? "Preparing your files" : "Download Ready"}
          <div>
            <Icon name={panelCollapseIconClasses} className="cursor-pointer mr-3" onClick={handleToggleDownloadPanel} />
            <Icon name="fa-times" className="cursor-pointer" onClick={handleCloseDownloadPanel} />
          </div>
        </Card.Header>
        <div className={downloadPanelBodyClasses}>
          {files &&
            files.length > 0 &&
            files.map((file) => (
              <DownloadItem
                key={file.fileId}
                downloadStatus={file.status}
                fileId={file.fileId}
                projectId={file.projectId}
                fileName={file.displayFileName}
                handleDownloadFile={handleDownloadFile}
                handleCancelFileDownload={handleCancelFileDownload}
                handleRetryFileDownload={handleRetryFileDownload}
              />
            ))}
          {fileListErrorMessage && <div className="my-5 mx-5">{<ErrorText text={fileListErrorMessage} className="my-5 mx-5" />}</div>}
        </div>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({ user: state.user, fileListErrorMessage: state.simulation.fileListErrorMessage });

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ setFileDownloadList, closeDownloadPanel, setFileListErrorMessage, getDownloadFilesQueue }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadPanel);
