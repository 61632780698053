import { typeUser } from "constants/actionTypes";
import { login, changePassword, forgotPassword, getUserApprovalStatus, getCurrentUser } from "apis/usersApi";
import ROUTES from "constants/routes";
import { changeRoute } from "utility/routing";
import { authCookieName, authCookieDuration } from "constants/general";
import { deleteCookie } from "utility/cookie";
import { setCookie } from "utility/cookie";

export const userLogin = ({ email, password }) => {
  return async (dispatch) => {
    dispatch({
      type: typeUser.loginStart,
    });

    let errorMessage = "";

    const loginResult = await login({ email: email, password: password });

    if (loginResult.data) {
      switch (loginResult.data.result) {
        case 0:
          setCookie(authCookieName, loginResult.data.bearerToken, authCookieDuration);
          localStorage.setItem("isApproved", "true");
          dispatch({
            type: typeUser.loginEnd,
            payload: {
              isLoggedIn: true,
              isApproved: true,
              response: {
                result: loginResult.data.result,
                message: "",
              },
            },
          });
          return;
        case 1:
          errorMessage = "Unknown error";
          break;
        case 2:
          errorMessage = "Invalid username or password";
          break;
        case 5:
          errorMessage = "You have not yet been granted permission to use the application. Please contact an administrator. ";
          setCookie(authCookieName, loginResult.data.bearerToken, authCookieDuration);
          localStorage.setItem("isApproved", "false");
          dispatch({
            type: typeUser.loginEnd,
            payload: {
              response: {
                result: loginResult.data.result,
              },
              isLoggedIn: true,
              isApproved: false,
            },
          });
          return;
        default:
          break;
      }
      //Handles Axios error
    } else if (loginResult) {
      errorMessage = loginResult.error;
    } else {
      errorMessage = "Undefined error";
    }
    dispatch({
      type: typeUser.loginEnd,
      payload: {
        isLoggedIn: false,
        response: {
          result: loginResult.data ? loginResult.data.result : 99,
          message: errorMessage,
        },
      },
    });
  };
};

export const userApprovalStatus = () => {
  return async (dispatch) => {
    const getUserApprovalStatusResult = await getUserApprovalStatus();
    let errorMessage = "";

    if (getUserApprovalStatusResult.data) {
      switch (getUserApprovalStatusResult.data.result) {
        case 0:
          dispatch({
            type: typeUser.getUserApprovalStatus,
            payload: {
              isUserApproved: getUserApprovalStatusResult.data.isUserApproved,
            },
          });
          break;
        case 1:
          errorMessage = "Undefined error";
          break;
        case 10:
          errorMessage = "User does not exist";
          break;
        default:
          break;
      }
      //Handles axios error
    } else if (getUserApprovalStatusResult) {
      errorMessage = getUserApprovalStatusResult.error;
    } else {
      errorMessage = "Undefined error";
    }
    dispatch({
      type: typeUser.getUserApprovalStatus,
      payload: {
        response: {
          result: 99,
          errorMessage,
        },
      },
    });
  };
};

export const userForgotPassword = (email) => {
  return async (dispatch) => {
    dispatch({
      type: typeUser.loginStart,
    });

    let message = "";

    const forgotPasswordResult = await forgotPassword(email);
    if (!forgotPasswordResult.data.success) {
      message = "Password reset failed. Unknown error";
    } else {
      message = "Password reset link sent successfully. Please check your inbox.";
    }

    dispatch({
      type: typeUser.loginEnd,
      payload: {
        response: {
          result: forgotPasswordResult,
          message,
        },
      },
    });
  };
};

export const fetchCurrentUserData = () => {
  return async (dispatch) => {
    const response = await getCurrentUser();
    if (response.status !== 200) {
      dispatch({
        type: typeUser.currentData,
      });
    } else {
      dispatch({
        type: typeUser.currentData,
        payload: response.data.item,
      });
    }
  };
};

export const userResetPassword = (resetPasswordData) => {
  return async (dispatch) => {
    dispatch({
      type: typeUser.loginStart,
    });

    let message = "";
    //Routing timeout is defined in seconds
    const routingTimeout = 5;
    const resetPasswordResult = await changePassword(resetPasswordData);
    if (resetPasswordResult.data.success) {
      dispatch({
        type: typeUser.loginEnd,
        payload: {
          isLoggedIn: false,
          isApproved: false,
          response: {
            result: resetPasswordResult.data.success,
            message: `Password reset successful. You will be redirected to the login page in ${routingTimeout} seconds`,
          },
        },
      });
      deleteCookie("AUTH_COOKIE");
      setTimeout(function() {
        changeRoute(ROUTES.login);
      }, routingTimeout * 1000);
    }

    if (!resetPasswordResult.data.success) {
      switch (resetPasswordResult.data.error) {
        case 2:
          message = "Unknown error";
          break;
        case 3:
          message = "Invalid reset token";
          break;
        case 4:
          message = "Max number of password reset attempts exceeded";
          break;
        case 5:
          message = `User with email ${resetPasswordData.email} does not exist`;
          break;
        case 6:
          message = "Password too short";
          break;
        case 7:
          message = "Old password is invalid";
          break;
        case 8:
          message = "Missing required fields";
          break;
        case 9:
          message = "You are not logged in";
          break;
        default:
          break;
      }
      dispatch({
        type: typeUser.loginEnd,
        payload: {
          response: {
            result: resetPasswordResult.data.success,
            message,
          },
        },
      });
    }
  };
};

export const resetResponse = () => {
  return async (dispatch) => {
    dispatch({
      type: typeUser.resetResponse,
    });
  };
};
