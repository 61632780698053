import React from "react";
import classNames from "classnames";
import { a11yKeyboardClick } from "utility/a11y";

const isFontAwesomeIcon = (iconName) => {
  return iconName.includes("fa-");
};

const getIconFontClass = (iconName) => {
  if (isFontAwesomeIcon(iconName)) {
    return "fas";
  }
};

const Icon = ({ name, className, onClick, title, context }) => {
  const classesString = classNames("icon", getIconFontClass(name), name, context ? `text-${context}` : false, onClick ? "cursor-pointer" : false, className);

  const handleAccessibilityClick = (event) => {
    a11yKeyboardClick(event, onClick, {
      spacebarClick: true,
    });
  };

  return (
    <i
      className={classesString}
      title={title || null}
      onClick={onClick || null}
      role={onClick ? "button" : "img"}
      tabIndex={onClick ? 0 : null}
      onKeyDown={onClick ? handleAccessibilityClick : null}
    ></i>
  );
};

export default Icon;
