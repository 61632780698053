import React from "react";
import { Row, Table } from "react-bootstrap";
import UsersTableRow from "./UsersTableRow";

const UsersTable = ({ users, handleEditUserModalOpen, handleDeleteUser, onUserStatusChange }) => {
  return (
    <div>
      <Row className="w-100">
        {/* //TODO: Leave here if search is needed when Lieberman replies */}
        {/* //TODO: Remove 12px right padding on input element */}
        {/* <Col sm={12} md={6} className="d-flex justify-content-end">
          <label>
            Search:
            <input type="search" className="form-control form-control-sm" placeholder="" />
          </label>
        </Col> */}
      </Row>
      <Row>
        <div className="col-sm-12">
          <Table responsive className="table">
            <thead className="thead-light">
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>User type</th>
                <th>Status</th>
                <th className="text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {users &&
                users.map((user) => (
                  <UsersTableRow
                    key={user.email}
                    user={user}
                    handleDeleteUser={handleDeleteUser}
                    handleEditUserModalOpen={handleEditUserModalOpen}
                    onStatusChange={onUserStatusChange}
                  />
                ))}
            </tbody>
          </Table>
        </div>
      </Row>
    </div>
  );
};

export default UsersTable;
