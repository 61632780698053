import axios from "axios";
import { authCookieName } from "constants/general";
import { getCookie } from "utility/cookie";

const getBaseUrl = () => {
  return axios.create({
    baseURL: window.location.origin + "/api/simulation",
    headers: { Authorization: "Bearer " + getCookie(authCookieName) },
  });
};

export const checkDataSetGenerationStatus = async (projectId) => {
  const params = {
    projectId,
  };
  return getBaseUrl()
    .get("/checkDatasetGenerationStatus", { params })
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};

export const createSimulation = (projectId, name, selectedQuotaGroupIds, isWeighted, selectedMessageGroups, includeAllMessages, messages) => {
  return getBaseUrl()
    .post("/createSimulation", {
      projectId,
      name,
      selectedQuotaGroupIds,
      isWeighted,
      selectedMessageGroups,
      includeAllMessages,
      messages,
    })
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};

export const deleteSimulation = async (simulationId) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return getBaseUrl()
    .post("/deleteSimulation", simulationId, { headers })
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};

export const getSimulations = async (projectId, page, pageSize, orderIsDescending, sortBy) => {
  const params = {
    projectId,
    page,
    pageSize,
    orderIsDescending,
    sortBy,
  };
  return getBaseUrl()
    .get("/getSimulations", { params })
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};

export const getSimulationData = async (simulationId) => {
  const params = {
    simulationId,
  };
  return getBaseUrl()
    .get("/getSimulationData", { params })
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};

export const runSimulation = async (
  projectId,
  simulationId,
  quotaGroupId,
  page = 1,
  pageSize = 10,
  orderIsDescending,
  sortBy,
  reachMin,
  reachMax,
  leverageabilityMin,
  leverageabilityMax,
  adjLeverageabilityMin,
  adjLeverageabilityMax,
  messageIds,
  messageCategories
) => {
  const params = {
    projectId,
    simulationId,
    quotaGroupId,
    page,
    pageSize,
    orderIsDescending,
    sortBy,
    reachMin,
    reachMax,
    leverageabilityMin,
    leverageabilityMax,
    adjLeverageabilityMin,
    adjLeverageabilityMax,
    messageIds,
    messageCategories,
  };
  return getBaseUrl()
    .get("/runSimulation", { params })
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};

export const queueFileDownload = async (
  projectId,
  simulationId,
  applyFilters,
  orderIsDescending,
  sortBy,
  reachMin,
  reachMax,
  leverageabilityMin,
  leverageabilityMax,
  adjLeverageabilityMin,
  adjLeverageabilityMax,
  messageIds,
  messageCategories
) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return getBaseUrl()
    .post(
      "/queueFileDownload",
      {
        projectId,
        simulationId,
        applyFilters,
        orderIsDescending,
        sortBy,
        reachMin,
        reachMax,
        leverageabilityMin,
        leverageabilityMax,
        adjLeverageabilityMin,
        adjLeverageabilityMax,
        messageIds,
        messageCategories,
      },
      { headers }
    )
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};

export const getQueuedFilesByUser = async () => {
  return getBaseUrl()
    .get("/getQueuedFilesByUser")
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};

export const downloadResultsFile = async ({ fileId }) => {
  const params = {
    fileId,
  };
  return getBaseUrl()
    .get("/downloadResultsFile", { params, responseType: "blob" })
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};

export const cancelDownloadResultsFile = async ({ fileId }) => {
  return getBaseUrl()
    .post("/cancelDownloadResultsFile", {
      fileId,
    })
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};

export const retryFileDownload = async ({ fileId }) => {
  return getBaseUrl()
    .post("/retryFileDownload", {
      fileId,
    })
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};

export const cancelAllDownloads = async () => {
  return getBaseUrl()
    .post("/cancelAllDownloads")
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};

export const saveSimulationFilters = async ({
  simulationId,
  reachMin,
  reachMax,
  leverageabilityMin,
  leverageabilityMax,
  adjustedleverageabilityMin,
  adjustedleverageabilityMax,
  messages,
  categories,
}) => {
  return getBaseUrl()
    .post("/saveSimulationFilters", {
      simulationId,
      reachMin,
      reachMax,
      leverageabilityMin,
      leverageabilityMax,
      adjustedleverageabilityMin,
      adjustedleverageabilityMax,
      messages,
      categories,
    })
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};

export const deleteSimulationFilters = async ({ simulationId }) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return getBaseUrl()
    .post("/deleteSimulationFilters", simulationId, { headers })
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};
