import React from "react";
import { Row, Col } from "react-bootstrap";
import { CustomLink } from "components/CustomLink";
import ROUTES from "constants/routes";
import mainLogo from "assets/img/logo.png";

const RegistrationWrapper = ({ children, userType }) => {
  return (
    <div className="account-body">
      <Row className="row vh-100">
        <Col className="col-12 align-self-center">
          <div className="text-center">
            <img src={mainLogo} alt="Compel logo" />
          </div>

          <div className="text-center auth-logo-text">
            <h4 className="mb-3 mt-5">Register a new {userType === null ? "" : userType === 1 ? "employee " : "client "} account</h4>
          </div>
          <div className="registration-choices-wrapper">
            <div className="auth-page">{children}</div>
          </div>
          <div className="m-3 text-center">
            <p>
              <CustomLink href={ROUTES.login} className="text-primary ml-2">
                Already have an account? Press here to sign in
              </CustomLink>
            </p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default RegistrationWrapper;
