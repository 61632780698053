import React, { useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";

const DropzoneCreateProject = ({
  text,
  handleFileUpload,
  handleFileInfo,
  handleErrorMessages,
  draftId,
  setShowProgressBar,
  multiple,
  maxFileSize = 20000000,
}) => {
  const onDropAccepted = useCallback(
    (files) => {
      if (multiple) {
        handleErrorMessages({});
        handleFileUpload({ files, draftId });
      } else {
        handleErrorMessages();
        let droppedFile = files[0];
        handleFileInfo(droppedFile.name, droppedFile.size);
        handleFileUpload(files[0], draftId);
      }
    },
    [draftId, handleFileInfo, handleFileUpload, handleErrorMessages, multiple]
  );

  const onDropRejected = useCallback(
    (files) => {
      if (multiple) {
        const errorObjects = [];
        let droppedFiles = files;
        droppedFiles.forEach((droppedFile) => {
          handleFileInfo(droppedFile.name, droppedFile.size);

          let xlsRegex = new RegExp(/^[^.]+.xls$/);
          let xlsxRegex = new RegExp(/^[^.]+.xlsx$/);

          if (!xlsRegex.test(droppedFile.name) && !xlsxRegex.test(droppedFile.name)) {
            errorObjects.push({ fileName: droppedFile.name, result: 18 });
          }

          if (droppedFile.size >= maxFileSize) {
            errorObjects.push({ fileName: droppedFile.name, result: 2 });
          }
        });
        handleErrorMessages({ fileUploadErrors: errorObjects });
      }
      //Single file upload
      else {
        const errorMessages = [];
        let droppedFile = files[0];

        handleFileInfo(droppedFile.name, droppedFile.size);

        let xlsRegex = new RegExp(/^[^.]+.xls$/);
        let xlsxRegex = new RegExp(/^[^.]+.xlsx$/);

        if (!xlsRegex.test(droppedFile.name) && !xlsxRegex.test(droppedFile.name)) {
          errorMessages.push("Upload failed. File must be an Excel spreadsheet.");
        }

        if (droppedFile.size >= maxFileSize) {
          errorMessages.push(`Upload failed. Max. file size is ${maxFileSize / 1000000} MB.`);
        }
        setShowProgressBar(true);
        handleErrorMessages(errorMessages);
      }
    },
    [handleFileInfo, handleErrorMessages, setShowProgressBar, maxFileSize, multiple]
  );

  const activeStyle = {
    borderStyle: "none",
    boxShadow: "0px 0px 6px 0px rgba(0, 176, 104, 1)",
    borderColor: "#00b068",
    opacity: 0.65,
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: [".xls", ".xlsx"],
    maxSize: maxFileSize,
    onDropAccepted: onDropAccepted,
    onDropRejected: onDropRejected,
  });

  const style = useMemo(
    () => ({
      ...(isDragActive ? activeStyle : {}),
    }),
    [isDragActive, activeStyle]
  );

  return (
    <>
      <p>To begin, please upload the file containing the {text} by clicking the box below or dragging the file into it.</p>
      <div className="dropzone dz-clickable" {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p className="pt-1 dz-message dz-clickable">
          <span className="dz-button">Drop files here to upload</span>
        </p>
      </div>
      <p className="pt-1">{`Max. file size is ${maxFileSize / 1000000} MB.`}</p>
    </>
  );
};

export default DropzoneCreateProject;
