import React, { useState } from "react";
import { Dropdown, Table, Button } from "react-bootstrap";
import { CustomToggle } from "components/Filters";
import { CustomCheckboxv2 } from "components/Forms";

const CheckboxTableFilterCategory = ({ filterTitle, onChange, onClose, onApply, filterData, tableHeaders, name, numberOfSelected, onDropdownClose }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const onDropDownToggle = () => {
    setShowDropdown(!showDropdown);
    onDropdownClose({ filterName: name });
  };

  //TODO: How to fix behavior that shows dropdown when the users enters filters through the address bar
  // useEffect(() => {
  //   numberOfSelected === 0 ? setShowDropdown(true) : setShowDropdown(false);
  // }, [numberOfSelected]);
  return (
    <Dropdown className="mx-2 my-2 custom-select-dropdown" show={showDropdown} onToggle={onDropDownToggle}>
      <Dropdown.Toggle onClose={onClose} as={CustomToggle}>
        {`${numberOfSelected} ${filterTitle} selected`}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <div className="dropdown__menu dropdown__menu--scrollable select-filter__background--narrow">
          <Table>
            <thead>
              <tr>
                <td>#</td>
                {tableHeaders.map((header, index) => (
                  <td key={index}>{header}</td>
                ))}
              </tr>
            </thead>
            <tbody>
              {filterData.map((category, index) => (
                <tr key={category.categoryName + index}>
                  <td>{index + 1}</td>
                  <td>
                    <CustomCheckboxv2
                      label={category.categoryName}
                      key={category.categoryName}
                      name={name}
                      checked={category.isChecked}
                      value={category.categoryName}
                      onChange={onChange}
                      id={category.categoryName}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
        <div className="text-right p-2 ">
          <Button
            onClick={() => {
              onApply();
              onDropDownToggle();
            }}
            variant="outline-primary"
          >
            Apply
          </Button>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CheckboxTableFilterCategory;
