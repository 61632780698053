import React from "react";

const BrowseFiles = ({ text, handleLeverageabilityUpload }) => {
  return (
    <label className="btn btn-outline-primary mb-0">
      {text} <input type="file" hidden onChange={handleLeverageabilityUpload} />
    </label>
  );
};

export default BrowseFiles;
