import React, { useEffect } from "react";
import { connect } from "react-redux";
import { PageWrapper } from "components/Layout";
import { MainNavigation } from "components/MainNavigation";
import { Footer } from "components/Footer";
import { DownloadPanel } from "components/DownloadPanel";
import { setFileDownloadList, setFileListErrorMessage, getDownloadFilesQueue } from "actions/simulationActions";
import { bindActionCreators } from "redux";

const Dashboard = ({ children, isDownloadPanelOpened, files, getDownloadFilesQueue, fileListErrorMessage }) => {
  useEffect(() => {
    if (!isDownloadPanelOpened) return;
    getDownloadFilesQueue();
  }, [isDownloadPanelOpened, getDownloadFilesQueue]);

  useEffect(() => {
    getDownloadFilesQueue();
  }, [getDownloadFilesQueue]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (files && files.some((file) => file.status === 0 || file.status === 1)) {
        getDownloadFilesQueue();
      }
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [files, isDownloadPanelOpened, getDownloadFilesQueue]);

  return (
    <div>
      <PageWrapper>
        <MainNavigation />
        <div className="page-content">
          {children}
          {((files && files.length > 0) || fileListErrorMessage) && <DownloadPanel files={files} />}
          <Footer />
        </div>
      </PageWrapper>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isDownloadPanelOpened: state.simulation.downloadPanel.isOpen,
  files: state.simulation.files,
  fileListErrorMessage: state.simulation.fileListErrorMessage,
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ setFileDownloadList, setFileListErrorMessage, getDownloadFilesQueue }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
