import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Form, FormControl, FormGroup, Button, Spinner } from "react-bootstrap";
import { CustomLink } from "components/CustomLink";
import ROUTES from "constants/routes";
import mainLogo from "assets/img/logo.png";
import { userLogin, resetResponse } from "actions/userAuthenticationActions";

const Login = ({ user, userLogin, resetResponse }) => {
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  const handleInput = (event) => {
    const { name, value } = event.target;
    setLoginData({
      ...loginData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    //Send data to backend
    userLogin(loginData);
  };

  //Resets previous server response to inital value
  useEffect(() => {
    resetResponse();
  }, [resetResponse]);

  return (
    <>
      <div className="account-body">
        <div className="row vh-100">
          <div className="col-12 align-self-center">
            <div className="auth-page smaller">
              <div className="text-center">
                <img src={mainLogo} alt="Compel logo" />
              </div>
              <div className="text-center auth-logo-text">
                <h4 className="mb-3 mt-5">Please sign in to continue</h4>
              </div>
              <div className="card auth-card shadow-lg">
                <div className="card-body">
                  <div className="px-3">
                    <Form noValidate onSubmit={handleSubmit} className="form-horizontal auth-form my-4">
                      <Form.Group>
                        <Form.Label>Email address</Form.Label>
                        <FormControl type="email" name="email" onChange={handleInput} value={loginData.email} isInvalid={user.response.message}></FormControl>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Password</Form.Label>
                        <FormControl
                          type="password"
                          name="password"
                          onChange={handleInput}
                          value={loginData.password}
                          isInvalid={user.response.message}
                        ></FormControl>
                      </Form.Group>
                      {user.response.message && <p className="text-danger text-center">{user.response.message}</p>}
                      <FormGroup className="mb-0">
                        <div className="">
                          <Button type="submit" className="btn btn-primary  btn-block waves-effect waves-light" disabled={user.authenticationInProgress}>
                            {user.authenticationInProgress ? <Spinner as="div" animation="border" variant="light" /> : "Sign in"}
                          </Button>
                        </div>
                      </FormGroup>
                    </Form>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-sm-12 text-center">
                  <CustomLink href={ROUTES.register} className="text-primary font-13">
                    Don't have an account? Press here to register
                  </CustomLink>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-sm-12 text-center">
                  <CustomLink href={ROUTES.forgotPassword()} className="text-primary font-13">
                    Forgot password?
                  </CustomLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ userLogin, resetResponse }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
