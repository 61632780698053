import React from "react";
import { Helmet } from "react-helmet";
import { Route, Redirect } from "react-router";
import ROUTES from "constants/routes";

const ProtectedRoute = ({ userIsLoggedIn, userIsApproved, component: Component, childComponent: ChildComponent, documentTitle, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location, match }) => {
        if (userIsLoggedIn) {
          switch (userIsApproved) {
            case true:
              return (
                <Component location={location} match={match} documentTitle={documentTitle}>
                  <Helmet title={documentTitle} />
                  {ChildComponent}
                </Component>
              );
            case false:
              if (Component.name === "NotApproved") {
                return <Component />;
              }
              return <Redirect to={ROUTES.notApproved} />;
            default:
              break;
          }
        } else if (!userIsLoggedIn) {
          return <Redirect to={ROUTES.login} />;
        }
      }}
    />
  );
};

export default ProtectedRoute;
