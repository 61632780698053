import React from "react";

const CustomSearchInput = ({ onChange }) => {
  return (
    <div className="search-field">
      <label className="search-field__label">
        Search:
        <input className="search-field__input form-control form-control-sm" onChange={onChange} />
      </label>
    </div>
  );
};

export default CustomSearchInput;
