import React, { useEffect, useRef } from "react";

const CustomCheckboxv2 = ({ label, disabled, name, checked, value, onChange, id, indeterminate }) => {
  const checkboxRef = useRef(null);

  useEffect(() => {
    if (!checkboxRef.current) return;

    checkboxRef.current.indeterminate = indeterminate;
  }, [indeterminate]);

  return (
    <div className="custom-control custom-checkbox">
      <input
        type="checkbox"
        name={name}
        checked={checked}
        value={value}
        onChange={onChange}
        id={id}
        disabled={disabled}
        className="custom-control-input"
        ref={checkboxRef}
      />
      <label className="custom-control-label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

export default CustomCheckboxv2;
