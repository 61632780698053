import React, { useState, useRef } from "react";
import { Row, Col, ProgressBar, Spinner } from "react-bootstrap";
import { FileUploadErrors } from "components/Wizards";
import { DropzoneCreateProject } from "components/Forms";
import { DeleteButton } from "components/Button";
import { QuotaGroupsUploadResultTable } from "components/Tables";
import { uploadQuotaGroups, deleteQuotaGroups } from "apis/projectApi";
import { useScrollToElement } from "utility/hooks";
import { useEffect } from "react";

const UploadQuotaGroups = ({ quotaGroupsData, draftId, handleQuotaGroupsData, changeLeverageabilityValidation, handleReachData }) => {
  const redirectTimeout = 5000;
  const [fileIsUploading, setFileIsUploading] = useState(false);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [fileName, setFileName] = useState("");
  const [fileSize, setFileSize] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleFileUpload = async (file, draftId) => {
    setFileIsUploading(true);
    setShowProgressBar(true);
    let uploadQuotaGroupsResult = await uploadQuotaGroups(file, draftId);
    setFileIsUploading(false);
    if (uploadQuotaGroupsResult.data) {
      switch (uploadQuotaGroupsResult.data.result) {
        case 0:
          handleQuotaGroupsData(uploadQuotaGroupsResult.data.quotaGroups);
          break;
        case 1:
          setErrorMessage("File not uploaded.");
          break;
        case 2:
          setErrorMessage("You are not authorized to upload a file to this draft.");
          break;
        case 3:
          setErrorMessage("The draft you are trying to upload files to does not exist.");
          break;
        case 4:
          setErrorMessage("Undefined error");
          break;
        case 5:
          setErrorMessage("File you are trying to upload is too large. File size cannot exceed 4MB");
          break;
        case 6:
          setErrorMessage("File upload failed.");
          break;
        case 7:
          setErrorMessage("Quota groups file has already been uploaded for this draft.");
          break;
        case 8:
          setErrorMessage(`Quota group number at cell ${uploadQuotaGroupsResult.data.errorCellAddress} is missing.`);
          break;
        case 9:
          setErrorMessage(
            `Quota group number format error at cell ${uploadQuotaGroupsResult.data.errorCellAddress}. The value that triggered the error is: ${uploadQuotaGroupsResult.data.errorValue}. Quota group numbers should be numerics only.`
          );
          break;
        case 10:
          setErrorMessage(
            `Quota group number at cell ${uploadQuotaGroupsResult.data.errorCellAddress} is a duplicate. Duplicate value is: ${uploadQuotaGroupsResult.data.errorValue}. Quota group numbers must be unique`
          );
          break;
        case 11:
          setErrorMessage(`Quota group name at cell ${uploadQuotaGroupsResult.data.errorCellAddress} is missing.`);
          break;
        case 12:
          setErrorMessage(
            `Quota group name at cell ${uploadQuotaGroupsResult.data.errorCellAddress} is a duplicate. Duplicate value is: ${uploadQuotaGroupsResult.data.errorValue}. Quota group names must be unique`
          );
          break;
        case 13:
          setErrorMessage(`Quota group row ${uploadQuotaGroupsResult.data.errorValue} is missing.`);
          break;
        case 14:
          setErrorMessage(
            `Wrong file type. File worksheet name is supposed to be called "Quota Groups Upload". Uploaded worksheet name: "${uploadQuotaGroupsResult.data.errorValue}"`
          );
          break;
        default:
          break;
      }
    }
  };

  const handleFileInfo = (fileName, fileSize) => {
    setFileName(fileName);
    if (fileSize >= 1000000) {
      setFileSize(`${Math.ceil(fileSize / 1000000).toFixed(2)} MB`);
    } else {
      setFileSize(`${Math.ceil(fileSize / 1000)} KB`);
    }
  };

  const handleErrorMessages = (errorMessage) => {
    if (!errorMessage) {
      setErrorMessage("");
    } else {
      setErrorMessage(errorMessage);
    }
  };

  const handleDeleteQuotaGroups = async () => {
    setIsLoading(true);
    let deleteQuotaGroupsResult = await deleteQuotaGroups(draftId);
    if (deleteQuotaGroupsResult.data) {
      switch (deleteQuotaGroupsResult.data.result) {
        //Draft deleted successfully -> new project is created and user is routed to first step of the wizard
        case 0:
          setShowProgressBar(false);
          handleQuotaGroupsData();
          changeLeverageabilityValidation(false);
          handleReachData();
          setErrorMessage("");
          break;
        case 1:
          setErrorMessage("You are not authorized to delete data in this draft");
          break;
        case 2:
          //TODO: Maybe logout and reroute the user to a special screen saying their account does not exist anymore?
          //TODO: Create a function
          setErrorMessage(`This account has been deleted. You will be redirected to the login screen in ${redirectTimeout} seconds`);
          break;
        case 3:
          setErrorMessage("The draft you are trying to delete data from does not exist");
          break;
        case 4:
          setErrorMessage("Undefined error");
          break;
        default:
          break;
      }
    }
    //TODO: Handle axios error
    setIsLoading(false);
  };

  //If current step data exists -> display progress bar and the delete icon
  useEffect(() => {
    quotaGroupsData.length > 0 ? setShowProgressBar(true) : setShowProgressBar(false);
  }, [quotaGroupsData]);

  const progressBarRef = useRef(null);
  useScrollToElement({ elementRef: progressBarRef, startScroll: quotaGroupsData.length > 0, offset: 100 });

  return (
    <div className="pt-4">
      <div className="pt-5">
        {quotaGroupsData.length < 1 && !showProgressBar && (
          <DropzoneCreateProject
            text="quota groups"
            handleFileUpload={handleFileUpload}
            handleFileInfo={handleFileInfo}
            draftId={draftId}
            handleErrorMessages={handleErrorMessages}
            setShowProgressBar={setShowProgressBar}
          />
        )}
        {(quotaGroupsData.length > 0 || showProgressBar) && (
          <div className="pt-5">
            <Row>
              <Col sm={11}>
                <div ref={progressBarRef}>
                  <ProgressBar now={fileIsUploading ? 65 : 100} label={fileIsUploading ? "Uploading" : "Upload complete"} />
                </div>
              </Col>
              <Col sm={1} className="d-flex align-items-center">
                {isLoading ? <Spinner animation="border" size="sm" variant="danger" /> : <DeleteButton onClick={handleDeleteQuotaGroups} className="font-18" />}
              </Col>
            </Row>
            {showProgressBar && fileName !== "" && (
              <Row className="pt-2 pb-5">
                <Col sm={11} className="d-flex justify-content-end">
                  <p>{`${fileName} - ${fileSize} total`}</p>
                </Col>
              </Row>
            )}
          </div>
        )}
        {fileIsUploading && (
          <div className="d-flex justify-content-center">
            <Spinner size="lg" animation="border" variant="primary" />
          </div>
        )}
        {quotaGroupsData.length > 0 && (
          <QuotaGroupsUploadResultTable draftId={draftId} quotaGroups={quotaGroupsData} handleQuotaGroupsData={handleQuotaGroupsData} />
        )}
        {errorMessage.length > 0 && <FileUploadErrors errorMessage={errorMessage} />}
      </div>
    </div>
  );
};

export default UploadQuotaGroups;
