// Don't write Redux actions as "const ACTION_NAME = 'ACTION_NAME'"

// Group actions under a common group, such as user, project etc.
// These names will appear in Redux Dev Tools and might allow you to better reason about what's going on
// Name groups of actions with "type" prefix, such as typeUser, typeProject etc.

export const typeUser = {
  loginStart: "user/loginStart",
  loginEnd: "user/loginEnd",
  getUserApprovalStatus: "user/getUserApprovalStatus",
  resetResponse: "user/resetResponse",
  currentData: "user/currentData",
};

export const typeUserRegistration = {
  selectUserType: "userRegistration/selectUserType",
  registerUserStart: "userRegistration/registerUserStart",
  registerUserEnd: "userRegistration/registerUserEnd",
  resetResponse: "userRegistration/resetResponse",
};

export const typeAdmin = {
  getUsersStart: "admin/getUsersStart",
  getUsersEnd: "admin/getUsersEnd",
  getUsersError: "admin/getUsersError",
  changeUserApprovalStart: "admin/changeUserApprovalStart",
  changeUserApprovalEnd: "admin/changeUserApprovalEnd",
  changeUserApprovalError: "admin/changeUserApprovalError",
  addUserStart: "admin/addUserStart",
  addUserEnd: "admin/addUserEnd",
  addUserError: "admin/addUserError",
  editUserStart: "admin/editUserStart",
  editUserEnd: "admin/editUserEnd",
  editUserError: "admin/editUserError",
  resetResponse: "admin/resetResponse",
};

export const typeSimulation = {
  openDownloadPanel: "simulation/openDownloadPanel",
  closeDownloadPanel: "simulation/closeDownloadPanel",
  setDownloadFileList: "simulation/setDownloadFileList",
  setFileListErrorMessage: "simulation/setFileListErrorMessage",
};
