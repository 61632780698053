/**
 * Convert Array of some values to a string list separated by commas (eg. One, Two, Three). If an empty array is provided it returns an empty string ""
 * @param {Object} param0
 * @param {Array} param0.array - Array to convert
 * @param {string} param0.key - If `key` is supplied the function will first extract that key from each item in array and then make a list out of that
 */

const hashString = (hashBase) => {
  let hash = 0;

  for (let i = 0; i < hashBase.length; i++) {
    const chr = hashBase.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }

  return hash;
};

export { hashString };
