import { useCallback, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";

const useQueryParams = () => {
  const location = useLocation();
  const history = useHistory();

  const [queryParams] = useState(new URLSearchParams(location.search));

  const setQueryParam = useCallback(
    ({ paramName, paramValue }) => {
      queryParams.set(paramName, paramValue);

      history.replace({
        pathname: location.pathname,
        search: queryParams.toString(),
      });
    },
    [queryParams, history, location.pathname]
  );

  const removeQueryParams = useCallback(
    ({ paramNames }) => {
      paramNames.forEach((param) => {
        queryParams.delete(param);
      });

      history.replace({
        pathname: location.pathname,
        search: queryParams.toString(),
      });
    },
    [queryParams, history, location.pathname]
  );

  const resetAllParams = useCallback(() => {
    const paramNames = [];
    for (let param of queryParams.entries()) {
      paramNames.push(param[0]);
    }
    paramNames.forEach((param) => {
      queryParams.delete(param);
    });

    history.replace({
      pathname: location.pathname,
      search: new URLSearchParams("").toString(),
    });
  }, [queryParams, history, location.pathname]);

  return { queryParams, setQueryParam, removeQueryParams, resetAllParams };
};

export default useQueryParams;
