import userReducer from "./userAuthenticationReducer";
import userRegistrationReducer from "./userRegistrationReducer";
import adminReducer from "./adminReducer";
import simulationReducer from "./simulationReducer";

// Don't store all state in redux store, global state should live here.
// Local state can safely live in components with the use of hooks such as useState or useReducer

export const reducers = {
  user: userReducer,
  userRegistration: userRegistrationReducer,
  admin: adminReducer,
  simulation: simulationReducer,
};
