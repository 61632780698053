export function getFileNameFromContentDisposition(contentDisposition) {
  if (!contentDisposition) return null;
  let fileName = contentDisposition.split(";")[2].split("filename*=UTF-8''")[1];
  // const match = contentDisposition.match(/filename="?([^"]+)"?/);
  // return match ? match[1] : null;
  return fileName.replace("%20", " ");
}

export const createAnchorAndDownloadFile = ({ response, data }) => {
  const url = window.URL.createObjectURL(
    new Blob([data], {
      type: response.headers["content-type"],
    })
  );

  const actualFileName = getFileNameFromContentDisposition(response.headers["content-disposition"]);

  // uses the download attribute on a temporary anchor to trigger the browser download behavior.
  const link = document.createElement("a");
  link.href = url;
  link.download = actualFileName;
  link.click();
};
