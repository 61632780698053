import React, { useState } from "react";
import { Table } from "react-bootstrap";
import { CustomButton } from "components/Button";
import ROUTES from "constants/routes";
import { formatDateMDY } from "utility/date";
import { Link } from "react-router-dom";
import { ConfirmationModal } from "components/Modals";
import { changeRoute } from "utility/routing";
import { Avatar } from "components/Avatar";
import { TableHeaderCell } from "components/Tables";
import classNames from "classnames";
import { connect } from "react-redux";

const SimulationTableRow = ({ simulation, projectId, projectStatus, handleDeleteSimulation, onModalOpen, onModalClose, user }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteSuccessful, setIsDeleteSuccessful] = useState(false);

  const handleModalClose = (getSimulations) => {
    onModalClose(setShowModal, setErrorMessage, getSimulations);
  };
  const userCanDeleteSimulation =
    user && user.currentUser && (user.currentUser.userType === 0 || user.currentUser.userType === 1 || user.currentUser.id === simulation.userId);

  return (
    <tr key={simulation.id}>
      <td>
        <Link to={{ pathname: ROUTES.simulation(simulation.id.toString()), state: { projectId: projectId, simulationId: simulation.id } }}>
          <Avatar name={simulation.name}></Avatar>
          {simulation.name}
        </Link>
      </td>
      <td>{simulation.owner}</td>
      <td>{formatDateMDY(simulation.createdAt)}</td>
      <td>{simulation.simulationMessageGroup} message run</td>
      <td className="text-right">
        <CustomButton
          className="btn btn-secondary mt-0 "
          onClick={() => changeRoute(ROUTES.simulation(simulation.id.toString()), { projectId: projectId, simulationId: simulation.id })}
        >
          <i className="fas fa-edit mr-2"></i>
          View
        </CustomButton>
        {userCanDeleteSimulation && projectStatus !== 100 && (
          <CustomButton className="btn btn-danger mt-0 ml-2 " onClick={() => onModalOpen(setShowModal)}>
            <i className="fas fa-trash-alt mr-2"></i>
            Delete
          </CustomButton>
        )}
      </td>
      <ConfirmationModal
        showModal={showModal}
        isLoading={isLoading}
        errorMessage={errorMessage}
        onCancel={handleModalClose}
        onConfirm={() => handleDeleteSimulation(simulation.id, setIsLoading, setIsDeleteSuccessful, setErrorMessage)}
        success={isDeleteSuccessful}
        confirmationMessage="Yes, delete it!"
        questionMessage="Are you sure?"
        warningMessage="You won't be able to revert this!"
        errorTitle="Error"
        successTitle="Deleted!"
        successSubtitle="Simulation successfully deleted"
      />
    </tr>
  );
};
const ProjectSimulationsTable = ({
  data,
  projectId,
  projectStatus,
  onModalOpen,
  onModalClose,
  handleDeleteSimulation,
  activeColumn,
  orderIsDescending,
  handleSelectSortingOrder,
  tableIsLoading,
  user,
}) => {
  const headers = ["Name", "Owner", "Created", "Messages"];
  const tableClassesString = classNames("mt-4", tableIsLoading ? "opacity-65" : "");
  return (
    <div className="dash-social">
      <Table responsive className={tableClassesString}>
        <thead className="thead-light">
          <tr>
            {headers.map((header, index) => {
              //Last column
              return (
                <TableHeaderCell
                  key={header}
                  title={header}
                  currentColumn={index}
                  activeColumn={activeColumn}
                  orderIsDescending={orderIsDescending}
                  handleSelectSortingOrder={handleSelectSortingOrder}
                />
              );
            })}
            <th></th>
          </tr>
        </thead>

        <tbody>
          {data.map((simulation) => (
            <SimulationTableRow
              key={simulation.id}
              simulation={simulation}
              onModalOpen={onModalOpen}
              onModalClose={onModalClose}
              handleDeleteSimulation={handleDeleteSimulation}
              projectId={projectId}
              projectStatus={projectStatus}
              user={user}
            />
          ))}
        </tbody>
      </Table>
    </div>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps)(ProjectSimulationsTable);
