import React from "react";
import { Modal, Table } from "react-bootstrap";
import { CustomCheckbox } from "components/Forms";
import { ErrorText } from "components/Text";

const IncludeSimulationMessagesModal = ({
  showIncludeMessagesModal,
  setShowIncludeMessagesModal,
  modalMessages,
  includedMessageIds,
  excludedMessageIds,
  messageGroup,
  messagesOperator,
  handleIncludeMessage,
  messageLimitReached,
}) => {
  return (
    <Modal size="xl" show={showIncludeMessagesModal} onHide={() => setShowIncludeMessagesModal(false)} centered>
      <Modal.Body>
        <div className="d-flex flex-column align-items-center p-3">
          <h3 className="mb-4">Messages</h3>
          {/* {includedMessageIds && "Included messages: " + includedMessageIds.toString()}
            <br></br>
            {excludedMessageIds && "Excluded messages: " + excludedMessageIds.toString()} */}
          <p>Please mark the messages you want to include in your simulation by pressing the checkbox on the right of each message.</p>
        </div>
        <Table responsive>
          <colgroup>
            <col className="table__column--width-33" />
            <col className="table__column--width-33" />
            <col className="table__column--width-33" />
          </colgroup>
          <thead>
            <tr>
              <th>#</th>
              <th>Message</th>
              <th>
                {/* //TODO: Currently not including "Mark all" as it does not fit the current modal logic */}
                Include? {/* <small className="cursor-pointer" onClick={handleSelectAllMessages}>
                  Mark all
                </small>{" "} */}
              </th>
            </tr>
          </thead>
          <tbody>
            {modalMessages.map((message, index) => {
              const isDisabled = excludedMessageIds.includes(message.messageId) || (includedMessageIds.length >= messageGroup && !message.isChecked);

              return (
                <tr key={message.messageId}>
                  <td>{index + 1}</td>
                  <td>{message.text}</td>
                  <td className="d-flex align-items-center">
                    <CustomCheckbox
                      key={message.MessageId + index}
                      checked={isDisabled ? false : message.isChecked}
                      value={message.messageId}
                      onChange={handleIncludeMessage}
                      id={message.messageId}
                      classNameInput="custom-control-input"
                      classNameLabel="custom-control-label mb-4"
                      disabled={isDisabled}
                    />
                    {isDisabled && excludedMessageIds.includes(message.messageId) && <ErrorText className="mb-1 pb-0 ml-2" text="Message is excluded" />}
                    {isDisabled && messageLimitReached && !excludedMessageIds.includes(message.messageId) && (
                      <ErrorText
                        className="mb-1 pb-0 ml-2"
                        text={`Message limit reached. You may select a maximum of ${messageGroup} messages. To select more messages, change "# of Messages" dropdown value`}
                      />
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
};

export default IncludeSimulationMessagesModal;
