import { typeAdmin } from "constants/actionTypes";
import { getUsers, changeUserApprovalStatus, addUser, editUser } from "apis/adminApi";

export const adminGetUsersList = (page, pageSize) => {
  return async (dispatch) => {
    dispatch({
      type: typeAdmin.getUsersStart,
      payload: {
        loading: true,
      },
    });

    let errorMessage = "";

    const getUsersResult = await getUsers(page, pageSize);
    if (getUsersResult.data) {
      switch (getUsersResult.data.result) {
        case 0:
          dispatch({
            type: typeAdmin.getUsersEnd,
            payload: {
              users: getUsersResult.data.users,
              count: getUsersResult.data.count,
              page: page,
              pageSize: pageSize,
            },
          });
          return;
        case 1:
          errorMessage = "Undefined error";
          break;
        case 9:
          errorMessage = "You are not authorized to perform this action";
          break;
        default:
          break;
      }
      dispatch({
        type: typeAdmin.getUsersEnd,
        payload: {
          response: {
            result: getUsersResult.data,
            errorMessage,
          },
        },
      });
    }
    //This handles axios error
    else if (getUsersResult) {
      errorMessage = getUsersResult.error;
    } else {
      errorMessage = "Undefined error";
    }
    dispatch({
      type: typeAdmin.getUsersError,
      payload: {
        response: {
          result: 99,
          errorMessage,
        },
      },
    });
  };
};

export const changeUserApproval = (email) => {
  return async (dispatch) => {
    dispatch({
      type: typeAdmin.changeUserApprovalStart,
    });

    let errorMessage = "";

    const changeUserApprovalResult = await changeUserApprovalStatus(email);

    if (changeUserApprovalResult.data) {
      switch (changeUserApprovalResult.data.result) {
        case 0:
          dispatch({
            type: typeAdmin.changeUserApprovalEnd,
          });
          return;
        case 1:
          break;
        case 2:
          errorMessage = "User does not exist";
          break;
        case 9:
          errorMessage = "You are not authorized to perform this action";
          break;
        default:
          break;
      }
      dispatch({
        type: typeAdmin.changeUserApprovalError,
        payload: {
          response: {
            result: changeUserApprovalResult.data.result,
            errorMessage,
          },
        },
      });
      //This handles axios error
    } else if (changeUserApprovalResult) {
      errorMessage = changeUserApprovalResult.error;
    } else {
      errorMessage = "Undefined error";
    }
    dispatch({
      type: typeAdmin.changeUserApprovalError,
      payload: {
        response: {
          result: 99,
          errorMessage,
        },
      },
    });
  };
};

export const adminAddUser = (userData) => {
  return async (dispatch) => {
    dispatch({
      type: typeAdmin.addUserStart,
    });

    let errorMessage = "";

    const addUserResult = await addUser(userData);

    if (addUserResult.data) {
      switch (addUserResult.data.result) {
        case 0:
          dispatch({
            type: typeAdmin.addUserEnd,
            payload: {
              response: {
                result: addUserResult.data.result,
              },
            },
          });
          return;
        case 1:
          errorMessage = "Undefined error";
          break;
        case 3:
          errorMessage = "Password is too short";
          break;
        case 4:
          errorMessage = `User with the email ${userData.email} already exists`;
          break;
        case 6:
          errorMessage = `All fields are required ${addUserResult.data.requiredFields}`;
          break;
        case 7:
          errorMessage = "User type is not allowed";
          break;
        case 8:
          errorMessage = "Invalid email";
          break;
        case 9:
          errorMessage = "You are not authorized to perform this action";
          break;
        default:
          break;
      }
      dispatch({
        type: typeAdmin.addUserError,
        payload: {
          response: {
            result: addUserResult.data.result,
            errorMessage,
          },
        },
      });
      return;
      //This handles axios error
    } else if (addUserResult) {
      errorMessage = addUserResult.error;
    } else {
      errorMessage = "Undefined error";
    }
    dispatch({
      type: typeAdmin.addUserError,
      payload: {
        response: {
          result: 99,
          errorMessage,
        },
      },
    });
  };
};

export const adminEditUser = (userData) => {
  return async (dispatch) => {
    dispatch({
      type: typeAdmin.resetResponse,
    });
    dispatch({
      type: typeAdmin.editUserStart,
    });

    let errorMessage = "";

    const editUserResult = await editUser(userData);

    if (editUserResult.data) {
      switch (editUserResult.data.result) {
        case 0:
          dispatch({
            type: typeAdmin.editUserEnd,
            payload: {
              response: {
                result: editUserResult.data.result,
              },
            },
          });
          return;
        case 1:
          errorMessage = "Undefined error";
          break;
        case 4:
          errorMessage = `User with the email ${userData.newEmail} already exists`;
          break;
        case 6:
          errorMessage = `All fields are required: ${editUserResult.data.requiredFields}`;
          break;
        case 7:
          errorMessage = "Not allowed to change the status of a client user.";
          break;
        case 9:
          errorMessage = "You are not authorized to perform this action";
          break;
        case 10:
          errorMessage = "User does not exist";
          break;
        default:
          break;
      }
      dispatch({
        type: typeAdmin.editUserError,
        payload: {
          response: {
            result: editUserResult.data.result,
            errorMessage,
          },
        },
      });
      return;
      //This handles axios error
    } else if (editUserResult) {
      errorMessage = editUserResult.error;
    } else {
      errorMessage = "Undefined error";
    }
    dispatch({
      type: typeAdmin.editUserError,
      payload: {
        response: {
          result: 99,
          errorMessage,
        },
      },
    });
  };
};

export const adminResetResponse = () => {
  return async (dispatch) => {
    dispatch({
      type: typeAdmin.resetResponse,
    });
  };
};
