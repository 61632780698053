import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const CustomSelect = ({ isDisabled, defaultValue, value, options, styles, animated, isMulti, closeMenuOnSelect, hideSelectedOptions, onChange }) => {
  const animatedComponents = makeAnimated();
  return (
    <Select
      isDisabled={isDisabled}
      value={value}
      defaultValue={defaultValue}
      options={options}
      styles={styles}
      components={animated && animatedComponents}
      isMulti={isMulti}
      closeMenuOnSelect={closeMenuOnSelect}
      hideSelectedOptions={hideSelectedOptions}
      onChange={onChange}
    />
  );
};

export default CustomSelect;
