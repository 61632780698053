import React from "react";
import { Table } from "react-bootstrap";

const MessagesUploadResultTable = ({ dataRows }) => {
  return (
    <Table responsive>
      <thead>
        <tr>
          <th>ID</th>
          <th>Message</th>
          <th>Category</th>
        </tr>
      </thead>
      <tbody>
        {dataRows.map((dataRow, index) => (
          <tr key={index}>
            <td>{dataRow.messageNumber}</td>
            <td>{dataRow.text}</td>
            <td>{dataRow.category}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default MessagesUploadResultTable;
