import React from "react";
import classNames from "classnames";

import { hashString } from "utility/text";

const Avatar = ({ className, src, name = "", alt, size = "md" }) => {
  const classesString = classNames("avatar-box", `thumb-${size}`, "align-self-center", "mr-2", className);
  const altSource = alt || "Image of " + name;
  const initalsStyles = ["orange", "red", "#3895d3", "#50c878"];

  const renderAvatar = () => {
    if (src) {
      return <img className="avatar__image" src={src} alt={altSource} />;
    } else {
      const initials = name.slice(0, 2);

      const hashedString = Math.abs(hashString(name));
      const style = initalsStyles[hashedString % initalsStyles.length];
      const imageClass = classNames("avatar__initials");

      return (
        <div className={imageClass} style={{ background: style }}>
          <span className="avatar-title rounded-circle">{initials}</span>
        </div>
      );
    }
  };

  return <div className={classesString}>{renderAvatar()}</div>;
};

const AvatarText = (props) => {
  const { children, offset } = props;
  const classesString = classNames("avatar__text", offset ? `avatar__text--offset-${offset}` : false);

  return <div className={classesString}>{children}</div>;
};

Avatar.Text = AvatarText;

export default Avatar;
