import axios from "axios";
import { authCookieName } from "constants/general";
import { getCookie } from "utility/cookie";

const getBaseUrl = () => {
  return axios.create({
    baseURL: window.location.origin + "/api/users",
    headers: { Authorization: "Bearer " + getCookie(authCookieName) },
  });
};

export const register = (registrationData) => {
  return getBaseUrl()
    .post("/register", registrationData)
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};

export const login = (loginData) => {
  return getBaseUrl()
    .post("/login", loginData)
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};

export const getUserApprovalStatus = () => {
  return getBaseUrl()
    .get("/getUserApprovalStatus", { params: {} })
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};

//Triggers sending of email from backend to the user
export const forgotPassword = (email) => {
  //Very weird
  if (email === undefined) {
    email = "";
  }
  const headers = {
    "Content-Type": "application/json",
  };
  return getBaseUrl()
    .post("/forgotPassword", `"${email}"`, { headers })
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};

export const changePassword = (changePasswordData) => {
  return getBaseUrl()
    .post("/changePassword", changePasswordData)
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};

export const getCurrentUser = () => {
  return getBaseUrl()
    .get("/GetCurrentUser")
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};
