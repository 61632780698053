import React, { useReducer, useCallback, useMemo, useEffect, useRef } from "react";
import classNames from "classnames";
import { useComponentContext } from "utility/hooks";
import { Row, Col, Card } from "react-bootstrap";
import { scrollToElement } from "utility/scrollToElement";

const WizardContext = React.createContext();

const initialState = ({ activeStep }) => {
  return {
    activeStepIndex: activeStep,
    maxActiveIndex: activeStep,
    numberOfSteps: 0,
  };
};

const wizardReducer = (state, action) => {
  switch (action.type) {
    case "stepChanged":
      const newStepIndex = action.payload.selectedStep;
      const newMaxIndex = newStepIndex > state.maxActiveIndex ? newStepIndex : state.maxActiveIndex;
      const newState = {
        ...state,
        activeStepIndex: newStepIndex,
        maxActiveIndex: newMaxIndex,
      };
      return newState;

    default:
      break;
  }
};

const Wizard = ({ children, activeStep, numbered, onStepChange, handleSelectStep, altMode, numberOfSteps }) => {
  const [state, dispatch] = useReducer(wizardReducer, initialState({ activeStep }));
  const wizardRef = useRef(null);
  const handleActiveStepShowing = useCallback(
    (selectedStep) => {
      if (state.activeStepIndex === selectedStep) return;

      handleSelectStep(selectedStep);
      dispatch({ type: "stepChanged", payload: { selectedStep: selectedStep } });
      if (onStepChange) {
        onStepChange(selectedStep);
      }
    },
    [onStepChange, state.activeStepIndex, handleSelectStep]
  );

  useEffect(() => {
    if (activeStep > numberOfSteps) return;
    if (activeStep < 1) return;

    dispatch({ type: "stepChanged", payload: { selectedStep: activeStep } });
  }, [activeStep, numberOfSteps]);

  useEffect(() => {
    if (onStepChange) {
      onStepChange(state.activeStepIndex);
    }
  }, [state.activeStepIndex, onStepChange]);

  useEffect(() => {
    scrollToElement({ element: wizardRef.current, duration: 0 });
  }, [activeStep]);

  useEffect(() => {
    dispatch({ type: "stepChanged", payload: { selectedStep: activeStep } });
  }, [activeStep]);

  const providerValue = useMemo(
    () => ({
      handleActiveStepShowing,
      activeStepIndex: state.activeStepIndex,
      maxActiveIndex: state.maxActiveIndex,
      dispatch,
      numbered,
      numberOfSteps,
      altMode,
    }),
    [handleActiveStepShowing, numbered, state.activeStepIndex, state.maxActiveIndex, numberOfSteps, altMode]
  );
  return (
    <WizardContext.Provider value={providerValue}>
      <Row>
        <Col sm={12}>
          <Card>
            <Card.Body>
              <div className="wizard" ref={wizardRef}>
                <h2 className="text-center font-weight-bold pb-5">CREATE A NEW PROJECT</h2>
                {children}
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </WizardContext.Provider>
  );
};

const WizardNav = (props) => {
  const { children } = props;

  const filteredChildren = children.filter((x) => x !== null);

  const { activeStepIndex, maxActiveIndex, altMode } = useComponentContext({
    contextInstance: WizardContext,
    componentName: "Wizard",
  });

  return (
    <div className="wizard-header">
      <div className="line"></div>
      {React.Children.map(filteredChildren, (child, index) => {
        return React.cloneElement(child, {
          index: index,
          isActive: activeStepIndex === index + 1,
          isDisabled: child.props.isDisabled || (!altMode && maxActiveIndex < index + 1 && index + 1 > activeStepIndex),
        });
      })}
    </div>
  );
};

Wizard.Nav = WizardNav;

const WizardNavItem = ({ title, subtitle, className, isActive, isFinished, isInvalid, isDisabled, index, stepNumber, stepTitle, ...otherProps }) => {
  const { handleActiveStepShowing } = useComponentContext({ contextInstance: WizardContext, componentName: "Wizard" });
  const navItemClassesString = classNames(
    "header-item",
    isActive ? "active" : false,
    isFinished ? "completed" : false,
    isDisabled ? "wizard__nav-item--disabled" : false,
    className
  );
  const handleNavStepClick = () => {
    handleActiveStepShowing(index + 1);
  };

  return (
    <div className={navItemClassesString}>
      <button className="circle" onClick={handleNavStepClick} disabled={!isFinished}></button>
      <div className={`label ${isFinished ? "cursor-pointer" : ""}`} onClick={isFinished ? handleNavStepClick : undefined}>
        <span className="step-number">{title || `Step ${index + 1}`}</span>
        <span className={isFinished ? "cursor-pointer" : ""}>{subtitle}</span>
      </div>
    </div>
  );
};

Wizard.NavItem = WizardNavItem;

const WizardData = (props) => {
  const { children } = props;
  const filteredChildren = children.filter((x) => x !== null);
  const { activeStepIndex } = useComponentContext({ contextInstance: WizardContext, componentName: "Wizard" });

  return (
    <div className="wizard-content">
      {React.Children.map(filteredChildren, (child, index) => {
        return React.cloneElement(child, {
          index: index,
          className: classNames("step", activeStepIndex === index + 1 ? "active" : null),
        });
      })}
    </div>
  );
};

Wizard.Data = WizardData;

const WizardStepContent = (props) => {
  const { children, className } = props;

  return (
    <div className={className}>
      <div className="pt-4">{children}</div>
    </div>
  );
};

Wizard.StepContent = WizardStepContent;

const WizardFooter = ({ activeStep, handleGoToNextStep, handleGoToPrevStep, prevButtonDisabled, nextButtonDisabled }) => {
  const { numberOfSteps } = useComponentContext({ contextInstance: WizardContext, componentName: "Wizard" });

  return (
    <div className="wizard-footer">
      {activeStep !== 1 && (
        <button className="btn btn-outline-primary wizard-footer__left-button" onClick={handleGoToPrevStep} disabled={prevButtonDisabled}>
          Previous
        </button>
      )}
      <button className="btn btn-primary" onClick={handleGoToNextStep} disabled={nextButtonDisabled}>
        {activeStep !== numberOfSteps ? "Next" : "Finish"}
      </button>
    </div>
  );
};

Wizard.Footer = WizardFooter;

export default Wizard;
