import React from "react";
import { Button } from "react-bootstrap";
import { ErrorText } from "components/Text";
import { changeRoute } from "utility/routing";
import ROUTES from "constants/routes";

const ProjectCreated = ({ projectCreated, errorText, projectId, showReviewAndCompleteScreen }) => {
  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      <h3 className={errorText ? "text-danger" : ""}>{!errorText ? "Project created" : "Project creation failed"}</h3>

      {projectCreated && (
        <>
          <p className="w-50 text-center mt-4">Your Project has been successfully created and has been queued for dataset generation.</p>
          {/* <p>Generation of the dataset will take approximately xxxxx minutes/seconds/hours</p> */}
          <p>As soon as the dataset has been generated, the project will appear in the list of projects on the Projects screen.</p>
          <p>Press the button below to go to the Projects screen.</p>
          <Button className="mt-3" onClick={() => changeRoute(ROUTES.projects)}>
            Go To The Projects Screen
          </Button>
        </>
      )}
      {errorText && (
        <>
          {errorText && <ErrorText text={errorText} />}
          <p>Press the button below to go to the Previous screen.</p>
          <Button className="mt-3" onClick={() => showReviewAndCompleteScreen(false)}>
            Go To The Previous Step
          </Button>
        </>
      )}
    </div>
  );
};

export default ProjectCreated;
