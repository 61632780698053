import { useState } from "react";

const useWizard = (initialStep = 1) => {
  const [activeWizardStep, setActiveWizardStep] = useState(initialStep);
  const goToNextStep = () => {
    setActiveWizardStep((currentStep) => currentStep + 1);
  };
  const goToPrevStep = () => {
    setActiveWizardStep((currentStep) => currentStep - 1);
  };

  const handleSelectStep = (selectedStep) => {
    setActiveWizardStep(selectedStep);
  };

  return { activeWizardStep, goToNextStep, goToPrevStep, handleSelectStep };
};

export default useWizard;
