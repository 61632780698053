import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { CustomLink } from "components/CustomLink";
import { Form, FormGroup, Button, FormControl, Spinner } from "react-bootstrap";
import { validEmailRegex } from "utility/validation";
import ROUTES from "constants/routes";
import { userForgotPassword, resetResponse } from "actions/userAuthenticationActions";
import mainLogo from "assets/img/logo.png";
const ForgotPassword = ({ user, userForgotPassword, resetResponse }) => {
  const [firstValidation, setFirstValidation] = useState(false);
  const [emailIsValid, setEmailIsValid] = useState(true);
  const [email, setEmail] = useState("");

  const handleInput = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    //Show validation visual effects
    setFirstValidation(true);
    //Send request to backend
    //TODO: Get response and display a message to the user if the response was successful
    if (emailIsValid) {
      userForgotPassword(email);
    }
  };

  //Resets previous server response to inital value
  useEffect(() => {
    resetResponse();
  }, [resetResponse]);

  useEffect(() => {
    const validateEmail = () => {
      if (!validEmailRegex.test(email)) {
        setEmailIsValid(false);
      } else {
        setEmailIsValid(true);
      }
    };
    validateEmail();
  }, [email]);

  return (
    <>
      <div className="account-body">
        <div className="row vh-100">
          <div className="col-12 align-self-center">
            <div className="auth-page">
              <div className="text-center">
                <img src={mainLogo} alt="Compel logo" />
              </div>
              <div className="text-center auth-logo-text">
                <h4 className="mb-3 mt-5">Password reset</h4>
              </div>
              <div className="card auth-card shadow-lg">
                <div className="card-body">
                  <div className="px-3">
                    <Form noValidate onSubmit={handleSubmit} className="form-horizontal auth-form my-4 text-center">
                      <Form.Group>
                        <Form.Label>Enter your email address</Form.Label>
                        <FormControl
                          type="email"
                          name="email"
                          onChange={handleInput}
                          className={firstValidation && emailIsValid && "is-valid"}
                          value={email}
                          isInvalid={firstValidation && !emailIsValid}
                        ></FormControl>
                        <Form.Control.Feedback type="invalid">
                          {firstValidation && email.length < 1
                            ? "Please enter your email"
                            : firstValidation && !emailIsValid
                            ? "Please enter a valid email address"
                            : ""}
                        </Form.Control.Feedback>
                        {!user.response.result && <p className="text-danger text-center">{user.response.message}</p>}
                        {user.response.result && <p className="text-success text-center">{user.response.message}</p>}
                      </Form.Group>
                      <FormGroup className="mb-0">
                        <div className="col-12 mt-2">
                          <Button type="submit" className="btn btn-primary btn-round btn-block waves-effect waves-light">
                            {user.authenticationInProgress ? <Spinner as="div" animation="border" variant="light" /> : "Send password reset link"}
                          </Button>
                        </div>
                      </FormGroup>
                    </Form>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-sm-12 text-center">
                  <CustomLink href={ROUTES.register} className="text-primary font-13">
                    Don't have an account? Press here to register
                  </CustomLink>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-sm-12 text-center">
                  <CustomLink href={ROUTES.login} className="text-primary font-13">
                    Already have an account? Press here to sign in
                  </CustomLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ userForgotPassword, resetResponse }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
