import React, { useState, useEffect } from "react";
import { Row, Col, FormCheck } from "react-bootstrap";
import { CustomInputField } from "components/Forms";
import { ReviewAndCompleteTable } from "components/Tables";
import { CustomSelect } from "components/Forms";
import { connect } from "react-redux";
import { ErrorText } from "components/Text";

const ReviewAndCompleteStep = ({
  numberOfMessages,
  numberOfQuotaGroups,
  numberOfRespondents,
  projectName,
  projectPassword,
  handlePasswordInput,
  handleProjectNameInput,
  limitProjectAccess,
  handleCheckbox,
  handleSelectStep,
  maxMessageGroup,
  handleSelectMaxMessageSet,
  firstValidation,
  passwordIsValid,
  user,
  resultsSetSize,
  handleResultsSetSizeInput,
  resultsSetSizeErrorMessage,
}) => {
  let selectMessageGroupsOptions = [];
  for (let i = 2; i < 9; i++) {
    selectMessageGroupsOptions.push({
      label: i,
      value: i,
    });
  }
  const binomialFormulaDivider = (n, k) => {
    let result = 1;

    for (var i = 1; i <= k; i++) {
      result *= n - (k - i);
      result /= i;
    }
    return result;
  };

  const [expectedDurationSeconds, setExpectedDurationSeconds] = useState(0);
  const [expectedDurationMinutes, setExpectedDurationMinutes] = useState(0);
  const [expectedDurationHours, setExpectedDurationHours] = useState(0);

  useEffect(() => {
    //I would leave combinations calculation here, Lieberman will definitely want to re-use this at one point and it will save time when they do
    // let combinations = 0;
    let results = 0;
    let linkingResults = 0;
    for (let i = 2; i <= maxMessageGroup; i++) {
      let currentMessageGroupCombinations = binomialFormulaDivider(numberOfMessages, i);
      //I would leave combinations calculation here, Lieberman will definitely want to re-use this at one point and it will save time when they do
      // combinations += currentMessageGroupCombinations;

      let currentMessageGroupResults = currentMessageGroupCombinations * numberOfQuotaGroups;
      results += currentMessageGroupResults;
      linkingResults += currentMessageGroupResults * i;
    }

    // const approxResultsPerMs = 0.0318675966397667;
    const approxResultsPerMs = 0.06;
    // const approxResultsPerMs = 0.0986137053001277;
    let seconds = Math.ceil((approxResultsPerMs * (results + linkingResults)) / 1000);
    let hours = Math.floor(seconds / 3600)
      .toString()
      .padStart(1, "0");
    let minutes = Math.floor((seconds % 3600) / 60)
      .toString()
      .padStart(1, "0");
    seconds = Math.floor(seconds % 60)
      .toString()
      .padStart(1, "0");

    setExpectedDurationSeconds(seconds);
    setExpectedDurationMinutes(minutes);
    setExpectedDurationHours(hours);
  }, [maxMessageGroup, numberOfMessages, numberOfQuotaGroups]);

  const onChange = (option) => {
    handleSelectMaxMessageSet(option.value);
  };

  //react-select styles
  const customSelectSyles = {
    menu: (base) => ({ ...base, zIndex: 999 }),
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? "#00b068" : base.borderColor,
      "&:hover": {
        borderColor: state.isFocused ? "#00b068" : base.borderColor,
      },
      boxShadow: "none",
    }),
  };
  return (
    <div className="mt-5">
      <ReviewAndCompleteTable
        numberOfMessages={numberOfMessages}
        numberOfQuotaGroups={numberOfQuotaGroups}
        numberOfRespondents={numberOfRespondents}
        handleSelectStep={handleSelectStep}
      />

      {user && user.currentUser && user.currentUser.userType === 0 && (
        <>
          <Row className="my-5">
            <Col sm={6}>
              <p>Select maximum number of message groups in the project</p>
              <CustomSelect
                options={selectMessageGroupsOptions}
                onChange={onChange}
                styles={customSelectSyles}
                defaultValue={selectMessageGroupsOptions.length > 0 && selectMessageGroupsOptions[0]}
              />
            </Col>
            <Col sm={6} className="d-flex flex-column justify-content-center align-items-start">
              <p>Dataset will be generated for up to {maxMessageGroup} message groups</p>
              <p>Approximate duration of dataset generation process:</p>

              <p>
                <strong>
                  {expectedDurationHours} hours, {expectedDurationMinutes} minutes, {expectedDurationSeconds} seconds
                </strong>
              </p>
            </Col>
          </Row>

          <Row>
            <Col sm={6}>
              <div>
                <CustomInputField
                  label="Set results set size limit (default is 20000)"
                  name="resultsSetSize"
                  value={resultsSetSize}
                  handleInputChange={handleResultsSetSizeInput}
                />
                <ErrorText text={resultsSetSizeErrorMessage} />
              </div>
            </Col>
          </Row>
        </>
      )}

      <Row>
        <Col sm={6}>
          <CustomInputField
            label="Give a name to this project"
            name="projectName"
            placeholder="Type your name here"
            value={projectName}
            handleInputChange={handleProjectNameInput}
          />
        </Col>
      </Row>

      <Row className="my-3">
        <Col sm={5}>
          <div onClick={handleCheckbox}>
            <FormCheck label="Limit access with password" onChange={handleCheckbox} checked={limitProjectAccess} />
          </div>
        </Col>
      </Row>
      {limitProjectAccess && (
        <div>
          <Row>
            <Col sm={6}>
              <div style={{ display: "none" }}>
                <input type="password" tabIndex="-1" />
              </div>
              <CustomInputField
                label="Password"
                name="projectPassword"
                type="password"
                placeholder="Type your password here"
                value={projectPassword}
                handleInputChange={handlePasswordInput}
                isInvalid={firstValidation && !passwordIsValid ? true : false}
                errorMsg={"Password has to be at least 5 characters long"}
              />
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <p>
                NOTE: All users who want to access this project will need to enter the password first, except for Compel Administrators who can access it
                without entering the password.
              </p>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps, null)(ReviewAndCompleteStep);
