import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { Modal, Button, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { AddNewUserForm } from "components/Forms";
import { validEmailRegex } from "utility/validation";
import { adminAddUser, adminGetUsersList, adminResetResponse } from "actions/adminActions";

const AddUserModal = ({ admin, isAddUserModalActive, setIsAddUserModalActive, adminAddUser, adminGetUsersList, adminResetResponse }) => {
  //TODO: Check with Lieberman if projectTeam stays as input field
  const initialFormData = {
    firstName: "",
    lastName: "",
    email: "",
    repeatEmail: "",
    password: "",
    repeatPassword: "",
    projectTeam: "",
    companyName: "",
    userType: null,
    projects: [],
  };

  //TODO: Check with Lieberman if projectTeam stays as input field
  const initialInputvalidation = {
    projectTeam: false,
    companyName: false,
    firstName: false,
    lastName: false,
    email: false,
    repeatEmail: false,
    password: false,
    repeatPassword: false,
  };

  //If true, form can be submitted to backend.
  const [formIsValid, setFormIsValid] = useState(false);
  //Validation will not be triggered until this is set to true
  const [firstValidation, setFirstValidation] = useState(false);

  const [formData, setFormData] = useState(initialFormData);

  const [inputValidation, setInputValidation] = useState(initialInputvalidation);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    validateField(name, value);

    setFormData((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  //Used for radio buttons
  const handleUserSelect = (value) => {
    setFormData((prevState) => {
      return { ...prevState, userType: value };
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    //Triggers validation of form
    setFirstValidation(true);
    //Submits form to backend
    if (formIsValid) {
      adminAddUser(formData);
    }
  };

  const handleModalClose = () => {
    //Close modal
    setIsAddUserModalActive(false);
    //Reset form data
    setFormData(initialFormData);
    //Reset form validation
    setInputValidation(initialInputvalidation);
    //Reset form validation result
    setFormIsValid(false);
    //Reset form first validation
    setFirstValidation(false);
    //Reset result & error message in admin reducer
    adminResetResponse();
    //Reload the users list
    adminGetUsersList(1, admin.users.pageSize);
  };

  const validateField = (fieldName, fieldValue) => {
    //All input fields should be filled in
    if (fieldValue.length < 1) {
      setInputValidation((prevState) => ({ ...prevState, [fieldName]: false }));
    } else {
      setInputValidation((prevState) => ({ ...prevState, [fieldName]: true }));
    }

    //Special cases
    switch (fieldName) {
      case "email":
        if (!validEmailRegex.test(fieldValue)) {
          setInputValidation((prevState) => ({ ...prevState, [fieldName]: false }));
        } else {
          setInputValidation((prevState) => ({ ...prevState, [fieldName]: true }));
        }
        break;
      case "password":
        if (fieldValue.length < 5) {
          setInputValidation((prevState) => ({ ...prevState, [fieldName]: false }));
        } else {
          setInputValidation((prevState) => ({ ...prevState, [fieldName]: true }));
        }
        break;
      default:
        break;
    }
  };

  //Repeat password comparison & validation
  useEffect(() => {
    if (formData.repeatPassword !== formData.password || formData.repeatPassword.length < 5) {
      setInputValidation((prevState) => ({ ...prevState, repeatPassword: false }));
    } else {
      setInputValidation((prevState) => ({ ...prevState, repeatPassword: true }));
    }
  }, [formData.password, formData.repeatPassword]);

  //Repeat email comparison & validation
  useEffect(() => {
    if (formData.repeatEmail !== formData.email || formData.repeatEmail.length < 1) {
      setInputValidation((prevState) => ({ ...prevState, repeatEmail: false }));
    } else {
      setInputValidation((prevState) => ({ ...prevState, repeatEmail: true }));
    }
  }, [formData.repeatEmail, formData.email]);

  //Checks if any value in validation fields object is false. If it is, form is not considered valid and user can't submit the form.
  useEffect(() => {
    switch (formData.userType) {
      //Employee
      case 0:
      case 1:
        //Next line creates a new object from the input validation object, but without the company name property (employee does not have company name)
        //TODO: Check with Lieberman if projectTeam stays as input field
        const { companyName, projectNumber, ...employeeInputValidation } = inputValidation;
        if (!Object.values(employeeInputValidation).includes(false)) {
          setFormIsValid(true);
        } else {
          setFormIsValid(false);
        }
        break;
      //Client
      case 2:
        //Next line creates a new object from the input validation object, but without the company name property(client does not have project team)
        //TODO: Check with Lieberman if projectTeam stays as input field
        const { projectTeam, ...clientInputValidation } = inputValidation;
        if (!Object.values(clientInputValidation).includes(false)) {
          setFormIsValid(true);
        } else {
          setFormIsValid(false);
        }
        break;
      default:
        break;
    }
  }, [inputValidation, formData.userType]);
  return (
    <Modal size="lg" centered={true} show={isAddUserModalActive} onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add new user</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AddNewUserForm
          formData={formData}
          inputValidation={inputValidation}
          firstValidation={firstValidation}
          handleInputChange={handleInputChange}
          handleUserSelect={handleUserSelect}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={handleModalClose}>
          Close
        </Button>
        {formData.userType !== null && admin.response.result !== 0 && (
          <Button variant="primary" onClick={handleSubmit}>
            {admin.loading ? <Spinner as="span" animation="border" variant="light" /> : "Save"}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

//TODO: Add usertype global here
const mapStateToProps = (state) => ({ admin: state.admin });

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ adminAddUser, adminGetUsersList, adminResetResponse }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUserModal);
