import React from "react";
import { bindActionCreators } from "redux";
import { CustomButton } from "components/Button";
import { Button } from "react-bootstrap";
import { changeUserApproval, adminGetUsersList, adminEditUser } from "actions/adminActions";
import { connect } from "react-redux";

const UserActions = ({ admin, changeUserApproval, user, adminGetUsersList, handleEditUserModalOpen, handleDeleteUser, onStatusChange, adminEditUser }) => {
  const approveUser = async () => {
    await changeUserApproval(user.email);
    adminGetUsersList(admin.users.page, admin.users.pageSize);

    if (onStatusChange) {
      onStatusChange();
    }
  };

  return (
    <td className="d-flex justify-content-center">
      {!user.isApproved && (
        <div>
          <Button className="mr-2" onClick={approveUser}>
            Approve
          </Button>
          <Button onClick={() => handleDeleteUser({ userId: user.id })}>Deny</Button>
        </div>
      )}
      {user.isApproved && (
        <div>
          <CustomButton
            className="btn btn-secondary mt-0 "
            onClick={() => {
              handleEditUserModalOpen(user.id);
            }}
          >
            <i className="fas fa-edit mr-2"></i>
            Edit
          </CustomButton>

          {/* TODO: Add functionality to delete users */}
          {/* <CustomButton className="btn btn-danger mt-0 ml-2 " onClick={() => onModalOpen(setShowModal)}> */}
          <CustomButton className="btn btn-danger mt-0 ml-2" onClick={() => handleDeleteUser({ userId: user.id })}>
            <i className="fas fa-trash-alt mr-2"></i>
            Delete
          </CustomButton>
          {/* <Button variant="link" onClick={handleModalOpen}>
            <i className="fas fa-edit text-info font-16"></i>
          </Button>
          <Button variant="link">
            <i className="fas fa-trash-alt text-danger font-16"></i>
          </Button> */}
        </div>
      )}
    </td>
  );
};

const mapStateToProps = (state) => ({ admin: state.admin });

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ changeUserApproval, adminGetUsersList, adminEditUser }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(UserActions);
