import React, { useState } from "react";
import { useEffect } from "react";

const CustomEntriesDisplay = ({ totalEntries, page, pageSize }) => {
  const [firstEntry, setFirstEntry] = useState(0);
  let upToEntry = page * pageSize;
  if (totalEntries < pageSize) upToEntry = totalEntries;
  if (upToEntry > totalEntries) upToEntry = totalEntries;

  useEffect(() => {
    switch (totalEntries !== null) {
      case totalEntries > 0 && page === 1:
        setFirstEntry(1);
        break;
      case totalEntries > 0:
        setFirstEntry(page * pageSize - pageSize + 1);
        break;
      case totalEntries === 0:
        setFirstEntry(0);
        break;
      default:
        break;
    }
  }, [page, pageSize, totalEntries]);

  if (totalEntries === null) {
    return null;
  }
  return (
    <div className="d-flex align-items-center col-sm-12 col-md-5 mb-0">
      Showing {firstEntry} to {upToEntry} of {totalEntries ? totalEntries : 0} entries
    </div>
  );
};

export default CustomEntriesDisplay;
