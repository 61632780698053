import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { forgotPassword } from "apis/usersApi";
import { Modal, Button } from "react-bootstrap";
import { validEmailRegex } from "utility/validation";
import EditUserForm from "components/Forms/EditUserForm";
import { adminEditUser, adminGetUsersList, adminResetResponse } from "actions/adminActions";

const EditUserModal = ({ admin, user, isEditUserModalActive, setIsEditUserModalActive, adminGetUsersList, adminResetResponse, adminEditUser }) => {
  const [resetPasswordMailIsSent, setResetPasswordMailIsSent] = useState(null);

  const handleResetPassword = async () => {
    setResetPasswordMailIsSent(null);
    const forgotPasswordResult = await forgotPassword(newData.email);
    forgotPasswordResult.data.success ? setResetPasswordMailIsSent(true) : setResetPasswordMailIsSent(false);
  };

  const initialInputValidation = {
    firstName: false,
    lastName: false,
    newEmail: false,
  };

  //Handling separately because isApproved is a boolean so validation would fail if it was == false && was inside the formData object during validation
  const [isApproved, setIsApproved] = useState(user.isApproved);
  //If true, form can be submitted to backend.
  const [formIsValid, setFormIsValid] = useState(false);
  //Visual effects of validation will not be triggered until this is set to true (though form is still being validated behind the scenes)
  const [firstValidation, setFirstValidation] = useState(false);

  let [newData, setNewData] = useState({ ...user, newEmail: user.email });

  const [inputValidation, setInputValidation] = useState(initialInputValidation);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setNewData((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  //Used for user type selection radio buttons
  const handleUserSelect = (value) => {
    setNewData((prevState) => {
      return { ...prevState, userType: value };
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    //Triggers visual effects of form validation
    setFirstValidation(true);

    if (formIsValid) {
      //Add user approval status to form data object
      newData = { ...newData, isApproved };
      //   //Submits form to backend
      await adminEditUser(newData);
    }
  };

  const handleModalClose = () => {
    //Close modal
    setIsEditUserModalActive(false);
    //Reset password reset message
    setResetPasswordMailIsSent(null);
    //Reset form validation
    setInputValidation(initialInputValidation);
    //Reset form validation result
    setFormIsValid(false);
    //Reset form first validation
    setFirstValidation(false);
    //Reset result & error message in admin reducer
    adminResetResponse();
    //Reload the users list
    adminGetUsersList(1, admin.users.pageSize);
  };

  //Validation of individual fields
  useEffect(() => {
    //First name
    if (newData.firstName && newData.firstName.length < 1) {
      setInputValidation((prevState) => ({ ...prevState, firstName: false }));
    } else if (newData.firstName.length >= 1) {
      setInputValidation((prevState) => ({ ...prevState, firstName: true }));
    }

    //Last name
    if (newData.lastName && newData.lastName.length < 1) {
      setInputValidation((prevState) => ({ ...prevState, lastName: false }));
    } else if (newData.lastName.length >= 1) {
      setInputValidation((prevState) => ({ ...prevState, lastName: true }));
    }

    //Email
    if ((newData.newEmail && newData.newEmail.length < 1) || !validEmailRegex.test(newData.newEmail)) {
      setInputValidation((prevState) => ({ ...prevState, newEmail: false }));
    } else if ((firstValidation && newData.newEmail.length > 1) || validEmailRegex.test(newData.newEmail)) {
      setInputValidation((prevState) => ({ ...prevState, newEmail: true }));
    }

    //Special cases
    switch (newData.userType) {
      //Employee / Admin
      case 0:
      case 1:
        //Project team
        if (newData.projectTeam && newData.projectTeam.length < 1) {
          setInputValidation((prevState) => ({ ...prevState, projectTeam: false }));
        } else if (newData.projectTeam && newData.projectTeam.length >= 1) {
          setInputValidation((prevState) => ({ ...prevState, projectTeam: true }));
        }
        break;
      //Client
      case 2:
        //Company name
        if (newData.companyName && newData.companyName.length < 1) {
          setInputValidation((prevState) => ({ ...prevState, companyName: false }));
        } else if (newData.companyName && newData.companyName.length >= 1) {
          setInputValidation((prevState) => ({ ...prevState, companyName: true }));
        }
        //Project number
        if (newData.projectNumber && newData.projectNumber.length < 1) {
          setInputValidation((prevState) => ({ ...prevState, projectNumber: false }));
        } else if (newData.projectNumber && newData.projectNumber.length >= 1) {
          setInputValidation((prevState) => ({ ...prevState, projectNumber: true }));
        }
        break;
      default:
        break;
    }
  }, [newData, firstValidation]);

  //Validation of entire form
  useEffect(() => {
    switch (user.userType) {
      //Employee / Admin
      case 0:
      case 1:
        //TODO: Check with Lieberman if projectTeam stays as input field
        if (!Object.values(inputValidation).includes(false)) {
          setFormIsValid(true);
        } else {
          setFormIsValid(false);
        }
        break;
      //Client
      case 2:
        //TODO: Check with Lieberman if projectTeam stays as input field
        if (!Object.values(inputValidation).includes(false)) {
          setFormIsValid(true);
        } else {
          setFormIsValid(false);
        }
        break;
      default:
        break;
    }
  }, [user.userType, inputValidation]);

  return (
    <Modal size="lg" centered={true} show={isEditUserModalActive} onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit user</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <EditUserForm
          isFormValid={formIsValid}
          currentUserEmail={user.newEmail}
          isUserApproved={user.isApproved}
          newData={newData}
          firstValidation={firstValidation}
          inputValidation={inputValidation}
          handleInputChange={handleInputChange}
          handleUserSelect={handleUserSelect}
          handleApprovalSelect={setIsApproved}
          handleResetPassword={handleResetPassword}
          resetPasswordMailIsSent={resetPasswordMailIsSent}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleModalClose}>
          Close
        </Button>
        {admin.response.result !== 0 && (
          <Button variant="primary" onClick={handleSubmit}>
            Save changes
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

//TODO: Add usertype global here
const mapStateToProps = (state) => ({ admin: state.admin });

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ adminEditUser, adminGetUsersList, adminResetResponse }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUserModal);
