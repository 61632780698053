export const formatDateMDY = (responseDate) => {
  let date = new Date(responseDate);
  let day = date.getUTCDate();
  //UTC month starts from 0
  let month = date.getUTCMonth() + 1;
  let year = date
    .getFullYear()
    .toString()
    .substr(2, 2);
  return `${month}/${day}/${year}`;
};
