import React from "react";

import classNames from "classnames";

const SuccessText = ({ className, text, hasIcons }) => {
  if (hasIcons)
    return (
      <div className="d-flex justify-content-center align-items-center">
        <i className="fas fa-check text-success pr-1"></i>
        <p className="text-success pt-3">{text}</p>
      </div>
    );

  const classesString = classNames("text-success", "text-center", className);

  return <p className={classesString}>{text}</p>;
};

export default SuccessText;
