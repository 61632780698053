import React, { useState } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { CustomInputField } from "components/Forms";
import { requestAccess } from "apis/projectApi";

const ProjectAuthorizationModal = ({ projectId, projectName, isOpen, setIsOpen, onSuccess }) => {
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [processing, setProcessing] = useState(false);

  const handleInputChange = (event) => {
    setErrorMessage("");
    setPassword(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (processing) {
      return;
    }
    setProcessing(true);
    var response = await requestAccess(projectId, password);
    if (response && response.data && response.data.result === 0) {
      setProcessing(false);
      onSuccess();
    } else {
      setErrorMessage("Invalid password");
      setProcessing(false);
    }
  };

  const handleModalClose = () => {
    //Close modal
    setIsOpen(false);
  };

  return (
    <Modal size="md" centered={true} show={isOpen} onHide={handleModalClose}>
      <Modal.Header closeButton>
        <Modal.Title>Restricted access</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>Access to project "{projectName}" is restricted. Please provide valid password to proceed.</Col>
        </Row>
        <Row>
          <Col>
            <CustomInputField
              label="Project password:"
              type="text"
              name="password"
              handleInputChange={handleInputChange}
              value={password}
              isInvalid={errorMessage}
              errorMsg={errorMessage}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleModalClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit} disabled={processing}>
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProjectAuthorizationModal;
