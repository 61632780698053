import React from "react";
import { Form, FormControl, FormGroup } from "react-bootstrap";
import classNames from "classnames";

const CustomInputField = ({ label, type, name, disabled, placeholder, handleInputChange, className, value, isInvalid, errorMsg, formGroupClassName }) => {
  const formGroupClassname = classNames("form-group", formGroupClassName);
  return (
    <FormGroup className={formGroupClassname}>
      <Form.Label className="col-form-label">{label}</Form.Label>
      <FormControl
        type={type}
        name={name}
        disabled={disabled}
        onChange={handleInputChange}
        className={className}
        value={value}
        isInvalid={isInvalid}
        placeholder={placeholder}
      />
      <Form.Control.Feedback type="invalid">{errorMsg}</Form.Control.Feedback>
    </FormGroup>
  );
};

export default CustomInputField;
