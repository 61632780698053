import React, { useState, useEffect, useMemo } from "react";
import { Row, Col, Button, Card, Spinner, Form, ToggleButtonGroup, ToggleButton, Table } from "react-bootstrap";
import { getProjectById } from "apis/projectApi";
import { createSimulation } from "apis/simulationApi";
import { Link } from "react-router-dom";
import { ToggleChartResults } from "components/Tables";
import { CustomSelect } from "components/Forms";
import { ErrorText } from "components/Text";
import { changeRoute } from "utility/routing";
import ROUTES from "constants/routes";
import { Chart } from "components/Chart";
import { CustomLink } from "components/CustomLink";
import { IncludeSimulationMessagesModal } from "components/Modals";
import { useRouteId } from "utility/hooks";

const PreviewMessagesTable = ({ selectedMessages, onClick }) => {
  return (
    <Table>
      <thead>
        <tr>
          <td>Selected messages</td>
          <td>
            <Button onClick={onClick}>Edit</Button>
          </td>
        </tr>
      </thead>
      <tbody>
        {selectedMessages.map((message, index) => {
          if (!message.isChecked) {
            return null;
          }
          return (
            <tr key={message.text + index}>
              <td>{index + 1}</td>
              <td>{message.text}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

const CreateSimulation = () => {
  const projectId = useRouteId();
  useEffect(() => {
    handleGetProject(projectId);
  }, [projectId]);

  const redirectTimeout = 5000;
  const [project, setProject] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showWeightedResults, setShowWeightedResults] = useState(true);
  const [chartData, setChartData] = useState([]);

  //Display logic
  const [showIncludeMessagesSection, setShowIncludeMessagesSection] = useState(false);
  const [showIncludeMessagesModal, setShowIncludeMessagesModal] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [simulationIsLoading, setSimulationIsLoading] = useState(false);

  //Form data
  const [quotaGroups, setQuotaGroups] = useState([]);
  const [isWeighted, setIsWeighted] = useState(true);
  const [messageGroup, setMessageGroup] = useState(null);
  const [simulationName, setSimulationName] = useState("");

  //Form options
  const [quotaGroupSelectOptions, setQuotaGroupSelectOptions] = useState([]);
  const [messageGroupSelectOptions, setMessageGroupSelectOptions] = useState([]);
  const [excludeMessagesSelectOptions, setExcludeMessagesSelectOptions] = useState([]);

  //Messages
  const [modalMessages, setModalMessages] = useState([]);
  const [includeAllMessages, setIncludeAllMessages] = useState(true);
  const [includedMessageIds, setIncludedMessageIds] = useState([]);
  //ON select exclude popuniti ovaj array sa idevima
  const [excludedMessageIds, setExcludedMessageIds] = useState([]);
  //Messages operator option is an enum on the backend -"And" = 0, "Or" = 1.
  const [messagesOperator, setMessagesOperator] = useState("1");
  const [messageLimitReached, setMessageLimitReached] = useState(false);

  const handleIncludeMessage = (event) => {
    const selectedMessageId = parseInt(event.target.value);
    let currentIncludedMessages = [...includedMessageIds];

    if (includedMessageIds.includes(selectedMessageId)) {
      //If message already exists in the array of Ids
      setIncludedMessageIds(currentIncludedMessages.filter((message) => message !== selectedMessageId));
    } else {
      //If message does not exist in the array of Ids
      setIncludedMessageIds((prevState) => [...prevState, selectedMessageId]);
    }

    //Sets checked state for modal message with the same Id as the selected message
    let newModalMessages = modalMessages.map((modalMessage) => {
      if (modalMessage.messageId === selectedMessageId) {
        modalMessage.isChecked = !modalMessage.isChecked;
      }
      return modalMessage;
    });
    setModalMessages(newModalMessages);
  };

  // Number of included messages can't be larger than the number of messages in message group. i.e. if message group is === 4, that means that the user can select a maximum of 4 messages
  useEffect(() => {
    if (includedMessageIds.length >= messageGroup) {
      setMessageLimitReached(true);
    } else {
      setMessageLimitReached(false);
    }
  }, [includedMessageIds, messageGroup]);

  const handleSelectMessagesOperator = (event) => {
    setMessagesOperator(event.target.value);
    setIncludedMessageIds([]);

    //Sets checked state for modal message with the same Id as excluded messages
    let newModalMessages = modalMessages.map((modalMessage) => {
      modalMessage.isChecked = false;
      return modalMessage;
    });
    setModalMessages(newModalMessages);
  };

  const handleExcludeMessage = (excludedMessagesArray) => {
    let newExcludedMessageIds = [];
    let newIncludedMessageIds = [...includedMessageIds];
    //If no value is selected react-select sends null
    if (excludedMessagesArray) {
      excludedMessagesArray.forEach((excludedMessage) => {
        newExcludedMessageIds.push(excludedMessage.value);
        // newIncludedMessageIds = newIncludedMessageIds.filter((includedMessage) => includedMessage !== excludedMessage.value.toString());
        newIncludedMessageIds = newIncludedMessageIds.filter((includedMessage) => includedMessage !== excludedMessage.value);
      });
    }

    //Sets checked state for modal message with the same Id as excluded messages
    let newModalMessages = modalMessages.map((modalMessage) => {
      if (!newIncludedMessageIds.includes(modalMessage.messageId)) {
        modalMessage.isChecked = false;
      }
      return modalMessage;
    });
    setModalMessages(newModalMessages);

    //Sets new state of excluded message Ids
    setExcludedMessageIds(newExcludedMessageIds);
    //Removes any excluded message from the set of included messages
    setIncludedMessageIds(newIncludedMessageIds);
  };

  const handleSelectMessagesToIncludeSection = (option) => {
    if (option) {
      switch (option.value) {
        case true:
          setIncludeAllMessages(true);
          setIncludedMessageIds([]);
          setMessagesOperator("1");
          setShowIncludeMessagesSection(false);
          break;
        case false:
          setIncludeAllMessages(false);
          setIncludedMessageIds([]);
          setShowIncludeMessagesSection(true);
          break;
        default:
          break;
      }
    }
  };

  //react-select styles
  const customSelectSyles = {
    menu: (base) => ({ ...base, zIndex: 999 }),
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? "#00b068" : base.borderColor,
      "&:hover": {
        borderColor: state.isFocused ? "#00b068" : base.borderColor,
      },
      boxShadow: "none",
    }),
  };

  const customSelectQuotaGroupStyle = {
    ...customSelectSyles,
    option: (base) => {
      return quotaGroups.length === quotaGroupSelectOptions.length - 1 ? { ...base, pointerEvents: "none", opacity: "0.65" } : { ...base };
    },
  };
  //Options for the Include messages dropdown
  const messagesToIncludeOptions = [
    { label: "Include All", value: true },
    { label: "Combinations with selected messages only", value: false },
  ];

  //Messages for displaying in modal and in the table
  useEffect(() => {
    if (!project) return;
    const modalMessages = [];
    project.messages.forEach((message) => {
      modalMessages.push({
        messageId: message.messageId,
        text: message.text,
        category: message.category,
        isChecked: false,
      });
    });
    setModalMessages(modalMessages);
  }, [project, includeAllMessages]);

  useEffect(() => {
    if (!project) return;
    const selectQuotaGroups = [];
    const selectMessageGroup = [];

    //Quota groups
    selectQuotaGroups.push({ label: "All Quota Groups", value: [] });

    project.quotaGroups.forEach((quotaGroup) => {
      selectQuotaGroups.push({
        label: quotaGroup.name,
        value: quotaGroup.quotaGroupId,
      });
      selectQuotaGroups[0].value.push(quotaGroup.quotaGroupId);
    });
    setQuotaGroupSelectOptions(selectQuotaGroups);
    //Message group
    for (let messageGroup = 1; messageGroup <= project.maxMessageSet; messageGroup++) {
      selectMessageGroup.push({ label: messageGroup.toString(), value: messageGroup });
    }
    setMessageGroupSelectOptions(selectMessageGroup);

    //Potential excluded messages
    const excludedMessagesSelectOptions = [];
    project.messages.forEach((message, index) => {
      excludedMessagesSelectOptions.push({
        label: index + 1,
        value: message.messageId,
      });
    });
    setExcludeMessagesSelectOptions(excludedMessagesSelectOptions);
  }, [project]);

  //Select quota groups
  const handleSelectQuotaGroups = (values) => {
    let quotaGroupIds = [];
    if (values) {
      values.forEach((quotaGroup) => {
        if (quotaGroup.value.length > 1) {
          quotaGroupIds = quotaGroup.value;
        } else {
          quotaGroupIds.push(quotaGroup.value);
        }
      });
    }
    setQuotaGroups(quotaGroupIds.sort());
  };
  //Select weight
  const handleWeight = (value) => {
    setIsWeighted(value);
  };
  //Select message group
  const handleSelectMessageGroup = (value) => {
    setMessageGroup(value.value);
    setIncludeAllMessages(true);
    setIncludedMessageIds([]);
    setMessagesOperator("1");
    setShowIncludeMessagesSection(false);
  };

  const handleInputChange = (event) => {
    setSimulationName(event.target.value);
  };

  //Used for disabling the submit button
  useEffect(() => {
    if (quotaGroups.length < 1 || messageGroup < 1 || (!includeAllMessages && includedMessageIds.length < 1)) {
      setCanSubmit(false);
    } else {
      setCanSubmit(true);
    }
  }, [quotaGroups, messageGroup, includeAllMessages, includedMessageIds]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSimulationIsLoading(true);
    //Prepare request data
    let requestMessages = [];

    if (includeAllMessages) {
      requestMessages = project.messages.map((message) => {
        if (excludedMessageIds.includes(message.messageId)) {
          return { messageId: parseInt(message.messageId), isIncluded: false, messageOperator: 0 };
        }
        return { messageId: parseInt(message.messageId), isIncluded: true, messageOperator: 0 };
      });
    } else {
      requestMessages = project.messages.map((message) => {
        if (excludedMessageIds.includes(message.messageId)) {
          return { messageId: parseInt(message.messageId), isIncluded: false, messageOperator: 0 };
        }
        if (includedMessageIds.includes(message.messageId)) {
          return { messageId: parseInt(message.messageId), isIncluded: true, messageOperator: parseInt(messagesOperator) };
        }
        return { messageId: parseInt(message.messageId), isIncluded: true, messageOperator: 0 };
      });
    }

    let createSimulationResponse = await createSimulation(
      projectId,
      simulationName,
      quotaGroups,
      isWeighted,
      [messageGroup],
      includeAllMessages,
      requestMessages
    );
    if (createSimulationResponse.data) {
      switch (createSimulationResponse.data.result) {
        case 0:
          changeRoute(ROUTES.simulation(createSimulationResponse.data.simulationId), { projectId, simulationId: createSimulationResponse.data.simulationId });
          break;
        case 1:
          setErrorMessage("Undefined error.");
          break;
        case 2:
          setErrorMessage("Error. Project does not exist.");
          break;
        case 3:
          setErrorMessage("Error. You are not authorized to run simulations in this project.");
          break;
        case 4:
          setErrorMessage(`This account has been deleted. You will be redirected to the login screen in ${redirectTimeout} seconds.`);
          break;
        default:
          break;
      }
    }
    setSimulationIsLoading(false);
  };
  //TODO: Figure out how to handle errors
  const handleGetProject = async (projectId) => {
    let getProjectByIdResult = await getProjectById(projectId);
    if (getProjectByIdResult.data) {
      switch (getProjectByIdResult.data.result) {
        case 0:
          setProject(getProjectByIdResult.data);
          break;
        case 1:
          setErrorMessage("You are not authorized to run simulations in this project");
          break;
        case 2:
          setErrorMessage(`This account has been deleted. You will be redirected to the login screen in ${redirectTimeout} seconds.`);
          break;
        case 3:
          setErrorMessage("The project you are trying to create simulation for does not exist");
          break;
        case 4:
          setErrorMessage("Undefined error");
          break;
        default:
          break;
      }
    }
  };

  //Chart && reach data table
  const handleToggleResults = (value) => {
    setShowWeightedResults(value);
  };

  const generateChartData = useMemo(() => {
    if (!project) return;
    //Generate data arrays used for updating the chart when the user toggles the "Weighted/Unweighted" button
    let weightedData = [];
    let unweightedData = [];
    project.reachReportChartData.quotaGroups.forEach((quotaGroup) => {
      weightedData.push(quotaGroup.weightedReachReportResults);
      unweightedData.push(quotaGroup.unweightedReachReportResults);
    });
    return { weightedData, unweightedData };
  }, [project]);

  const generateInitialChartDataSets = useMemo(() => {
    if (!project) return;
    //TODO: Check with Dennis to maybe select 20-30 colors to use in graphs in situations with a large number of quota groups
    const colors = ["#f3c74d", "#00b068", "pink", "#4ac7ec", "#1c2d41", "#874df3", "#e95151", "#7286a2"];
    let initialDataset = [];
    project.reachReportChartData.quotaGroups.forEach((quotaGroup, index) => {
      initialDataset.push({
        label: quotaGroup.name,
        //Chart displays weighted values by default
        data: quotaGroup.weightedReachReportResults,
        backgroundColor: colors[index],
        borderColor: colors[index],
        fill: false,
      });
    });
    return initialDataset;
  }, [project]);

  useEffect(() => {
    if (!project) return;
    const { weightedData, unweightedData } = generateChartData;
    showWeightedResults ? setChartData(weightedData) : setChartData(unweightedData);
  }, [showWeightedResults, project, generateChartData]);

  return (
    <div className="container-fluid">
      {/* Include Messages Modal */}
      <IncludeSimulationMessagesModal
        showIncludeMessagesModal={showIncludeMessagesModal}
        setShowIncludeMessagesModal={setShowIncludeMessagesModal}
        includedMessageIds={includedMessageIds}
        excludedMessageIds={excludedMessageIds}
        modalMessages={modalMessages}
        messageGroup={messageGroup}
        messagesOperator={messagesOperator}
        handleIncludeMessage={handleIncludeMessage}
        messageLimitReached={messageLimitReached}
      />
      {/* //TODO: Add show reach report modal */}
      <Row>
        <Col sm={12}>
          <div className="page-title-box">
            <div className="float-right">
              <ol className="breadcrumb">
                {project ? (
                  <>
                    <li className="breadcrumb-item active">
                      <CustomLink href={ROUTES.projects}>Projects</CustomLink>
                    </li>
                    <li className="breadcrumb-item active">
                      <Link to={{ pathname: ROUTES.singleProject(projectId), state: { projectId: projectId } }}>{project.name} </Link>
                    </li>
                    <li>New Simulation</li>
                  </>
                ) : (
                  <Spinner animation="border" variant="primary" size="sm" />
                )}
              </ol>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Row>
                {project ? (
                  <>
                    <Col sm={8}>
                      <div className="inline-buttons">
                        <div className="avatar-box thumb-lg align-self-center mr-2">
                          <span className="avatar-title bg-soft-success rounded-circle">E</span>
                        </div>
                        <h4 className="page-title">{project.name}</h4>
                      </div>
                    </Col>
                    <Col sm={4} className="d-flex justify-content-end align-items-center">
                      <ErrorText text={errorMessage} className="mb-0 mr-3" />
                      <Button variant="secondary" onClick={() => changeRoute(ROUTES.singleProject(projectId), { projectId: projectId })}>
                        Back To Project
                      </Button>
                    </Col>
                  </>
                ) : (
                  <Spinner animation="border" variant="primary" />
                )}
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col sm={7}>
          <Row>
            <Col>
              {project ? (
                <Card style={{ minHeight: "75vh" }}>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Form onSubmit={handleSubmit}>
                          <Form.Group as={Row}>
                            <Form.Label column sm={2} className="text-left">
                              Quota Group
                            </Form.Label>
                            <Col sm={10}>
                              <CustomSelect
                                options={quotaGroupSelectOptions}
                                styles={customSelectQuotaGroupStyle}
                                animated
                                isMulti
                                closeMenuOnSelect={false}
                                onChange={handleSelectQuotaGroups}
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row} className="mt-5">
                            <Form.Label column sm={2} className="text-left">
                              Weighting
                            </Form.Label>
                            <Col sm={10}>
                              <ToggleButtonGroup type="radio" name="weight" value={isWeighted} onChange={handleWeight}>
                                <ToggleButton value={true} variant={isWeighted ? "primary" : "outline-primary"}>
                                  Weighted
                                </ToggleButton>
                                <ToggleButton value={false} variant={!isWeighted ? "primary" : "outline-primary"}>
                                  Unweighted
                                </ToggleButton>
                              </ToggleButtonGroup>
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row} className="mt-5">
                            <Form.Label column sm={2} className="text-left">
                              # Of Messages
                            </Form.Label>
                            <Col sm={10}>
                              <CustomSelect
                                options={messageGroupSelectOptions}
                                styles={customSelectSyles}
                                animated
                                closeMenuOnSelect={true}
                                onChange={handleSelectMessageGroup}
                              />
                            </Col>
                          </Form.Group>
                          <Form.Group as={Row} className="mt-5">
                            <Form.Label column sm={2} className="text-left">
                              Messages to Include
                            </Form.Label>
                            <Col sm={10}>
                              <CustomSelect
                                isDisabled={messageGroup === null}
                                value={includeAllMessages ? messagesToIncludeOptions[0] : messagesToIncludeOptions[1]}
                                options={messagesToIncludeOptions}
                                styles={customSelectSyles}
                                isMulti={false}
                                onChange={handleSelectMessagesToIncludeSection}
                              />
                            </Col>
                            {showIncludeMessagesSection && (
                              <Col sm={{ span: 10, offset: 2 }}>
                                <Form.Group as={Row} className="mt-3 pl-2">
                                  <Form.Check
                                    inline
                                    label="And"
                                    type="radio"
                                    name="messagesOperator"
                                    value={"1"}
                                    checked={messagesOperator === "1"}
                                    onChange={handleSelectMessagesOperator}
                                  />
                                  <Form.Check
                                    inline
                                    label="Or"
                                    type="radio"
                                    name="messagesOperator"
                                    value={"2"}
                                    checked={messagesOperator === "2"}
                                    onChange={handleSelectMessagesOperator}
                                  />
                                </Form.Group>
                                <p>You need to select which messages will be included with your simulation. Press the button below to do that.</p>
                                {includedMessageIds.length > 0 ? (
                                  <PreviewMessagesTable selectedMessages={modalMessages} onClick={() => setShowIncludeMessagesModal(true)} />
                                ) : (
                                  <Button disabled={messagesOperator === null} onClick={() => setShowIncludeMessagesModal(true)}>
                                    {includedMessageIds.length > 0 ? "Edit messages" : "Select messages"}
                                  </Button>
                                )}
                              </Col>
                            )}
                          </Form.Group>
                          <Form.Group as={Row} className="mt-5">
                            <Form.Label column sm={2} className="text-left">
                              Messages to Exclude
                            </Form.Label>
                            <Col sm={10}>
                              <CustomSelect
                                isDisabled={messageGroup === null}
                                options={excludeMessagesSelectOptions}
                                styles={customSelectSyles}
                                isMulti
                                onChange={handleExcludeMessage}
                              />
                            </Col>
                          </Form.Group>

                          <Form.Group as={Row} className="my-5">
                            <Form.Label column sm={2} className="text-left">
                              Name
                              <span>
                                <small> (Optional)</small>
                              </span>
                            </Form.Label>
                            <Col sm={10} className="mb-5">
                              {/* TODO: Fix inline styles */}
                              <Form.Control type="text" style={{ border: "1px solid #cccccc" }} onChange={handleInputChange} value={simulationName} />
                            </Col>
                          </Form.Group>
                          <div className="mt-5" style={{ borderTop: "1px solid #7081b9" }}>
                            <div className="d-flex justify-content-end align-items-center mt-3">
                              {errorMessage && <ErrorText text={errorMessage} className="pb-0 mb-0 mr-3" />}
                              <Button type="submit" disabled={!canSubmit}>
                                {simulationIsLoading ? <Spinner animation="border" size="sm" variant="primary" /> : "Run Simulation"}
                              </Button>
                            </div>
                          </div>
                        </Form>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              ) : (
                <div className="d-flex justify-content-center">
                  <Spinner animation="border" variant="primary" />
                </div>
              )}
            </Col>
          </Row>
        </Col>

        <Col sm={5}>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <Row>
                    <Col>
                      <Card>
                        <Card.Body>
                          <Row>
                            <Col>
                              <h5 className="float-left">Reach report</h5>
                            </Col>
                          </Row>
                          <div className="float-right eco-revene-history justify-content-end">
                            {project ? (
                              <ToggleChartResults showWeightedResults={showWeightedResults} handleToggleResults={handleToggleResults} />
                            ) : (
                              <Spinner animation="border" variant="primary" />
                            )}
                          </div>

                          {project ? (
                            <Chart
                              chartType="line"
                              initialChartData={generateInitialChartDataSets}
                              chartData={chartData}
                              displayLegend={true}
                              legendPosition="bottom"
                              legendAlignment="center"
                              legendLabelBoxWidth={20}
                            />
                          ) : (
                            <div className="d-flex justify-content-center">
                              <Spinner animation="border" variant="primary" />
                            </div>
                          )}
                          {/* TODO: Add show reach report modal */}
                          {/* <div className="d-flex justify-content-end mt-4">
                            <Button variant="outline-primary">Show Reach Report</Button>
                          </div> */}
                          {/* {quotaGroups && "Quota groups: " + quotaGroups.toString()}
                          <br></br>
                          {"Weight: " + isWeighted.toString()}
                          <br></br>
                          {messageGroup && "Message group: " + messageGroup.toString()}
                          <br></br>
                          {messagesOperator && "Messages operator: " + messagesOperator.toString()}
                          <br />
                          {includedMessageIds && "Included message ids: " + includedMessageIds.toString()}
                          <br></br>
                          {"Include all messages: " + includeAllMessages.toString()}
                          <br></br>
                          {excludedMessageIds && "Excluded message ids: " + excludedMessageIds.toString()}
                          <br />
                          {simulationName}
                          <br />
                          {messages &&
                            messages.map((message, index) => (
                              <p
                                key={message + index}
                              >{`MessageId: ${message.messageId}, isIncluded: ${message.isIncluded}, operator: ${message.messageOperator}`}</p>
                            ))} */}
                          {/*<Tab eventKey="table" title="Table">
                              <div className="tab-pane p-3">
                                {/* {project ? (
                          <ReachTable tableData={project.reachReportTableData} weighted={showWeightedResults} />
                        ) : (
                          <div className="d-flex justify-content-center">
                            <Spinner animation="border" variant="primary" />
                          </div>
                        )} */}
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default CreateSimulation;
