import React, { useState, useEffect } from "react";
import { Form, Row, Col, ToggleButtonGroup, ToggleButton, Button } from "react-bootstrap";
import { connect } from "react-redux";
import CustomInputField from "./CustomInputField";
import { ErrorText, SuccessText } from "components/Text";
import CustomSelect from "components/Forms/CustomSelect";
import { getAllProjects } from "apis/projectApi";
import { Icon } from "components/Icon";

const AddNewUserForm = ({ admin, formData, firstValidation, inputValidation, handleInputChange, handleUserSelect }) => {
  const [availableProjects, setAvailableProjects] = useState([]);

  const fetchProjects = async () => {
    var result = await getAllProjects();
    if (result && result.data) {
      setAvailableProjects(result.data.items || []);
    }
  };
  useEffect(() => {
    fetchProjects();
  }, []);

  return (
    <Form noValidate id="form-vertical">
      {/* TODO: Add ::before to h5 and first input row */}
      <div className="content w-100 d-flex flex-column">
        <Row className="align-items-start mb-4">
          <Col>
            <h5>1. Select user type</h5>
          </Col>
          <Col className="d-flex justify-content-between">
            <ToggleButtonGroup type="radio" name="userType" onChange={handleUserSelect}>
              <ToggleButton variant="outline-primary" className="mr-2 cursor-pointer" value={0}>
                Admin
              </ToggleButton>
              <ToggleButton variant="outline-primary" className="mx-2 cursor-pointer" value={1}>
                Employee
              </ToggleButton>
              <ToggleButton variant="outline-primary" className="mx-2 cursor-pointer" value={2}>
                Client
              </ToggleButton>
            </ToggleButtonGroup>
          </Col>
        </Row>
        <div>
          <h5>2. Enter user data</h5>
          {formData.userType === 2 && (
            <>
              <Row>
                <Col>
                  <CustomInputField
                    label="Company name"
                    type="text"
                    name="companyName"
                    handleInputChange={handleInputChange}
                    className={firstValidation && inputValidation.companyName ? "is-valid" : ""}
                    value={formData.companyName}
                    isInvalid={firstValidation && !inputValidation.companyName ? true : false}
                    errorMsg="Company name is required"
                  />
                </Col>
                {/* <Col>
                <CustomInputField
                  label="Project number"
                  type="text"
                  name="projectNumber"
                  handleInputChange={handleInputChange}
                  className={firstValidation && inputValidation.projectNumber ? "is-valid" : ""}
                  value={formData.projectNumber}
                  isInvalid={firstValidation && !inputValidation.projectNumber ? true : false}
                  errorMsg="Project number is required"
                />
              </Col> */}
              </Row>
              <Row>
                <Col>Assigned Projects</Col>
                <Col>
                  <CustomSelect
                    options={availableProjects
                      .filter((project) => {
                        if (!formData.projects) {
                          return true;
                        }
                        let exists = false;
                        formData.projects.forEach((element) => {
                          if (element.id === project.id) {
                            exists = true;
                          }
                        });
                        return !exists;
                      })
                      .map((project) => {
                        return { value: project, label: project.name };
                      })}
                    onChange={(selected) => {
                      let newProjects = [...formData.projects.filter((project) => project.id !== selected.value.id)];
                      handleInputChange({
                        target: {
                          name: "projects",
                          value: [...newProjects, selected.value],
                        },
                      });
                    }}
                    //styles={customSelectSyles}
                    //defaultValue={selectMessageGroupsOptions.length > 0 && selectMessageGroupsOptions[0]}
                  />
                </Col>
              </Row>
              {formData.projects && formData.projects.length > 0 && (
                <Row>
                  <Col colSpan="2">
                    {formData.projects.map((project) => {
                      return (
                        <Button
                          variant="light"
                          className="mr-2 mb-2"
                          key={project.id}
                          onClick={() => {
                            handleInputChange({
                              target: {
                                name: "projects",
                                value: [...formData.projects.filter((existingProject) => existingProject.id !== project.id)],
                              },
                            });
                          }}
                        >
                          <Icon name="fa-trash-alt" context="danger" className="mr-2" />
                          {project.name}
                        </Button>
                      );
                    })}
                  </Col>
                </Row>
              )}
            </>
          )}
          {(formData.userType === 0 || formData.userType === 1) && (
            <Row>
              <Col xs={6}>
                <CustomInputField
                  label="Project team"
                  type="text"
                  name="projectTeam"
                  handleInputChange={handleInputChange}
                  className={firstValidation && inputValidation.projectTeam ? "is-valid" : ""}
                  value={formData.projectTeam}
                  isInvalid={firstValidation && !inputValidation.projectTeam ? true : false}
                  errorMsg="Project team is required"
                />
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <CustomInputField
                label="First name"
                type="text"
                name="firstName"
                disabled={formData.userType === null}
                handleInputChange={handleInputChange}
                className={firstValidation && inputValidation.firstName && "is-valid"}
                value={formData.firstName}
                isInvalid={firstValidation && !inputValidation.firstName}
                errorMsg="First name is required"
              />
            </Col>
            <Col>
              <CustomInputField
                label="Last name"
                type="text"
                name="lastName"
                disabled={formData.userType === null}
                handleInputChange={handleInputChange}
                className={firstValidation && inputValidation.lastName && "is-valid"}
                value={formData.lastName}
                isInvalid={firstValidation && !inputValidation.lastName}
                errorMsg="Last name is required"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <CustomInputField
                label="Email"
                type="text"
                name="email"
                disabled={formData.userType === null}
                handleInputChange={handleInputChange}
                className={firstValidation && inputValidation.email && "is-valid"}
                value={formData.email}
                isInvalid={(firstValidation && !inputValidation.email) || (firstValidation && admin.response.result === 4)}
                errorMsg={!inputValidation.email && "Please enter a valid email!"}
              />
            </Col>
            <Col>
              <CustomInputField
                label="Repeat email"
                type="text"
                name="repeatEmail"
                disabled={formData.userType === null}
                handleInputChange={handleInputChange}
                className={firstValidation && inputValidation.repeatEmail && formData.repeatEmail.length > 0 && "is-valid"}
                value={formData.repeatEmail}
                isInvalid={
                  (firstValidation && !inputValidation.email) ||
                  (firstValidation && formData.repeatEmail.length < 1) ||
                  (firstValidation && !inputValidation.repeatEmail) ||
                  (firstValidation && admin.response.result === 4)
                }
                errorMsg={
                  firstValidation && !inputValidation.email
                    ? "Please enter a valid email"
                    : firstValidation && formData.repeatEmail < 1
                    ? "Please repeat the email address"
                    : firstValidation && !inputValidation.repeatEmail
                    ? "Emails do not match!"
                    : ""
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <CustomInputField
                label="Password"
                type="password"
                name="password"
                disabled={formData.userType === null}
                handleInputChange={handleInputChange}
                className={firstValidation && inputValidation.password ? "is-valid" : ""}
                value={formData.password}
                isInvalid={firstValidation && !inputValidation.password}
                errorMsg={!inputValidation.password ? "Password is too short!" : "Password is required"}
              />
            </Col>
            <Col>
              <CustomInputField
                label="Repeat password"
                type="password"
                name="repeatPassword"
                disabled={formData.userType === null}
                handleInputChange={handleInputChange}
                className={firstValidation && inputValidation.repeatPassword ? "is-valid" : ""}
                value={formData.repeatPassword}
                isInvalid={
                  (firstValidation && !inputValidation.password) ||
                  (firstValidation && formData.repeatPassword.length < 1) ||
                  (firstValidation && !inputValidation.repeatPassword)
                }
                errorMsg={
                  firstValidation && formData.repeatPassword.length < 1
                    ? "Please repeat the password!"
                    : firstValidation && !inputValidation.repeatPassword
                    ? "Passwords do not match!"
                    : firstValidation && !inputValidation.password
                    ? "Password is too short"
                    : ""
                }
              />
            </Col>
          </Row>
        </div>
        {<ErrorText text={admin.response.errorMessage} />}
        {admin.response.result === 0 && <SuccessText text="User successfully added!" />}
      </div>
    </Form>
  );
};

const mapStateToProps = (state) => ({ admin: state.admin });

export default connect(mapStateToProps, null)(AddNewUserForm);
