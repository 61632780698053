import { typeUserRegistration } from "constants/actionTypes";

const initialState = {
  userType: null,
  registrationInProgress: false,
  response: {
    result: null,
    message: "",
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case typeUserRegistration.selectUserType:
      return {
        ...state,
        userType: payload,
      };
    case typeUserRegistration.registerUserStart:
      return {
        ...state,
        registrationInProgress: true,
      };
    case typeUserRegistration.registerUserEnd:
      return {
        ...state,
        registrationInProgress: false,
        response: {
          result: payload.response.result,
          message: payload.response.message,
        },
      };
    case typeUserRegistration.resetResponse:
      return {
        ...state,
        response: {
          result: null,
          message: "",
        },
      };
    default:
      break;
  }
  return state;
};
