import React from "react";
import { Pagination } from "react-bootstrap";

const TablePagination = ({ currentPage, pageSize, pagesToShow = 5, totalEntries, onChange }) => {
  let totalPages = totalEntries === 0 ? 0 : Math.ceil(totalEntries / pageSize);

  if (totalEntries < 0 || totalPages < 2) return null;

  const handlePageChange = (selectedPage) => {
    onChange(selectedPage);
  };

  const goToPreviousPage = () => {
    handlePageChange(currentPage - 1);
  };

  const goToNextPage = () => {
    handlePageChange(currentPage + 1);
  };

  let maxPage = Math.floor(totalEntries / pageSize + (totalEntries % pageSize > 0 ? 1 : 0));
  let toPage = pagesToShow;
  let fromPage = Math.floor(currentPage - pagesToShow / 2 + 1);
  if (fromPage <= 1) {
    fromPage = 1;
  } else {
    toPage = Math.floor(currentPage + pagesToShow / 2);
  }
  if (toPage > maxPage) {
    toPage = maxPage;
    fromPage = toPage - pagesToShow + 1 >= 1 ? toPage - pagesToShow + 1 : 1;
  }
  const renderPages = () => {
    const paginationItems = [];

    for (let i = fromPage; i <= toPage; i++) {
      paginationItems.push(
        <Pagination.Item key={i} onClick={() => handlePageChange(i)} active={currentPage === i}>
          {i}
        </Pagination.Item>
      );
    }

    return paginationItems;
  };

  return (
    <Pagination className="mb-0">
      {!(currentPage === 1) && (
        <>
          <Pagination.Item onClick={() => handlePageChange(1)}>First</Pagination.Item>
          <Pagination.Item onClick={goToPreviousPage}>Previous</Pagination.Item>
        </>
      )}
      {renderPages(pagesToShow)}
      {!(currentPage === totalPages) && totalEntries > 1 && (
        <>
          <Pagination.Item onClick={goToNextPage}>Next</Pagination.Item>
          <Pagination.Item onClick={() => handlePageChange(totalPages)}>Last</Pagination.Item>
        </>
      )}
    </Pagination>
  );
};

export default TablePagination;
