import React from "react";
import { Table } from "react-bootstrap";
import { TableHeaderCell } from "components/Tables";

const SimulationResultsTable = ({ data, page, pageSize, activeColumn, orderIsDescending, handleSelectSortingOrder, onHover }) => {
  const headers = ["Leverageability", "Reach", "Adjusted Leverageability"];

  return (
    <Table responsive>
      <thead className="thead-light">
        <tr>
          <th>#</th>
          <th>Messages</th>
          {headers.map((header, index) => {
            return (
              <TableHeaderCell
                key={header}
                title={header}
                currentColumn={index}
                activeColumn={activeColumn}
                orderIsDescending={orderIsDescending}
                handleSelectSortingOrder={handleSelectSortingOrder}
                className="text-center"
              />
            );
          })}
        </tr>
      </thead>
      <tbody>
        {data &&
          data.map(({ messages, leverageability, reach, adjustedLeverageability }, index) => {
            const tableRowIndex = page === 1 ? index + 1 : index + 1 + (page - 1) * pageSize;
            return (
              <tr key={tableRowIndex} onMouseEnter={() => onHover({ messages, rowNumber: tableRowIndex })}>
                <td>{tableRowIndex}.</td>
                <td>{messages}</td>
                <td className="text-center">{leverageability.toFixed(0)}</td>
                <td className="text-center">{reach.toFixed(0)}</td>
                <td className="text-center">{adjustedLeverageability.toFixed(0)}</td>
              </tr>
            );
          })}
      </tbody>
    </Table>
  );
};

export default SimulationResultsTable;
