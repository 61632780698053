import { useEffect } from "react";
import { useDebounce } from "react-use";

const useDebounceAfterMount = (callbackFunction, timeoutValue, dependencyArray) => {
  const [isReady, cancelDebounce] = useDebounce(
    () => {
      callbackFunction();
    },
    timeoutValue,
    [...dependencyArray]
  );

  //Cancel debounce on initial render
  useEffect(() => {
    cancelDebounce();
  }, [cancelDebounce]);

  return [isReady, cancelDebounce];
};

export default useDebounceAfterMount;
