import React from "react";
import { Button, Modal, Spinner, FormCheck, Row, Col } from "react-bootstrap";
import { CustomInputField } from "components/Forms";
import { ErrorText } from "components/Text";

const EditProjectModal = ({
  showModal,
  onClose,
  changeProjectNameValue,
  handleChangeProjectNameInput,
  handleChangeProjectName,
  editProjectErrorMessage,
  changeProjectNameIsLoading,
  isProjectAccessLimited,
  projectAccessErrorMessage,
  isToggleActive,
  handleToggleCheckbox,
  newPasswordValue,
  handleChangeProjectPasswordAccess,
  handleNewPasswordInputChange,
  changeProjectPasswordAccessIsLoading,
}) => {
  return (
    <Modal show={showModal} centered onHide={onClose} size="lg">
      <Modal.Body>
        <div className="d-flex flex-column align-items-start mt-3 p-3">
          <Row className="d-flex align-items-center w-100">
            <Col sm={5}>
              <CustomInputField
                label="Change project name"
                value={changeProjectNameValue}
                handleInputChange={handleChangeProjectNameInput}
                isInvalid={editProjectErrorMessage}
                errorMsg={editProjectErrorMessage}
              />
            </Col>
            <Col sm={1}>
              <div className="ml-5 mt-2">
                <Button disabled={!changeProjectNameValue} onClick={handleChangeProjectName}>
                  {changeProjectNameIsLoading ? <Spinner size="sm" animation="border" /> : "Apply"}
                </Button>
              </div>
            </Col>
          </Row>

          <Row className="d-flex align-items-end mt-5 w-100">
            <Col sm={5}>
              <FormCheck
                id="projectAccessSwitch"
                type="switch"
                label="Limit project access with password"
                checked={isToggleActive}
                onChange={handleToggleCheckbox}
              />
              {isToggleActive && !isProjectAccessLimited && (
                <CustomInputField
                  label="Enter new project password"
                  value={newPasswordValue}
                  handleInputChange={handleNewPasswordInputChange}
                  formGroupClassName="mb-0"
                />
              )}
            </Col>
            {isToggleActive !== isProjectAccessLimited && (
              <Col sm={7}>
                <Row>
                  <Col sm={3} className="d-flex align-items-end ml-5 mt-2">
                    <Button onClick={handleChangeProjectPasswordAccess}>
                      {changeProjectPasswordAccessIsLoading ? <Spinner size="sm" animation="border" /> : "Apply"}
                    </Button>
                  </Col>
                  <Col sm={7}>
                    <ErrorText text={projectAccessErrorMessage} />
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditProjectModal;
