import React from "react";
import classNames from "classnames";

const CustomMenu = React.forwardRef(({ children, style, backgroundClassName, className, listClassName, "aria-labelledby": labeledBy }, ref) => {
  let backgroundClassesString = classNames(backgroundClassName, className);

  let listClassesString = classNames(listClassName);
  return (
    <div ref={ref} style={style} className={backgroundClassesString} aria-labelledby={labeledBy}>
      <ul className={listClassesString}>{children}</ul>
    </div>
  );
});

export default CustomMenu;
