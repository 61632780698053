import React, { useState, useEffect, useRef, useReducer } from "react";
import classNames from "classnames";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Tab, Spinner, Card, Button, Nav, Dropdown } from "react-bootstrap";
import { ErrorText } from "components/Text";
import { CustomLink } from "components/CustomLink";
import { Link } from "react-router-dom";
import { CustomEntriesDisplay } from "components/Tables";
import { SimulationResultsTable, TablePagination, ProjectMessagesTable } from "components/Tables";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { openDownloadPanel } from "actions/simulationActions";
import { runSimulation, getSimulationData, queueFileDownload, saveSimulationFilters, deleteSimulationFilters } from "apis/simulationApi";
import ROUTES from "constants/routes";
import { CustomCheckboxv2 } from "components/Forms";
import { Avatar } from "components/Avatar";
import { useQueryParams, useRouteId } from "utility/hooks";
import { CustomMenu, CustomToggle, ActiveFilters } from "components/Filters";

const initialFilterValues = {
  reach: { min: 0, max: 100 },
  leverageability: { min: 0, max: 1000 },
  adjustedLeverageability: { min: 0, max: 1000 },
  messageIds: "",
  categories: "",
};

const initialState = {
  //API call data
  projectName: "",
  projectId: null,
  simulationName: "",
  simulationIsWeighted: false,
  simulationQuotaGroups: null,
  simulationMessages: [],
  simulationCategories: [],
  selectedQuotaGroupId: null,
  simulationUserFilters: null,

  //Filters
  activeFilters: [],
  APIrequestFilterValues: null,

  //Table state
  tableData: null,
  simulationResultsCount: null,
  pageSize: 10,
  page: 1,
  orderIsDescending: true,
  activeColumn: 2,
  sidePanelMessages: null,
  simulationResultsAreLoading: false,
  filterRequestIsLoading: false,
};

const simulationReducer = (state, action) => {
  switch (action.type) {
    case "setSimulationData":
      return {
        ...state,
        projectName: action.payload.projectName,
        projectId: action.payload.projectId,
        simulationName: action.payload.simulationName,
        simulationIsWeighted: action.payload.simulationIsWeighted,
        simulationQuotaGroups: action.payload.simulationQuotaGroups,
        selectedQuotaGroupId: action.payload.selectedQuotaGroupId,
        simulationUserFilters: action.payload.simulationUserFilters,
        sidePanelMessages: action.payload.messages,
      };

    case "setSimulationMessages":
      return {
        ...state,
        simulationMessages: action.payload.map((message) => ({
          messageId: message.messageId,
          text: message.text,
          category: message.category,
          messageNumber: message.messageNumber,
          isChecked: false,
        })),
      };

    case "setSimulationCategories":
      let categoryNames = new Set();
      let categories = [];
      action.payload.forEach((message) => {
        categoryNames.add(message.category);
      });
      categoryNames.forEach((category) => {
        //Add to main filter state
        categories.push({ categoryName: category, isChecked: false });
      });
      return {
        ...state,
        simulationCategories: categories,
      };

    case "setSidePanelMessages":
      return { ...state, sidePanelMessages: action.payload };

    case "setSimulationResults":
      return {
        ...state,
        tableData: action.payload.tableData,
        simulationResultsCount: action.payload.simulationResultsCount,
        page: action.payload.page,
        pageSize: action.payload.pageSize,
      };

    case "selectSortingOrder":
      return {
        ...state,
        orderIsDescending: state.activeColumn === action.payload ? !state.orderIsDescending : true,
        activeColumn: state.activeColumn === action.payload ? state.activeColumn : action.payload,
        page: 1,
      };

    case "changePage":
      return { ...state, page: action.payload };

    case "selectQuotaGroup":
      return { ...state, selectedQuotaGroupId: action.payload.quotaGroupId, page: action.payload.page };

    case "setActiveFilters":
      return { ...state, activeFilters: action.payload };

    case "applyFilters":
      return { ...state, APIrequestFilterValues: action.payload.APIrequestFilterValues, page: action.payload.page };

    case "addFilter":
      return { ...state, activeFilters: [...state.activeFilters, action.payload] };

    case "removeFilter":
      return {
        ...state,
        APIrequestFilterValues: { ...state.APIrequestFilterValues, [action.payload.filterName]: action.payload.resetValue },
        activeFilters: action.payload.newActiveFilters,
        page: 1,
      };

    case "clearAllFilters":
      return {
        ...state,
        APIrequestFilterValues: initialFilterValues,
        simulationMessages: state.simulationMessages.map((message) => ({ ...message, isChecked: false })),
        simulationCategories: state.simulationCategories.map((category) => ({ ...category, isChecked: false })),
        activeFilters: [],
        page: 1,
      };

    default:
      break;
  }
};

const Simulation = ({ openDownloadPanel, user }) => {
  const redirectTimeout = 5000;

  const simulationId = useRouteId();
  const { queryParams, setQueryParam, removeQueryParams, resetAllParams } = useQueryParams();
  const [state, dispatch] = useReducer(simulationReducer, initialState);
  const [filterRequestIsLoading, setFilterRequestIsLoading] = useState(false);
  const [simulationResultsErrorMessage, setSimulationResultsErrorMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [reloadSimulationResults, setReloadSimulationResults] = useState(false);
  const handleRetryLoadingSimulationResults = () => {
    setSimulationResultsErrorMessage("");
    setReloadSimulationResults(!reloadSimulationResults);
  };

  //Filters
  const saveFiltersToDb = async ({
    simulationId,
    reachMin,
    reachMax,
    leverageabilityMin,
    leverageabilityMax,
    adjustedleverageabilityMin,
    adjustedleverageabilityMax,
    messages,
    categories,
  }) => {
    const response = await saveSimulationFilters({
      simulationId,
      reachMin,
      reachMax,
      leverageabilityMin,
      leverageabilityMax,
      adjustedleverageabilityMin,
      adjustedleverageabilityMax,
      messages,
      categories,
    });
    if (response && response.data) {
      switch (response.data.result) {
        case 0:
          setSimulationResultsErrorMessage("");
          break;
        case 1:
          setSimulationResultsErrorMessage("Failed to save simulationFilters");
          break;
        case 2:
          setSimulationResultsErrorMessage("Failed to save simulationFilters");
          break;
        case 3:
          setSimulationResultsErrorMessage("Failed to save simulationFilters");
          break;
        case 4:
          setSimulationResultsErrorMessage("Failed to save simulationFilters");
          break;
        default:
          break;
      }
    } else {
      setSimulationResultsErrorMessage("Failed to retrieve simulation results");
    }
  };
  const deleteFiltersFromDb = async ({ simulationId }) => {
    const response = await deleteSimulationFilters({
      simulationId,
    });
    if (response && response.data) {
      switch (response.data.result) {
        case 0:
          setSimulationResultsErrorMessage("");
          break;
        case 1:
          setSimulationResultsErrorMessage("Failed to delete simulationFilters");
          break;
        case 2:
          setSimulationResultsErrorMessage("Failed to delete simulationFilters");
          break;
        case 3:
          setSimulationResultsErrorMessage("Failed to delete simulationFilters");
          break;
        case 4:
          setSimulationResultsErrorMessage("Failed to delete simulationFilters");
          break;
        default:
          break;
      }
    } else {
      setSimulationResultsErrorMessage("Failed to retrieve simulation results");
    }
  };

  const removeFilter = ({ filterName, activeFilters }) => {
    let {
      reach: resetReach,
      leverageability: resetLeverageability,
      adjustedLeverageability: resetAdjustedLeverageability,
      messageIds: resetRequestMessageIds,
      categories: resetRequestCategories,
    } = state.APIrequestFilterValues;

    let resetValue;

    if (filterName === "reach" || filterName === "leverageability" || filterName === "adjustedLeverageability") {
      switch (filterName) {
        case "reach":
          removeQueryParams({ paramNames: ["reachMin", "reachMax"] });
          resetValue = resetReach = { min: 0, max: 100 };
          break;
        case "leverageability":
          removeQueryParams({ paramNames: ["leverageabilityMin", "leverageabilityMax"] });
          resetValue = resetLeverageability = { min: 0, max: 1000 };
          break;
        case "adjustedLeverageability":
          removeQueryParams({ paramNames: ["adjustedLeverageabilityMin", "adjustedLeverageabilityMax"] });
          resetValue = resetAdjustedLeverageability = { min: 0, max: 1000 };
          break;
        default:
          break;
      }
      dispatch({ type: "removeFilter", payload: { filterName, resetValue, newActiveFilters: activeFilters } });
    }

    if (filterName === "messages" || filterName === "categories") {
      switch (filterName) {
        case "messages":
          removeQueryParams({ paramNames: ["messages"] });
          resetValue = "";
          dispatch({ type: "setSimulationMessages", payload: state.simulationMessages });
          dispatch({ type: "removeFilter", payload: { filterName: "messageIds", resetValue, newActiveFilters: activeFilters } });
          break;
        case "categories":
          removeQueryParams({ paramNames: ["categories"] });
          resetValue = "";
          dispatch({ type: "setSimulationCategories", payload: state.simulationMessages });
          dispatch({ type: "removeFilter", payload: { filterName: "categories", resetValue, newActiveFilters: activeFilters } });
          break;

        default:
          break;
      }
    }

    if (activeFilters.length > 0) {
      saveFiltersToDb({
        simulationId,
        reachMin: parseFloat(resetReach.min),
        reachMax: parseFloat(resetReach.max),
        leverageabilityMin: parseFloat(resetLeverageability.min),
        leverageabilityMax: parseFloat(resetLeverageability.max),
        adjustedleverageabilityMin: parseFloat(resetAdjustedLeverageability.min),
        adjustedleverageabilityMax: parseFloat(resetAdjustedLeverageability.max),
        messages: resetRequestMessageIds,
        categories: resetRequestCategories,
      });
    } else {
      deleteFiltersFromDb({ simulationId });
    }

    //Check if user in the address bar is the same as the current user. If not, replace uId with current user's uId
    if (queryParams.get("uId") !== user.currentUser.id) {
      setQueryParam({ paramName: "uId", paramValue: user.currentUser.id });
    }
  };

  const handleFilterSelect = (event) => {
    const filterName = event.target.value;
    const isChecked = event.target.checked;
    if (isChecked) {
      //Add filter to array of active filters
      dispatch({ type: "addFilter", payload: filterName });
    } else {
      //Remove filter from array of active filters
      removeFilter({ filterName, activeFilters: state.activeFilters.filter((x) => x !== filterName) });
    }
  };

  const handleFilterClose = ({ filterName }) => {
    removeFilter({ filterName, activeFilters: state.activeFilters.filter((x) => x !== filterName) });
  };

  const handleApplyFilters = ({ filterName, values }) => {
    let newAPIrequestFilterValues = JSON.parse(JSON.stringify(state.APIrequestFilterValues));
    switch (filterName) {
      case "reach":
      case "adjustedLeverageability":
      case "leverageability":
        newAPIrequestFilterValues[filterName] = values;
        for (let key in values) {
          setQueryParam({ paramName: `${filterName}${key[0].toUpperCase()}${key.slice(1)}`, paramValue: values[key] });
        }
        break;

      case "messages":
        newAPIrequestFilterValues.messageIds = values
          .map((message) => {
            return message.isChecked ? message.messageNumber : null;
          })
          .filter(Boolean)
          .join(",");
        dispatch({ type: "setSimulationMessages", payload: values });
        setQueryParam({ paramName: filterName, paramValue: newAPIrequestFilterValues.messageIds });
        break;

      case "categories":
        newAPIrequestFilterValues[filterName] = values
          .map((category) => {
            return category.isChecked ? category.categoryName : null;
          })
          .filter(Boolean)
          .join(",");

        dispatch({ type: "setSimulationCategories", payload: values });
        //
        setQueryParam({ paramName: filterName, paramValue: newAPIrequestFilterValues.categories });
        break;
      default:
        break;
    }
    dispatch({ type: "applyFilters", payload: { APIrequestFilterValues: newAPIrequestFilterValues, page: 1 } });
    //Save filters to DB
    saveFiltersToDb({
      simulationId,
      reachMin: parseFloat(newAPIrequestFilterValues.reach.min),
      reachMax: parseFloat(newAPIrequestFilterValues.reach.max),
      leverageabilityMin: parseFloat(newAPIrequestFilterValues.leverageability.min),
      leverageabilityMax: parseFloat(newAPIrequestFilterValues.leverageability.max),
      adjustedleverageabilityMin: parseFloat(newAPIrequestFilterValues.adjustedLeverageability.min),
      adjustedleverageabilityMax: parseFloat(newAPIrequestFilterValues.adjustedLeverageability.max),
      messages: newAPIrequestFilterValues.messageIds,
      categories: newAPIrequestFilterValues.categories,
    });
    //Check if user in the address bar is the same as the current user. If not, replace uId with current user's uId
    if (queryParams.get("uId") !== user.currentUser.id) {
      setQueryParam({ paramName: "uId", paramValue: user.currentUser.id });
    }
    setQueryParam({ paramName: "page", paramValue: 1 });
  };

  const handleClearAllFilters = () => {
    dispatch({ type: "clearAllFilters" });
    //Deletes filters from DB
    deleteFiltersFromDb({ simulationId });
    //Resets query params
    resetAllParams();
    setQueryParam({ paramName: "uId", paramValue: user.currentUser.id });
    setQueryParam({ paramName: "page", paramValue: 1 });
  };

  const handleSelectQuotaGroupId = ({ quotaGroupId }) => {
    setSimulationResultsErrorMessage("");
    dispatch({ type: "selectQuotaGroup", payload: { quotaGroupId, page: 1 } });
    setQueryParam({ paramName: "page", paramValue: 1 });
    setQueryParam({ paramName: "qgId", paramValue: quotaGroupId });
  };

  //Results table data display
  const [hoveredRowNumber, setHoveredRowNumber] = useState("");
  const [simulationResultsAreLoading, setSimulationResultsAreLoading] = useState(false);
  const messagesTimerRef = useRef(null);

  const handleHoverOverRow = ({ messages, rowNumber }) => {
    if (messagesTimerRef.current !== null) {
      clearTimeout(messagesTimerRef.current);
    }
    setSimulationResultsAreLoading(true);
    const messageNumberStrings = messages.split(",");
    const messageNumbers = messageNumberStrings.map((numberString) => parseInt(numberString));
    const messagesToShow = state.simulationMessages.filter((sidePanelMessage) => messageNumbers.includes(sidePanelMessage.messageNumber));

    messagesTimerRef.current = setTimeout(() => {
      setSimulationResultsAreLoading(false);
      setHoveredRowNumber(rowNumber);
      dispatch({ type: "setSidePanelMessages", payload: messagesToShow });
    }, 500);
  };

  const handleMouseLeaveTable = () => {
    setTimeout(() => {
      setHoveredRowNumber("");
      dispatch({ type: "setSidePanelMessages", payload: state.simulationMessages });
    }, 500);
  };

  const handleSelectSortingOrder = ({ selectedColumn }) => {
    setFilterRequestIsLoading(true);
    dispatch({ type: "selectSortingOrder", payload: selectedColumn });
  };

  useEffect(() => {
    if (!state.APIrequestFilterValues) return;
    const handleGetSimulationResults = async ({
      projectId,
      simulationId,
      quotaGroupId,
      page,
      pageSize,
      orderIsDescending,
      sortBy,
      reachMin,
      reachMax,
      leverageabilityMin,
      leverageabilityMax,
      adjLeverageabilityMin,
      adjLeverageabilityMax,
      messageIds,
      messageCategories,
    }) => {
      setFilterRequestIsLoading(true);
      let response = await runSimulation(
        projectId,
        simulationId,
        quotaGroupId,
        page,
        pageSize,
        orderIsDescending,
        sortBy,
        reachMin,
        reachMax,
        leverageabilityMin,
        leverageabilityMax,
        adjLeverageabilityMin,
        adjLeverageabilityMax,
        messageIds,
        messageCategories
      );
      if (response.data) {
        switch (response.data.result) {
          case 0:
            dispatch({
              type: "setSimulationResults",
              payload: {
                tableData: response.data.items,
                simulationResultsCount: response.data.count,
                page: response.data.page,
                pageSize: response.data.pageSize,
              },
            });
            setSimulationResultsErrorMessage("");
            break;
          case 1:
            setSimulationResultsErrorMessage("Failed to retrieve simulation results. Undefined error.");
            break;
          case 2:
            setSimulationResultsErrorMessage("Failed to retrieve simulation results. Project does not exist.");
            break;
          case 3:
            setSimulationResultsErrorMessage("Failed to retrieve simulation results. You are not authorized to view results of this simulation");
            break;
          case 4:
            setSimulationResultsErrorMessage("Failed to retrieve simulation results");
            break;
          case 5:
            setSimulationResultsErrorMessage("Failed to retrieve simulation results");
            break;
          default:
            break;
        }
      } else {
        setSimulationResultsErrorMessage("Failed to retrieve simulation results");
      }
      setFilterRequestIsLoading(false);
    };

    handleGetSimulationResults({
      projectId: state.projectId,
      simulationId,
      quotaGroupId: state.selectedQuotaGroupId,
      page: state.page,
      pageSize: state.pageSize,
      orderIsDescending: state.orderIsDescending,
      sortBy: state.activeColumn,
      reachMin: state.APIrequestFilterValues.reach.min,
      reachMax: state.APIrequestFilterValues.reach.max,
      leverageabilityMin: state.APIrequestFilterValues.leverageability.min,
      leverageabilityMax: state.APIrequestFilterValues.leverageability.max,
      adjLeverageabilityMin: state.APIrequestFilterValues.adjustedLeverageability.min,
      adjLeverageabilityMax: state.APIrequestFilterValues.adjustedLeverageability.max,
      messageIds: state.APIrequestFilterValues.messageIds,
      messageCategories: state.APIrequestFilterValues.categories,
    });
  }, [
    simulationId,
    state.APIrequestFilterValues,
    state.activeColumn,
    state.orderIsDescending,
    state.selectedQuotaGroupId,
    state.page,
    state.pageSize,
    state.projectId,
    reloadSimulationResults,
  ]);

  const handlePageChange = (goToPage) => {
    setQueryParam({ paramName: "page", paramValue: goToPage });
    dispatch({ type: "changePage", payload: goToPage });
  };

  //File download
  const [unfilteredDownloadInProgress, setUnfilteredDownloadInProgress] = useState(false);
  const [filteredDownloadInProgress, setFilteredDownloadInProgress] = useState(false);
  const [unfilteredDownloadError, setUnfilteredDownloadError] = useState("");
  const [filteredDownloadError, setFilteredDownloadError] = useState("");

  const handleQueueFileDownload = async ({ applyFilters = false }) => {
    if (applyFilters) {
      setFilteredDownloadInProgress(true);
    } else {
      setUnfilteredDownloadInProgress(true);
    }
    const response = await queueFileDownload(
      state.projectId,
      simulationId,
      applyFilters,
      state.orderIsDescending,
      state.activeColumn,
      parseFloat(state.APIrequestFilterValues.reach.min),
      parseFloat(state.APIrequestFilterValues.reach.max),
      parseFloat(state.APIrequestFilterValues.leverageability.min),
      parseFloat(state.APIrequestFilterValues.leverageability.max),
      parseFloat(state.APIrequestFilterValues.adjustedLeverageability.min),
      parseFloat(state.APIrequestFilterValues.adjustedLeverageability.max),
      state.APIrequestFilterValues.messageIds,
      state.APIrequestFilterValues.categories
    );

    if (applyFilters) {
      setFilteredDownloadInProgress(false);
    } else {
      setUnfilteredDownloadInProgress(false);
    }

    if (response && response.data) {
      openDownloadPanel();
    }

    if (!response || !response.data) {
      applyFilters ? setFilteredDownloadError("Download failed") : setUnfilteredDownloadError("Download failed");
    }
  };

  useEffect(() => {
    const handleGetSimulationData = async () => {
      let response = await getSimulationData(simulationId);
      if (response.data) {
        switch (response.data.result) {
          case 0:
            dispatch({
              type: "setSimulationData",
              payload: {
                projectName: response.data.projectName,
                projectId: response.data.projectId,
                simulationName: response.data.name,
                simulationIsWeighted: response.data.isWeighted,
                simulationQuotaGroups: response.data.quotaGroups,
                simulationUserFilters: response.data.userFilters,
                messages: response.data.messages,
              },
            });
            dispatch({ type: "setSimulationMessages", payload: response.data.messages });
            dispatch({ type: "setSimulationCategories", payload: response.data.messages });
            break;
          case 1:
            setErrorMessage("Undefined error.");
            break;
          case 2:
            setErrorMessage("Error. Simulation does not exist.");
            break;
          case 3:
            setErrorMessage("You are not authorized to view data from this simulation.");
            break;
          case 4:
            setErrorMessage(`This account has been deleted. You will be redirected to the login screen in ${redirectTimeout} seconds.`);
            break;
          default:
            break;
        }
      }
    };
    handleGetSimulationData(simulationId);
  }, [simulationId]);

  useEffect(() => {
    if (!queryParams || !user || !user.currentUser || !state.simulationQuotaGroups) return;

    let filterValues = JSON.parse(JSON.stringify(initialFilterValues));
    let initialQuotaGroupId = null;
    let initialActiveFilters = [];
    let queryPage = 1;

    let paramsCount = 0;
    const queryActiveFiltersSet = new Set();
    //Number of params excluding uId and qgId
    for (let param of queryParams.entries()) {
      switch (param[0]) {
        case "page":
          queryPage = parseInt(param[1]);
          break;
        case "qgId":
          initialQuotaGroupId = parseInt(param[1]);
          break;
        case "reachMin":
          filterValues.reach.min = parseFloat(param[1]);
          queryActiveFiltersSet.add("reach");
          paramsCount++;
          break;
        case "reachMax":
          filterValues.reach.max = parseFloat(param[1]);
          queryActiveFiltersSet.add("reach");
          paramsCount++;
          break;
        case "leverageabilityMin":
          filterValues.leverageability.min = parseFloat(param[1]);
          queryActiveFiltersSet.add("leverageability");
          paramsCount++;
          break;
        case "leverageabilityMax":
          filterValues.leverageability.max = parseFloat(param[1]);
          queryActiveFiltersSet.add("leverageability");
          paramsCount++;
          break;
        case "adjustedLeverageabilityMin":
          filterValues.adjustedLeverageability.min = parseFloat(param[1]);
          queryActiveFiltersSet.add("adjustedLeverageability");
          paramsCount++;
          break;
        case "adjustedLeverageabilityMax":
          filterValues.adjustedLeverageability.max = parseFloat(param[1]);
          queryActiveFiltersSet.add("adjustedLeverageability");
          paramsCount++;
          break;
        case "messages":
          filterValues.messageIds = param[1];
          queryActiveFiltersSet.add("messages");
          paramsCount++;
          break;
        case "categories":
          filterValues.categories = param[1];
          queryActiveFiltersSet.add("categories");
          paramsCount++;
          break;
        default:
          break;
      }
    }

    if (!queryParams.has("uId")) {
      setQueryParam({ paramName: "uId", paramValue: `${user.currentUser.id}` });
    }

    if (!queryParams.has("page")) {
      setQueryParam({ paramName: "page", paramValue: 1 });
    }

    //This will pull state from the address bar
    if (paramsCount > 0) {
      if (queryParams.get("uId") === user.currentUser.id) {
        saveFiltersToDb({
          simulationId,
          reachMin: parseFloat(filterValues.reach.min),
          reachMax: parseFloat(filterValues.reach.max),
          leverageabilityMin: parseFloat(filterValues.leverageability.min),
          leverageabilityMax: parseFloat(filterValues.leverageability.max),
          adjustedleverageabilityMin: parseFloat(filterValues.adjustedLeverageability.min),
          adjustedleverageabilityMax: parseFloat(filterValues.adjustedLeverageability.max),
          messages: filterValues.messageIds,
          categories: filterValues.categories,
        });
      }
      initialActiveFilters = Array.from(queryActiveFiltersSet);
    }

    //This will pull state from the database
    if (paramsCount === 0) {
      if (!queryParams.has("qgId")) {
        setQueryParam({ paramName: "qgId", paramValue: state.simulationQuotaGroups[0].quotaGroupId });
        initialQuotaGroupId = state.simulationQuotaGroups[0].quotaGroupId;
      }

      if (!state.simulationUserFilters) {
        initialQuotaGroupId = parseInt(queryParams.get("qgId"));
      } else {
        const dbActiveFiltersSet = new Set();
        const simulationUserFilters = { ...state.simulationUserFilters };

        for (let filter in state.simulationUserFilters) {
          const filterValue = state.simulationUserFilters[filter];
          switch (filter) {
            case "reachMin":
              if (filterValue !== 0) {
                dbActiveFiltersSet.add("reach");
                setQueryParam({ paramName: "reachMin", paramValue: filterValue });
              }
              break;
            case "reachMax":
              if (filterValue !== 100) {
                dbActiveFiltersSet.add("reach");
                setQueryParam({ paramName: "reachMax", paramValue: filterValue });
              }
              break;
            case "leverageabilityMin":
              if (filterValue !== 0) {
                dbActiveFiltersSet.add("leverageability");
                setQueryParam({ paramName: "leverageabilityMin", paramValue: filterValue });
              }
              break;
            case "leverageabilityMax":
              if (filterValue !== 1000) {
                dbActiveFiltersSet.add("leverageability");
                setQueryParam({ paramName: "leverageabilityMax", paramValue: filterValue });
              }
              break;
            case "adjustedLeverageabilityMin":
              if (filterValue !== 0) {
                dbActiveFiltersSet.add("adjustedLeverageability");
                setQueryParam({ paramName: "adjustedLeverageabilityMin", paramValue: filterValue });
              }
              break;
            case "adjustedLeverageabilityMax":
              if (filterValue !== 1000) {
                dbActiveFiltersSet.add("adjustedLeverageability");
                setQueryParam({ paramName: "adjustedLeverageabilityMax", paramValue: filterValue });
              }
              break;
            case "messages":
              if (filterValue) {
                dbActiveFiltersSet.add("messages");
                setQueryParam({ paramName: "messages", paramValue: filterValue });
              }
              break;
            case "categories":
              if (filterValue) {
                dbActiveFiltersSet.add("categories");
                setQueryParam({ paramName: "categories", paramValue: filterValue });
              }
              break;
            default:
              break;
          }
        }

        filterValues = {
          reach: { min: simulationUserFilters.reachMin, max: simulationUserFilters.reachMax },
          leverageability: { min: simulationUserFilters.leverageabilityMin, max: simulationUserFilters.leverageabilityMax },
          adjustedLeverageability: { min: simulationUserFilters.adjustedLeverageabilityMin, max: simulationUserFilters.adjustedLeverageabilityMax },
          messageIds: simulationUserFilters.messages ? simulationUserFilters.messages : "",
          categories: simulationUserFilters.categories ? simulationUserFilters.categories : "",
        };

        initialActiveFilters = Array.from(dbActiveFiltersSet);
        initialQuotaGroupId = state.simulationQuotaGroups[0].quotaGroupId;
      }
    }

    dispatch({ type: "selectQuotaGroup", payload: { quotaGroupId: initialQuotaGroupId, page: queryPage } });
    setQueryParam({ paramName: "qgId", paramValue: initialQuotaGroupId });
    dispatch({ type: "setActiveFilters", payload: initialActiveFilters });
    dispatch({ type: "applyFilters", payload: { APIrequestFilterValues: filterValues, page: queryPage } });
  }, [queryParams, setQueryParam, simulationId, state.simulationUserFilters, state.simulationQuotaGroups, user]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setFilteredDownloadError("");
      setUnfilteredDownloadError("");
    }, 10000);
    return () => {
      clearTimeout(timeout);
    };
  }, [filteredDownloadError, unfilteredDownloadError]);

  const tabContainerClasses = classNames(filterRequestIsLoading ? "opacity-overlay" : "");

  return (
    <>
      <Helmet title={`${state.simulationName}`} />
      <Container fluid>
        <Row>
          <Col sm={4}></Col>
          <Col sm={8}>
            <div className="page-title-box">
              <div className="float-right">
                <ol className="breadcrumb">
                  {state.projectId ? (
                    <>
                      <li className="breadcrumb-item active">
                        <CustomLink href={ROUTES.projects}>Projects</CustomLink>
                      </li>
                      <li className="breadcrumb-item active">
                        <Link to={{ pathname: ROUTES.singleProject(state.projectId), state: { projectId: state.projectId } }}>{state.projectName} </Link>
                      </li>
                      <li>Simulation Results</li>
                    </>
                  ) : (
                    <Spinner animation="border" variant="primary" size="sm" />
                  )}
                </ol>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Row>
                  {state.simulationName ? (
                    <>
                      <Col sm={4}>
                        <div className="header-col left">
                          <div>
                            <div className="avatar-with-name">
                              <Avatar name={state.simulationName} size="lg"></Avatar>
                              <div className="name">
                                <h4 className="page-title">{state.simulationName}</h4>
                                <Link className="link" to={{ pathname: ROUTES.singleProject(state.projectId) }}>
                                  (Back To Project)
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col sm={4}>
                        <div className="header-col left">
                          <div>
                            <p className="result-header-label">
                              {state.simulationQuotaGroups && (
                                <>
                                  {state.simulationQuotaGroups.length} quota group{state.simulationQuotaGroups.length > 1 && "s"} selected
                                </>
                              )}
                            </p>
                            <p className="result-header-label">{state.simulationIsWeighted ? "Weighted" : "Unweighted"} results</p>
                          </div>
                        </div>
                      </Col>

                      <Col sm={4} className=" text-right ">
                        <div className="header-col">
                          <div>
                            <ErrorText text={unfilteredDownloadError} className="mr-3" />
                            <Button onClick={() => handleQueueFileDownload({ applyFilters: false })}>
                              {unfilteredDownloadInProgress ? <Spinner animation="border" size="sm" variant="primary" /> : "Download Results"}
                            </Button>
                            <ErrorText text={errorMessage} className="mb-0 mr-3" />
                          </div>
                        </div>
                      </Col>
                    </>
                  ) : (
                    <Spinner animation="border" variant="primary" />
                  )}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm={8}>
            <Row>
              <Col>
                {state.simulationQuotaGroups && state.simulationQuotaGroups.length > 0 && state.selectedQuotaGroupId ? (
                  <Card style={{ minHeight: "75vh" }} className="p-1">
                    <Card.Body>
                      <Row>
                        <Col sm={12} className={tabContainerClasses}>
                          <Tab.Container defaultActiveKey={state.selectedQuotaGroupId}>
                            <Row className="mb-3">
                              <Col className="my-2" sm={9}>
                                <Button onClick={() => handleQueueFileDownload({ applyFilters: true })}>
                                  {filteredDownloadInProgress ? <Spinner animation="border" size="sm" variant="primary" /> : "Download Filtered Results"}
                                </Button>
                                <ErrorText text={filteredDownloadError} className="ml-3" />
                              </Col>

                              <Col sm={3} className="text-right">
                                <Dropdown className="my-2">
                                  <Dropdown.Toggle isAddFilter as={CustomToggle}>
                                    Add filter
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu
                                    as={CustomMenu}
                                    className="d-flex flex-column align-items-center justify-content-center"
                                    listClassName="select-filter__list"
                                    backgroundClassName="select-filter__background"
                                  >
                                    <li>
                                      <span>Select the filters you want:</span>
                                    </li>
                                    <li>
                                      <CustomCheckboxv2
                                        label="Reach"
                                        checked={state.activeFilters.includes("reach")}
                                        onChange={handleFilterSelect}
                                        value="reach"
                                        id="reach"
                                      />
                                    </li>
                                    <li>
                                      <CustomCheckboxv2
                                        label="Leverageability"
                                        checked={state.activeFilters.includes("leverageability")}
                                        onChange={handleFilterSelect}
                                        value="leverageability"
                                        id="leverageability"
                                      />
                                    </li>
                                    <li>
                                      <CustomCheckboxv2
                                        label="Adj. Leverageability"
                                        checked={state.activeFilters.includes("adjustedLeverageability")}
                                        onChange={handleFilterSelect}
                                        value="adjustedLeverageability"
                                        id="adjLeverageability"
                                      />
                                    </li>
                                    <li>
                                      <CustomCheckboxv2
                                        label="Messages"
                                        checked={state.activeFilters.includes("messages")}
                                        onChange={handleFilterSelect}
                                        value="messages"
                                        id="messages"
                                      />
                                    </li>
                                    <li>
                                      <CustomCheckboxv2
                                        label="Category"
                                        checked={state.activeFilters.includes("categories")}
                                        onChange={handleFilterSelect}
                                        value="categories"
                                        id="categories"
                                      />
                                    </li>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </Col>
                            </Row>
                            <Row>
                              <Col offset={3} sm={12} className="d-flex">
                                {state.activeFilters && (
                                  <ActiveFilters
                                    activeFilters={state.activeFilters}
                                    simulationMessages={state.simulationMessages}
                                    simulationCategories={state.simulationCategories}
                                    requestFilterValues={state.APIrequestFilterValues}
                                    onApply={handleApplyFilters}
                                    onFilterClose={handleFilterClose}
                                    className="d-flex filters-list flex-wrap"
                                    handleClearAllFilters={handleClearAllFilters}
                                  />
                                )}
                              </Col>
                            </Row>

                            <Row>
                              <Col sm={3}>
                                <div>
                                  <Nav variant="tabs" className="flex-column">
                                    {state.simulationQuotaGroups.length > 0 &&
                                      state.simulationQuotaGroups.map((quotaGroup) => {
                                        return (
                                          <Nav.Item key={quotaGroup.quotaGroupId} className="word-wrap">
                                            <Nav.Link
                                              eventKey={quotaGroup.quotaGroupId}
                                              onClick={() => {
                                                handleSelectQuotaGroupId({ quotaGroupId: quotaGroup.quotaGroupId });
                                              }}
                                            >
                                              {quotaGroup.name}
                                            </Nav.Link>
                                          </Nav.Item>
                                        );
                                      })}
                                  </Nav>
                                </div>
                              </Col>
                              <Col sm={9}>
                                <Tab.Content className="h-100 d-flex">
                                  {!simulationResultsErrorMessage &&
                                    state.simulationQuotaGroups.length > 0 &&
                                    state.simulationQuotaGroups.map((quotaGroup) => {
                                      return (
                                        <Tab.Pane className="flex-grow-1" key={quotaGroup.quotaGroupId} eventKey={quotaGroup.quotaGroupId}>
                                          <div onMouseLeave={handleMouseLeaveTable}>
                                            <SimulationResultsTable
                                              data={state.tableData}
                                              page={state.page}
                                              pageSize={state.pageSize}
                                              handleSelectSortingOrder={handleSelectSortingOrder}
                                              activeColumn={state.activeColumn}
                                              orderIsDescending={state.orderIsDescending}
                                              onHover={handleHoverOverRow}
                                            />
                                            {!state.tableData && (
                                              <div className="text-center">
                                                <Spinner size="lg" animation="border" variant="primary" />
                                              </div>
                                            )}
                                          </div>
                                          <div className="d-flex justify-content-between">
                                            <CustomEntriesDisplay totalEntries={state.simulationResultsCount} page={state.page} pageSize={state.pageSize} />
                                            <TablePagination
                                              currentPage={state.page}
                                              pageSize={state.pageSize}
                                              onChange={handlePageChange}
                                              totalEntries={state.simulationResultsCount}
                                            />
                                          </div>
                                        </Tab.Pane>
                                      );
                                    })}
                                  {simulationResultsErrorMessage && (
                                    <div className="d-flex flex-column justify-content-center align-items-center flex-grow-1">
                                      <ErrorText text={simulationResultsErrorMessage} />
                                      <Button className="mt-3" variant="outline-primary" onClick={handleRetryLoadingSimulationResults}>
                                        Retry
                                      </Button>
                                    </div>
                                  )}
                                </Tab.Content>
                              </Col>
                            </Row>
                          </Tab.Container>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                ) : (
                  <div className="d-flex justify-content-center">
                    <Spinner animation="border" variant="primary" />
                  </div>
                )}
              </Col>
            </Row>
          </Col>

          <Col sm={4} className="scrollable max-height-75">
            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <Row>
                      <Col>
                        <Card>
                          <Card.Body>
                            <Row>
                              <Col className="d-flex justify-content-start">
                                <h5 className="float-left mb-3">
                                  {hoveredRowNumber !== "" ? `Messages in result row ${hoveredRowNumber}` : "Messages in this simulation"}
                                </h5>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                {state.sidePanelMessages && (
                                  <ProjectMessagesTable messages={state.sidePanelMessages} showCategory isLoading={simulationResultsAreLoading} />
                                )}
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ openDownloadPanel }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Simulation);
