import React from "react";
import classNames from "classnames";

const ToggleChartResults = ({ showWeightedResults, handleToggleResults }) => {
  const activeClassesString = classNames("form-check", "nav-link", "active");
  const inactiveClassesString = classNames("form-check", "nav-link");

  return (
    <ul className="nav">
      <li className="nav-item">
        <div className={showWeightedResults ? activeClassesString : inactiveClassesString}>
          <label className="mb-0" onClick={() => handleToggleResults(true)}>
            Weighted
          </label>
        </div>
      </li>
      <li className="nav-item">
        <div className={!showWeightedResults ? activeClassesString : inactiveClassesString}>
          <label className="mb-0" onClick={() => handleToggleResults(false)}>
            Unweighted
          </label>
        </div>
      </li>
    </ul>
  );
};

export default ToggleChartResults;
