import React from "react";

const CustomEntriesSelect = ({ options = [10, 25, 50, 100], onChange }) => {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <div className="entries-selector">
      <label className="entries-selector__label">
        Show
        <select className="entries-selector__select custom-select custom-select-sm form-control form-control-sm mx-1" onChange={handleChange}>
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </label>
      entries
    </div>
  );
};

export default CustomEntriesSelect;
