import axios from "axios";
import { getCookie } from "utility/cookie";
import { authCookieName } from "constants/general";

const getBaseUrl = () => {
  return axios.create({
    baseURL: window.location.origin + "/api/users",
    headers: { Authorization: "Bearer " + getCookie(authCookieName) },
  });
};

export const getUsers = (page, pageSize) => {
  return getBaseUrl()
    .get("/getUsers", {
      params: { page, pageSize },
    })
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};

export const changeUserApprovalStatus = (email) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return getBaseUrl()
    .post("/changeUserApprovalStatus", `"${email}"`, { headers })
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};

export const addUser = (userData) => {
  return getBaseUrl()
    .post("/addUser", userData)
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};

export const editUser = (userData) => {
  return getBaseUrl()
    .post("/editUser", userData)
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};

export const getUserForEdit = (userId) => {
  return getBaseUrl()
    .get("/GetUserForEdit", { params: { userId } })
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};

export const deleteUser = ({ userId }) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return getBaseUrl()
    .post("/deleteUser", `"${userId}"`, { headers })
    .catch((error) => {
      return {
        error: true,
        errorMessage: error,
      };
    });
};
