import React from "react";
/**
 * Custom hook for context usage with simple error handler. This is primarily used for compound components such as <Tabs> or <Wizard> which share their context across their multiple sub-components which can only be rendered inside of their parent component.
 * @param {Object} param0
 * @param {React.Context} param0.contextInstance - Instance of React.Context
 * @param {String} param0.componentName - Component name used in error messages
 */
const useComponentContext = ({ contextInstance, componentName }) => {
  const context = React.useContext(contextInstance);

  if (!context) {
    throw new Error(`${componentName} compound components cannot be rendered outside the <${componentName}> component`);
  }

  return context;
};

export default useComponentContext;
