import React from "react";
import { Helmet } from "react-helmet";
import { Route, Redirect } from "react-router";
import ROUTES from "constants/routes";

const ProtectedLogin = ({ userIsLoggedIn, userIsApproved, component: Component, documentTitle, ...rest }) => {
  return (
    <Route
      {...rest}
      render={() => {
        if (userIsLoggedIn) {
          switch (userIsApproved) {
            case true:
              return <Redirect to={ROUTES.index} />;
            case false:
              return <Redirect to={ROUTES.notApproved} />;
            default:
              break;
          }
        }
        return (
          <>
            <Helmet title={documentTitle} />
            <Component />
          </>
        );
      }}
    />
  );
};

export default ProtectedLogin;
