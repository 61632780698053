import { KEY_CODES } from "../constants/general";

export const a11yKeyboardClick = (event, handler, { spacebarClick = false }) => {
  const pressedKey = event.keyCode;
  if (pressedKey === KEY_CODES.enterKey || (spacebarClick && pressedKey === KEY_CODES.spacebar)) {
    event.preventDefault();
    // This prevents the whole page from scrolling when you press Spacebar, which is what regular buttons do in HTML and that's desired behavior for elements you can press with Spacebar
    // Be sure to add call this method in onKeyDown event, not onKeyUp because otherwise it won't work
    handler(event);
  }
};
