import React from "react";
import { CustomLink } from "components/CustomLink";
import ROUTES from "constants/routes";
import { Link } from "react-router-dom";
import { deleteCookie } from "utility/cookie";
import logo from "assets/img/logo-light.png";
import { connect } from "react-redux";

function MainNavigation({ user }) {
  const handleLogout = () => {
    deleteCookie("AUTH_COOKIE");
    window.location.reload();
  };

  return (
    <div className="left-sidenav">
      <div className="left-sidenav__logo">
        <img className="left-sidenav__logo-image" src={logo} alt="logo" />
      </div>
      <ul className="metismenu left-sidenav-menu">
        <li>
          <CustomLink href={ROUTES.projects}>
            <i className="ti-clipboard"></i>
            <span>Projects</span>
          </CustomLink>
        </li>
        {user && user.currentUser && user.currentUser.userType === 0 && (
          <li>
            <CustomLink href={ROUTES.users}>
              <i className="ti-user"></i>
              <span>Users</span>
            </CustomLink>
          </li>
        )}
        <li>
          <CustomLink href={ROUTES.account}>
            <i className="ti-lock"></i>
            <span>Account</span>
          </CustomLink>
        </li>
        <li>
          <CustomLink href="https://compelsupport.borealis.biz/">
            <i className="ti-help-alt"></i>
            <span>Support</span>
          </CustomLink>
        </li>
        <li>
          <Link to={ROUTES.login} onClick={handleLogout}>
            <i className="ti-power-off"></i>
            <span>Log out</span>
          </Link>
        </li>
      </ul>
    </div>
  );
}

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps)(MainNavigation);
