import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { CustomLink } from "components/CustomLink";
import { Form, FormGroup, Button, FormControl, Spinner } from "react-bootstrap";
import { userResetPassword, resetResponse } from "actions/userAuthenticationActions";
import ROUTES from "constants/routes";
import { useLocation } from "react-router-dom";
import mainLogo from "assets/img/logo.png";

const ResetPassword = ({ user, userResetPassword }) => {
  const [firstValidation, setFirstValidation] = useState(false);

  const [formData, setFormData] = useState({
    password: "",
    repeatPassword: "",
  });

  const [passwordIsValid, setPasswordIsValid] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(false);

  const [resetPasswordData, setResetPasswordData] = useState({
    newPassword: "",
    resetToken: "",
    email: "",
  });

  const [formIsValid, setFormIsValid] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevState) => {
      return { ...prevState, [name]: value };
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setFirstValidation(true);
    //Submit form to backend
    if (formIsValid) userResetPassword(resetPasswordData);
  };

  const location = useLocation();

  //Resets previous server response to inital value
  useEffect(() => {
    resetResponse();
  }, []);

  //Parse query params for resetToken and email
  useEffect(() => {
    let queryParams = location.search.split("&");
    const resetToken = queryParams[0].split("=")[1];
    const email = queryParams[1].split("=")[1];
    setResetPasswordData((prevState) => ({
      ...prevState,
      resetToken,
      email,
    }));
  }, [location]);

  //Password length check & form validation
  useEffect(() => {
    if (formData.password.length < 5) {
      setPasswordIsValid(false);
    } else if (formData.password.length >= 5) {
      setPasswordIsValid(true);
    }

    if (formData.password !== formData.repeatPassword) {
      setPasswordsMatch(false);
    } else if (formData.password === formData.repeatPassword) {
      setPasswordsMatch(true);
    }

    if (passwordIsValid && passwordsMatch) {
      setFormIsValid(true);
    }

    if (formIsValid) {
      setResetPasswordData((prevState) => ({
        ...prevState,
        newPassword: formData.password,
      }));
    }
  }, [formData.password, formData.repeatPassword, passwordIsValid, passwordsMatch, formIsValid]);

  return (
    <>
      <div className="account-body">
        <div className="row vh-100">
          <div className="col-12 align-self-center">
            <div className="auth-page">
              <h1 className="text-center">
                <img src={mainLogo} alt="Compel logo" />
              </h1>
              <div className="text-center auth-logo-text">
                <h4 className="mb-3 mt-5">Password reset</h4>
              </div>
              <div className="card auth-card shadow-lg">
                <div className="card-body">
                  <div className="px-3">
                    <Form noValidate onSubmit={handleSubmit} className="form-horizontal auth-form my-4">
                      <Form.Group>
                        <Form.Label>New password</Form.Label>
                        <FormControl
                          type="password"
                          name="password"
                          onChange={handleInputChange}
                          className={firstValidation && passwordIsValid && "is-valid"}
                          value={formData.password}
                          isInvalid={firstValidation && !passwordIsValid}
                        ></FormControl>
                        <Form.Control.Feedback type="invalid">
                          {firstValidation && !passwordIsValid && "Password must be at least 5 characters long"}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group>
                        <Form.Label>Repeat new password</Form.Label>
                        <FormControl
                          type="password"
                          name="repeatPassword"
                          onChange={handleInputChange}
                          className={firstValidation && passwordsMatch && "is-valid"}
                          value={formData.repeatPassword}
                          isInvalid={(firstValidation && !passwordsMatch) || (firstValidation && !passwordIsValid)}
                        ></FormControl>
                        <Form.Control.Feedback type="invalid">
                          {firstValidation && !passwordsMatch
                            ? "Passwords do not match"
                            : !passwordIsValid
                            ? "Password must be at least 5 characters long"
                            : ""}
                        </Form.Control.Feedback>
                      </Form.Group>
                      {user.response.result && <p className="text-success text-center">{user.response.message}</p>}
                      {!user.response.result && <p className="text-danger text-center">{user.response.message}</p>}
                      <FormGroup className="mb-0">
                        <div className="col-12 mt-2">
                          <Button type="submit" className="btn btn-primary btn-round btn-block waves-effect waves-light">
                            {user.authenticationInProgress ? <Spinner as="div" animation="border" variant="light" /> : "Reset password"}
                          </Button>
                        </div>
                      </FormGroup>
                    </Form>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-sm-12 text-center">
                  <CustomLink href={ROUTES.register} className="text-primary font-13">
                    Don't have an account? Press here to register
                  </CustomLink>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-sm-12 text-center">
                  <CustomLink href={ROUTES.login} className="text-primary font-13">
                    Already have an account? Press here to sign in
                  </CustomLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ userResetPassword, resetResponse }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
