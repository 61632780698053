import React from "react";
import { Table } from "react-bootstrap";
import classNames from "classnames";

const ProjectMessagesTable = ({ messages, showCategory, isLoading }) => {
  const tableClassesString = classNames("mb-0", "table-centered", isLoading ? "opacity-65" : "");
  return (
    <>
      <Table responsive className={tableClassesString}>
        <tbody>
          {messages.map((message) => (
            <tr key={message.messageNumber}>
              <td>{message.messageNumber}</td>
              <td>{message.text}</td>
              {showCategory && <td>{message.category}</td>}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default ProjectMessagesTable;
