import React from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";

import { isUrlExternal } from "utility/url";

/**
 * Custom link component. Use this anywhere you need an anchor (<a></a>) element. This ensures that internal links will use React Router and other links will behave as regular links.
 */
const CustomLink = ({ href, children, className, ...otherProps }) => {
  const isLinkExternal = isUrlExternal(href);
  const classesString = classnames("link", className);

  if (!isLinkExternal) {
    return (
      <Link className={classesString} to={href} {...otherProps}>
        {children}
      </Link>
    );
  } else {
    return (
      <a className={classesString} href={href} {...otherProps}>
        {children}
      </a>
    );
  }
};

export default CustomLink;
