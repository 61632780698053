import React from "react";
import { Button } from "react-bootstrap";
import { deleteCookie } from "utility/cookie";

const NotApproved = () => {
  const handleLogout = () => {
    deleteCookie("AUTH_COOKIE");
    window.location.reload();
  };
  return (
    <div className="text-center ">
      <h1>Thank you</h1>
      <p>
        You have successfully applied for an account. Your information has been sent to a system administrator. You will be able to login after an administrator
        approves your account.
      </p>
      <Button onClick={handleLogout}>Logout</Button>
    </div>
  );
};

export default NotApproved;
