import React, { useState } from "react";
import { Table, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import { CustomButton } from "components/Button";
import { TableHeaderCell } from "components/Tables";
import { changeRoute } from "utility/routing/";
import { formatDateMDY } from "utility/date";
import ROUTES from "constants/routes";
import { ConfirmationModal } from "components/Modals";
import { Avatar } from "components/Avatar";
import classNames from "classnames";
import ProjectAuthorizationModal from "components/Modals/ProjectAuthorizationModal";

const ProjectsTableRow = ({
  projectId,
  projectName,
  projectStatus,
  remainingProcessingTime,
  projectOwner,
  createdAt,
  lastUpdateAt,
  accessLevel,
  hasAccess,
  handleDeleteProject,
  onModalOpen,
  onModalClose,
  percentDone,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showAuthorizationModal, setShowAuthorizationModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteSuccessful, setIsDeleteSuccessful] = useState(false);

  const projectAuthorizeOnSuccess = () => {
    // setShowAuthorizationModal(false);
    changeRoute(ROUTES.singleProject(projectId), { projectId: projectId });
  };

  const handleModalClose = (getProjects) => {
    onModalClose(setShowModal, setErrorMessage, getProjects);
  };

  const handleProjectNameLinkAuthorization = (event) => {
    event.preventDefault();
    accessLevel && !hasAccess ? setShowAuthorizationModal(true) : changeRoute(ROUTES.singleProject(projectId), { projectId: projectId });
  };

  return (
    <tr key={projectId}>
      <td>
        <Avatar name={projectName}></Avatar>

        <Link
          onClick={handleProjectNameLinkAuthorization}
          to={{ pathname: ROUTES.singleProject(projectId), state: { projectId: projectId } }}
          className={projectStatus === 0 || projectStatus === 6 || projectStatus === 8 ? "disabled-link" : ""}
        >
          {projectName}
          {projectStatus === 100 && <span className="text-danger"> (Closed)</span>}
        </Link>
      </td>
      <td>{projectOwner}</td>
      <td>{formatDateMDY(createdAt)}</td>
      <td>{formatDateMDY(lastUpdateAt)}</td>
      <td className="mt-3">{accessLevel ? `Restricted ${hasAccess ? "(Authorized)" : ""}` : "Open"}</td>
      <td className="d-flex justify-content-between">
        <div>
          {projectStatus === 0 || projectStatus === 6 ? (
            <div className="d-flex flex-column align-items-center w-100">
              <div className="progress mt-3" style={{ width: "100%" }}>
                <div
                  className="progress-bar progress-bar-striped progress-bar-animated "
                  role="progressbar"
                  style={{ width: (percentDone * 100).toFixed(2) + "%" }}
                >
                  {(percentDone * 100).toFixed(2)}%
                </div>
              </div>
              <span className="progress-info">The data set for this project is still being generated </span>
            </div>
          ) : (
            <>
              <CustomButton
                className="btn btn-secondary mt-2"
                onClick={() =>
                  accessLevel && !hasAccess ? setShowAuthorizationModal(true) : changeRoute(ROUTES.singleProject(projectId), { projectId: projectId })
                }
              >
                <i className="fas fa-edit mr-2"></i>
                View
              </CustomButton>

              <CustomButton className="btn btn-danger mt-2 ml-2" onClick={() => onModalOpen(setShowModal)}>
                <i className="fas fa-trash-alt mr-2"></i>
                Delete
              </CustomButton>
            </>
          )}
        </div>
      </td>
      <ConfirmationModal
        showModal={showModal}
        isLoading={isLoading}
        errorMessage={errorMessage}
        onCancel={handleModalClose}
        onConfirm={() => handleDeleteProject(projectId, setIsLoading, setIsDeleteSuccessful, setErrorMessage)}
        success={isDeleteSuccessful}
        confirmationMessage="Yes, delete it!"
        questionMessage="Are you sure?"
        warningMessage="You won't be able to revert this!"
        errorTitle="Error"
        successTitle="Deleted!"
        successSubtitle="Project successfully queued for deletion"
      />
      {showAuthorizationModal && (
        <ProjectAuthorizationModal
          isOpen={showAuthorizationModal}
          setIsOpen={setShowAuthorizationModal}
          projectId={projectId}
          onSuccess={projectAuthorizeOnSuccess}
          projectName={projectName}
        ></ProjectAuthorizationModal>
      )}
    </tr>
  );
};

const ProjectsTable = ({
  projects,
  activeColumn,
  orderIsDescending,
  handleSelectSortingOrder,
  handleDeleteProject,
  onModalOpen,
  onModalClose,
  tableIsLoading,
  hasAccess,
  user,
}) => {
  const headers = ["Name", "Owner", "Created", "Last Edited", "Acccess Level", ""];

  const tableClassesString = classNames("table", tableIsLoading ? "opacity-65" : "");

  return (
    <>
      <Table responsive className={tableClassesString}>
        <thead className="thead-light">
          <tr>
            {headers.map((header, index) => {
              //Last column
              const isLastColumn = index + 1 === headers.length;
              return (
                <TableHeaderCell
                  key={header}
                  title={header}
                  currentColumn={index}
                  activeColumn={activeColumn}
                  orderIsDescending={orderIsDescending}
                  handleSelectSortingOrder={isLastColumn ? null : handleSelectSortingOrder}
                  isLastColumn={isLastColumn}
                />
              );
            })}
          </tr>
        </thead>
        <tbody>
          {projects.map((project) => (
            <ProjectsTableRow
              key={project.projectId}
              projectId={project.projectId}
              projectName={project.name}
              projectStatus={project.status}
              remainingProcessingTime={project.remainingProcessingTime}
              percentDone={project.percentDone}
              projectOwner={project.projectOwner}
              createdAt={project.createdAt}
              lastUpdateAt={project.lastUpdateAt}
              accessLevel={project.isAccessRestricted}
              hasAccess={project.currentUserHasAccess}
              handleDeleteProject={handleDeleteProject}
              onModalOpen={onModalOpen}
              onModalClose={onModalClose}
            />
          ))}
        </tbody>
      </Table>
      {tableIsLoading && (
        <div className="text-center">
          <Spinner size="lg" animation="border" variant="primary" />
        </div>
      )}
    </>
  );
};

export default ProjectsTable;
