import React, { useState, useEffect } from "react";
import { Row, Col, Button, ProgressBar, Spinner } from "react-bootstrap";
import { BrowseFilesButton, DeleteButton } from "components/Button";
import { FileUploadErrors } from "components/Wizards";
import { uploadLeverageability } from "apis/projectApi";
import { ErrorText, SuccessText } from "components/Text";

const LeverageabilityUploadRow = ({
  draftId,
  quotaGroup,
  changeLeverageabilityValidation,
  handleLeverageabilityDelete,
  handleQuotaGroupsData,
  handleGetLeverageabilityData,
  getLeverageabilityDataErrorMessage,
  leverageabilityDataIsLoading,
  handleDropzoneErrorMessages,
}) => {
  const redirectTimeout = 5000;
  const [leverageabilityFileUploaded, setLeverageabilityFileUploaded] = useState(quotaGroup.leverageabilityUploaded);
  const [fileName, setFileName] = useState("");
  const [fileSize, setFileSize] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState("");

  const handleLeverageabilityUpload = async (event) => {
    //Clears dropzone error messages
    handleDropzoneErrorMessages({});

    let file = event.target.files[0];
    handleFileInfo(file.name, file.size);

    let xlsRegex = new RegExp(/^[^.]+.xls$/);
    let xlsxRegex = new RegExp(/^[^.]+.xlsx$/);

    if (!xlsRegex.test(file.name) && !xlsxRegex.test(file.name)) {
      setErrorMessage("Upload failed. File must be an Excel spreadsheet.");
      //Toggles "disabled" status on the "Next" button
      changeLeverageabilityValidation(false);
      return;
    }

    if (file.size >= 20000000) {
      setErrorMessage("Upload failed. Max. file size is 20 MB.");
      //Toggles "disabled" status on the "Next" button
      changeLeverageabilityValidation(false);
      return;
    }

    let uploadLeverageabilityResult = await uploadLeverageability(draftId, quotaGroup.quotaGroupId, file);
    if (uploadLeverageabilityResult.data) {
      switch (uploadLeverageabilityResult.data.result) {
        case 0:
          setLeverageabilityFileUploaded(true);
          handleQuotaGroupsData(uploadLeverageabilityResult.data.quotaGroups);
          return;
        case 1:
          setErrorMessage("Undefined error");
          break;
        case 2:
          setErrorMessage("You are not authorized to upload a file to this draft.");
          break;
        case 3:
          setErrorMessage(`This account has been deleted. You will be redirected to the login screen in ${redirectTimeout} seconds`);
          break;
        case 4:
          setErrorMessage("The draft you are trying to upload files to does not exist.");
          break;
        case 5:
          setErrorMessage("File not found error.");
          break;
        case 6:
          setErrorMessage("File upload failed.");
          break;
        case 7:
          setErrorMessage(`Too many files uploaded. When using bulk upload, max. number of uploaded files has to be the same as number of quota groups.`);
          break;
        //Data validation errors
        case 8:
          const rowQuotaGroup = uploadLeverageabilityResult.data.quotaGroups.find(
            (responseQuotaGroup) => responseQuotaGroup.quotaGroupId === quotaGroup.quotaGroupId
          );
          const errorCellAddress = rowQuotaGroup.errorCellAddress;
          const errorValue = rowQuotaGroup.errorValue;

          switch (rowQuotaGroup.result) {
            case 1:
              setErrorMessage("Undefined error");
              break;
            case 2:
              setErrorMessage("File you are trying to upload is too large.");
              break;
            case 3:
              setErrorMessage("Quota group in cell A1 does not exist in this project.");
              break;
            case 4:
              setErrorMessage(`Quota group label at cell ${errorCellAddress} is empty`);
              break;
            case 5:
              setErrorMessage(`Quota group label format error at cell ${errorCellAddress}. Values in cell A1 have to correspond to quota group id numbers.`);
              break;
            case 6:
              setErrorMessage(`Message number at cell ${errorCellAddress} is empty`);
              break;
            case 7:
              setErrorMessage(
                `Message number format error at cell ${errorCellAddress}. The value that triggered the error is: ${errorValue}. Message numbers should be numerics only.`
              );
              break;
            case 8:
              setErrorMessage(
                `Message numbers ordering error at cell ${errorCellAddress}. The value that triggered the error is: ${errorValue}. Messages should be ordered in ascending order.`
              );
              break;
            case 9:
              setErrorMessage(`Message number at cell ${errorCellAddress} is empty`);
              break;
            case 10:
              setErrorMessage(
                `Message number format error at cell ${errorCellAddress}. The value that triggered the error is: ${errorValue}. Message numbers should be numerics only.`
              );
              break;
            case 11:
              setErrorMessage(
                `Message numbers ordering error at cell ${errorCellAddress}. The value that triggered the error is: ${errorValue}. Messages should be ordered in ascending order.`
              );
              break;
            case 12:
              setErrorMessage(`Message value at cell ${errorCellAddress} is empty`);
              break;
            case 13:
              setErrorMessage(
                `Message value format error at cell ${errorCellAddress}. The value that triggered the error is: ${errorValue}.  Message value should be numerics only.`
              );
              break;
            case 14:
              setErrorMessage(`Correlation coefficient at cell ${errorCellAddress} is empty.`);
              break;
            case 15:
              setErrorMessage(
                `Correlation coefficient format error at cell ${errorCellAddress}. The value that triggered the error is: ${errorValue}. Correlation coefficients should be numerics only.`
              );
              break;
            case 16:
              setErrorMessage(`Unexpected correlation coefficient at cell ${errorCellAddress}. The value that triggered the error is: ${errorValue}.`);
              break;
            case 17:
              setErrorMessage(`Upload failed. Multiple files uploaded for the same quota group.`);
              break;
            case 18:
              setErrorMessage(`Upload failed. File type not allowed.`);
              break;
            case 19:
              setErrorMessage(
                `Wrong file type. File worksheet name is supposed to be called "Leveragability Upload". Uploaded worksheet name: "${errorValue}"`
              );
              break;
            case 20:
              setErrorMessage(`Upload failed. Label quota group at cell A1 does not match row quota group". Uploaded quota group number: "${errorValue}"`);
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    }
  };

  const handleFileInfo = (fileName, fileSize) => {
    setFileName(fileName);
    if (fileSize >= 1000000) {
      setFileSize(`${Math.ceil(fileSize / 1000000).toFixed(2)} MB`);
    } else {
      setFileSize(`${Math.ceil(fileSize / 1000)} KB`);
    }
  };

  useEffect(() => {
    quotaGroup.leverageabilityUploaded ? setLeverageabilityFileUploaded(true) : setLeverageabilityFileUploaded(false);
    //Whenever quotaGroup data changes -> reset error message
    setErrorMessage("");
  }, [quotaGroup]);

  return (
    <tr>
      <td>
        {quotaGroup.name}
        <br></br>
        <span className="text-danger">{getLeverageabilityDataErrorMessage}</span>
      </td>
      {/* TODO: Remove inline styles when Lieberman feedback changes are implemented*/}
      <td style={{ maxWidth: 200 }}>
        <div className="text-center">
          {!leverageabilityFileUploaded && !errorMessage && <BrowseFilesButton text="Browse" handleLeverageabilityUpload={handleLeverageabilityUpload} />}

          {leverageabilityFileUploaded && !errorMessage && (
            <div>
              <Button variant="outline-primary" className="mr-3" onClick={() => handleGetLeverageabilityData(quotaGroup.quotaGroupId)}>
                {leverageabilityDataIsLoading ? <Spinner animation="border" variant="primary" size="sm" /> : "View"}
              </Button>
              <BrowseFilesButton text="Change" handleLeverageabilityUpload={handleLeverageabilityUpload} />
            </div>
          )}
        </div>
        {errorMessage && (
          <div className="pt-4">
            <Row className="w-100">
              <Col sm={11}>
                <ProgressBar now={100} label="Upload complete" />
              </Col>
              <Col sm={1}>
                {isLoading ? (
                  <Spinner animation="border" size="sm" variant="danger" />
                ) : (
                  <DeleteButton
                    className="font-18"
                    onClick={() => {
                      handleLeverageabilityDelete(draftId, setErrorMessage, setIsLoading, quotaGroup.quotaGroupId);
                    }}
                  />
                )}
              </Col>
            </Row>

            <Row className="w-100">
              <Col sm={11} className="d-flex justify-content-end">
                <p>{`${fileName} - ${fileSize} total`}</p>
              </Col>
            </Row>

            <FileUploadErrors errorMessage={errorMessage} />
          </div>
        )}
      </td>
      {/* TODO: Remove inline styles when Lieberman feedback changes are implemented*/}
      <td style={{ maxWidth: 100 }}>
        {!leverageabilityFileUploaded && !errorMessage && (
          <div className="mt-2 text-center">
            <ErrorText text="Not Uploaded" />
          </div>
        )}
        {errorMessage && <ErrorText text="File has errors" hasIcons />}
        {leverageabilityFileUploaded && !errorMessage && <SuccessText text="Uploaded and verified" hasIcons />}
      </td>
    </tr>
  );
};

export default LeverageabilityUploadRow;
