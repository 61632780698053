import "normalize.css";
import "assets/css/bootstrap.min.css";
import "assets/css/metisMenu.min.css";
import "assets/css/sweetalert2.min.css";
import "assets/css/jquery.steps.css";
import "styles/icons.scss";
import "styles/style.scss";

import React, { useState, useEffect } from "react";
// Use absolute imports.
// /src is aliased in jsconfig so you can just write "comps/FolderName", instead of using relative imports like "./../components/FolderName"
// Same will work for any folder created in /src folder
// You can use VS Code auto import, it will work like this
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Layout } from "components/Layout";
import { Route } from "react-router";
import { Switch } from "react-router-dom";
import { ProtectedRoute, ProtectedLogin } from "components/Routing";
import { getCookie } from "utility/cookie";
import {
  Login,
  Register,
  ForgotPassword,
  Dashboard,
  ResetPassword,
  NotApproved,
  Users,
  Projects,
  CreateProject,
  ProjectSingle,
  Simulation,
  CreateSimulation,
  Account,
} from "views";
import { userApprovalStatus, resetResponse, fetchCurrentUserData } from "actions/userAuthenticationActions";
import ROUTES from "constants/routes";

const App = ({ user, fetchCurrentUserData }) => {
  let cookie = getCookie("AUTH_COOKIE");
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(!!(user.isLoggedIn || cookie));
  const [userIsApproved, setUserIsApproved] = useState(!!(cookie && JSON.parse(localStorage.getItem("isApproved"))));
  // const [userIsLoggedIn, setUserIsLoggedIn] = useState(user.isLoggedIn && cookie);
  // const [userIsApproved, setUserIsApproved] = useState(cookie && JSON.parse(localStorage.getItem("isApproved")));

  useEffect(() => {
    if (!userIsLoggedIn) {
      return;
    }
    fetchCurrentUserData();
  }, [userIsLoggedIn, fetchCurrentUserData]);

  useEffect(() => {
    if (user.isLoggedIn && cookie) {
      setUserIsLoggedIn(true);
    }
    if (cookie && JSON.parse(localStorage.getItem("isApproved"))) {
      setUserIsApproved(true);
    }
  }, [user.isLoggedIn, user.isApproved, cookie]);

  return (
    <Layout>
      <Switch>
        <ProtectedRoute
          exact
          path={ROUTES.index}
          userIsLoggedIn={userIsLoggedIn}
          userIsApproved={userIsApproved}
          childComponent={<Projects />}
          documentTitle={"Projects"}
          component={Dashboard}
        />
        <ProtectedLogin exact path={ROUTES.login} userIsLoggedIn={userIsLoggedIn} userIsApproved={userIsApproved} component={Login} documentTitle={"Login"} />
        <ProtectedRoute
          exact
          path={ROUTES.notApproved}
          userIsLoggedIn={userIsLoggedIn}
          userIsApproved={userIsApproved}
          component={NotApproved}
          documentTitle={"Not Approved"}
        />
        <ProtectedRoute
          exact
          path={ROUTES.projects}
          userIsLoggedIn={userIsLoggedIn}
          userIsApproved={userIsApproved}
          component={Dashboard}
          documentTitle={"Projects"}
          childComponent={<Projects />}
        />
        <ProtectedRoute
          exact
          path={ROUTES.createProject()}
          userIsLoggedIn={userIsLoggedIn}
          userIsApproved={userIsApproved}
          component={Dashboard}
          documentTitle={"Create new project"}
          childComponent={<CreateProject />}
        />
        <ProtectedRoute
          exact
          path={ROUTES.singleProject(":id")}
          userIsLoggedIn={userIsLoggedIn}
          userIsApproved={userIsApproved}
          component={Dashboard}
          childComponent={<ProjectSingle />}
        />
        <ProtectedRoute
          exact
          path={ROUTES.simulation(":id")}
          userIsLoggedIn={userIsLoggedIn}
          userIsApproved={userIsApproved}
          component={Dashboard}
          childComponent={<Simulation />}
        />
        <ProtectedRoute
          exact
          path={ROUTES.createSimulation(":id")}
          userIsLoggedIn={userIsLoggedIn}
          userIsApproved={userIsApproved}
          component={Dashboard}
          documentTitle={"Create new simulation"}
          childComponent={<CreateSimulation />}
        />
        <ProtectedRoute
          exact
          path={ROUTES.users}
          userIsLoggedIn={userIsLoggedIn}
          userIsApproved={userIsApproved}
          component={Dashboard}
          documentTitle={"Users"}
          childComponent={<Users />}
        />
        <ProtectedRoute
          exact
          path={ROUTES.account}
          userIsLoggedIn={userIsLoggedIn}
          userIsApproved={userIsApproved}
          component={Dashboard}
          childComponent={<Account />}
        />
        <ProtectedRoute exact path={ROUTES.support} userIsLoggedIn={userIsLoggedIn} userIsApproved={userIsApproved} component={Dashboard} />
        <Route exact path={ROUTES.register} component={Register} />
        <Route exact path={ROUTES.forgotPassword()} component={ForgotPassword} />
        <Route exact path={ROUTES.resetPassword()} component={ResetPassword} />
      </Switch>
    </Layout>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ userApprovalStatus, resetResponse, fetchCurrentUserData }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
