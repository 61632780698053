import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { MessagesStep, QuotaGroupsStep, LeverageabilityStep, ReachStep, ProjectCreated } from "components/Wizards";
import { useLocation } from "react-router-dom";
import { useWizard } from "utility/hooks";
import { completeWizard } from "apis/projectApi";
import { Wizard } from "components/Wizards";
import ReviewAndCompleteStep from "components/Wizards/CreateProjectWizard/ReviewAndCompleteStep";
import { connect } from "react-redux";
import { ErrorText } from "components/Text";

const CreateProject = ({ user }) => {
  const { state: routerState } = useLocation();
  const draftId = routerState.draftId;
  const redirectTimeout = 5000;
  const { activeWizardStep, goToNextStep, goToPrevStep, handleSelectStep } = useWizard(routerState.initialWizardStep || 1);
  const [messages, setMessages] = useState(routerState.messages || []);
  const [quotaGroups, setQuotaGroups] = useState(routerState.quotaGroups || []);
  const [leverageabilityValidated, setLeverageabilityValidated] = useState(false);
  const [reach, setReach] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [projectPassword, setProjectPassword] = useState("");
  //Validation will not be triggered until this is set to true
  const [firstValidation, setFirstValidation] = useState(false);
  const [passwordIsValid, setPasswordIsValid] = useState(false);

  const [limitProjectAccess, setLimitProjectAccess] = useState(false);
  const [projectCanBeSubmitted, setProjectCanBeSubmitted] = useState(false);

  const [maxMessageSet, setMaxMessageSet] = useState(user && user.currentUser && user.currentUser.userType === 0 ? 2 : 5);
  const [resultsSetSize, setResultsSetSize] = useState(20000);

  const [wizardCompleted, setWizardCompleted] = useState(false);
  const [projectCreated, setprojectCreated] = useState(false);
  const [projectCreationError, setProjectCreationError] = useState("");

  const handleMessagesData = (messages) => {
    setMessages(messages || []);
  };

  const handleQuotaGroupsData = (quotaGroups) => {
    setQuotaGroups(quotaGroups || []);
  };

  const changeLeverageabilityValidation = (value) => {
    setLeverageabilityValidated(value);
  };

  const handleReachData = (reach) => {
    let reachArray = [];
    //Converts outer object to array. Final result is array of two objects
    if (reach) {
      reachArray = Object.keys(reach).map((key) => reach[key]);
    }
    setReach(reachArray);
  };

  const handleProjectNameInput = (event) => {
    setProjectName(event.target.value);
  };

  const handleResultsSetSizeInput = (event) => {
    let setSize = event.target.value;
    if (setSize < 1) {
      setSize = 20000;
    }
    setResultsSetSize(parseInt(setSize));
  };

  const handlePasswordInput = (event) => {
    const password = event.target.value;
    setProjectPassword(password);
    if (password.length < 5 && firstValidation) {
      setPasswordIsValid(false);
    } else if (password.length >= 5) {
      setPasswordIsValid(true);
    }
  };

  const handleCheckbox = () => {
    setLimitProjectAccess(!limitProjectAccess);
    setProjectPassword("");
    setFirstValidation(false);
  };

  const handleSelectMaxMessageSet = (value) => {
    setMaxMessageSet(value);
  };

  const handleCompleteWizard = async () => {
    //This part handles password validation logic
    setFirstValidation(true);
    if (limitProjectAccess && !passwordIsValid) {
      return;
    }

    let submitProjectResult = await completeWizard({ projectId: draftId, projectName, projectPassword, maxMessageSet, resultsSetSize });
    if (submitProjectResult.data) {
      switch (submitProjectResult.data.result) {
        case 0:
          setprojectCreated(true);
          setWizardCompleted(true);
          break;
        case 1:
          setProjectCreationError("Undefined error.");
          break;
        case 2:
          setProjectCreationError("You are not authorized to perform this action.");
          break;
        case 3:
          setProjectCreationError(`This account has been deleted. You will be redirected to the login screen in ${redirectTimeout} seconds`);
          break;
        case 4:
          setProjectCreationError("The draft you are trying to complete does not exist.");
          break;
        case 5:
          setProjectCreationError("Project name is missing.");
          break;
        case 6:
          setProjectCreationError("Password has to be at least 5 charaters long.");
          break;
        case 7:
          setProjectCreationError("Error! Upload Reach step has not been completed.");
          break;
        case 8:
          setProjectCreationError("Error. Messages data is missing. Please re-upload the messages file.");
          break;
        case 9:
          setProjectCreationError("Error. Quota groups data is missing. Please re-upload the quota groups file.");
          break;
        case 10:
          setProjectCreationError("Error. Reach data is missing. Please re-upload the reach file.");
          break;
        case 11:
          setProjectCreationError("Error. Message group value has to be between 2 and 8. Restart the wizard and select appropriate message group value.");
          break;
        case 12:
          setProjectCreationError("Error. Max results set size has to be between 1 and 2147483647.");
          break;
        default:
          break;
      }
    }
  };

  //Initial validation of quota groups received in the response. If leverageability file has not been uploaded for a specific quota group, the quota group's ".leverageabilityUploaded" property will be set to false
  useEffect(() => {
    const leverageabilityNotUploaded = (quotaGroup) => !quotaGroup.leverageabilityUploaded;
    if (quotaGroups.length === 0 || quotaGroups.some(leverageabilityNotUploaded)) {
      setLeverageabilityValidated(false);
    } else {
      setLeverageabilityValidated(true);
    }
  }, [quotaGroups]);

  useEffect(() => {
    if (routerState.reach) {
      const reachArray = Object.keys(routerState.reach).map((key) => routerState.reach[key]);
      setReach(reachArray);
    }
  }, [routerState]);

  useEffect(() => {
    if ((limitProjectAccess && projectPassword.length < 1) || projectName.length < 1) {
      setProjectCanBeSubmitted(false);
    } else {
      setProjectCanBeSubmitted(true);
    }
  }, [limitProjectAccess, projectName, projectPassword]);

  return (
    <Container fluid>
      {/* TODO: Make this a separate component */}
      <Row>
        <Col>
          <div className="page-title-box">
            <div className="float-right">
              <ol className="breadcrumb">
                <li className="breadcrumb-item active">Projects</li>
                <li className="breadcrumb-item active">New Project</li>
              </ol>
            </div>
          </div>
        </Col>
      </Row>
      <Wizard activeStep={activeWizardStep} handleSelectStep={handleSelectStep} numberOfSteps={5}>
        <Wizard.Nav>
          <Wizard.NavItem subtitle="Messages" isFinished={messages.length > 1} stepNumber={1} />
          <Wizard.NavItem subtitle="Quota Groups" isFinished={quotaGroups.length > 1} stepNumber={2} />
          <Wizard.NavItem subtitle="Leverageability" isFinished={leverageabilityValidated} stepNumber={3} />
          <Wizard.NavItem subtitle="Reach" isFinished={reach.length > 1} stepNumber={4} />
          <Wizard.NavItem subtitle="Review & Complete" stepNumber={5} />
        </Wizard.Nav>
        <Wizard.Data>
          <Wizard.StepContent>
            <MessagesStep
              messagesData={messages}
              draftId={draftId}
              handleMessagesData={handleMessagesData}
              handleQuotaGroupsData={handleQuotaGroupsData}
              changeLeverageabilityValidation={changeLeverageabilityValidation}
              handleReachData={handleReachData}
            />
            <Wizard.Footer
              activeStep={activeWizardStep}
              handleGoToPrevStep={goToPrevStep}
              handleGoToNextStep={goToNextStep}
              nextButtonDisabled={messages.length < 1}
            />
          </Wizard.StepContent>
          <Wizard.StepContent>
            <QuotaGroupsStep
              quotaGroupsData={quotaGroups}
              draftId={draftId}
              handleQuotaGroupsData={handleQuotaGroupsData}
              changeLeverageabilityValidation={changeLeverageabilityValidation}
              handleReachData={handleReachData}
            />
            <Wizard.Footer
              activeStep={activeWizardStep}
              handleGoToPrevStep={goToPrevStep}
              handleGoToNextStep={goToNextStep}
              nextButtonDisabled={quotaGroups.length < 1}
            />
          </Wizard.StepContent>
          <Wizard.StepContent>
            <LeverageabilityStep
              draftId={draftId}
              quotaGroupsData={quotaGroups}
              changeLeverageabilityValidation={changeLeverageabilityValidation}
              handleQuotaGroupsData={handleQuotaGroupsData}
              handleReachData={handleReachData}
            />
            <Wizard.Footer
              activeStep={activeWizardStep}
              handleGoToPrevStep={goToPrevStep}
              handleGoToNextStep={goToNextStep}
              nextButtonDisabled={!leverageabilityValidated}
            />
          </Wizard.StepContent>
          <Wizard.StepContent>
            <ReachStep reachData={reach} draftId={draftId} handleReachData={handleReachData} />
            <Wizard.Footer
              activeStep={activeWizardStep}
              handleGoToPrevStep={goToPrevStep}
              handleGoToNextStep={goToNextStep}
              nextButtonDisabled={reach.length < 1}
            />
          </Wizard.StepContent>
          <Wizard.StepContent>
            {!wizardCompleted && (
              <>
                <ReviewAndCompleteStep
                  numberOfMessages={messages.length}
                  numberOfQuotaGroups={quotaGroups.length}
                  numberOfRespondents={reach[1] ? reach[1].length : 0}
                  projectName={projectName}
                  projectPassword={projectPassword}
                  firstValidation={firstValidation}
                  passwordIsValid={passwordIsValid}
                  handleProjectNameInput={handleProjectNameInput}
                  handlePasswordInput={handlePasswordInput}
                  limitProjectAccess={limitProjectAccess}
                  handleCheckbox={handleCheckbox}
                  handleSelectStep={handleSelectStep}
                  maxMessageGroup={maxMessageSet}
                  resultsSetSize={resultsSetSize}
                  handleResultsSetSizeInput={handleResultsSetSizeInput}
                  handleSelectMaxMessageSet={handleSelectMaxMessageSet}
                  projectCreationError={projectCreationError}
                />
                <div className="d-flex justify-content-end">
                  <ErrorText text={projectCreationError} />
                </div>
                <Wizard.Footer
                  activeStep={activeWizardStep}
                  handleGoToPrevStep={goToPrevStep}
                  handleGoToNextStep={handleCompleteWizard}
                  nextButtonDisabled={!projectCanBeSubmitted || (firstValidation && !passwordIsValid)}
                />
              </>
            )}
            {wizardCompleted && (
              <ProjectCreated
                projectId={draftId}
                projectCreated={projectCreated}
                errorText={projectCreationError}
                showReviewAndCompleteScreen={setWizardCompleted}
              />
            )}
          </Wizard.StepContent>
        </Wizard.Data>
      </Wizard>
    </Container>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps, null)(CreateProject);
