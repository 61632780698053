import { useEffect } from "react";
import { scrollToElement } from "utility/scrollToElement";

const useScrollToElement = ({ elementRef, startScroll, offset, duration }) => {
  useEffect(() => {
    if (!elementRef || !elementRef.current) return;
    if (startScroll) {
      scrollToElement({ element: elementRef.current, offset, duration });
    }
  }, [startScroll, elementRef, offset, duration]);
};

export default useScrollToElement;
