import React from "react";
import { connect } from "react-redux";
import { RegistrationWrapper } from "components/Layout";
import { SelectUserTypeCard } from "components/Cards";
import { RegistrationForm } from "components/Forms";

const Register = ({ userType }) => {
  return (
    <RegistrationWrapper userType={userType}>
      {userType === null ? <SelectUserTypeCard /> : null}
      {userType !== null ? <RegistrationForm /> : null}
    </RegistrationWrapper>
  );
};

const mapStateToProps = (state) => ({ userType: state.userRegistration.userType });

export default connect(mapStateToProps)(Register);
