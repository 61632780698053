import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button, Spinner, FormCheck } from "react-bootstrap";
import { useEffectOnce } from "react-use";
import { CustomEntriesSelect, ProjectsTable, CustomEntriesDisplay, TablePagination } from "components/Tables";
import { CustomSearchInputField } from "components/Forms";
import { getDrafts, deleteProject, createProject, getProjects } from "apis/projectApi";
import { useDebounceAfterMount } from "utility/hooks";
import { ErrorText } from "components/Text";
import { CreateNewProjectModal } from "components/Modals";
import { changeRoute } from "utility/routing";
import ROUTES from "constants/routes";
import { connect } from "react-redux";

const Projects = ({ user }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const redirectTimeout = 5000;
  const [draft, setDraft] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [tableIsLoading, setTableIsLoading] = useState(false);

  //Projects access status
  const [showClosedProjects, setShowClosedProjects] = useState(false);
  const handleToggleClosedProjects = () => {
    setShowClosedProjects(!showClosedProjects);
  };

  //Get projects data
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalProjectsCount, setTotalProjectsCount] = useState(null);
  const [projects, setProjects] = useState([]);
  const [orderIsDescending, setOrderIsDescending] = useState(true);
  const [activeColumn, setActiveColumn] = useState(2);
  const [searchString, setSearchString] = useState("");

  const handleGetProjects = async ({ page, pageSize, sortOrder, sortBy, searchString, isManuallyTriggered, showClosedProjects = false }) => {
    if (isManuallyTriggered) {
      setTableIsLoading(true);
    }
    let getProjectsResult = await getProjects(page, pageSize, sortOrder, sortBy, searchString, showClosedProjects);
    if (isManuallyTriggered) {
      setTableIsLoading(false);
    }
    if (getProjectsResult.data) {
      switch (getProjectsResult.data.result) {
        case 0:
          setProjects(getProjectsResult.data.items);
          setPage(getProjectsResult.data.page);
          //todo no idea why is this here, commented for now
          setPageSize(getProjectsResult.data.pageSize);
          setTotalProjectsCount(getProjectsResult.data.count);
          break;
        case 1:
          setErrorMessage(`This account has been deleted. You will be redirected to the login screen in ${redirectTimeout} seconds`);
          break;
        case 2:
          setErrorMessage("Page parameter error");
          break;
        case 3:
          setErrorMessage("Page size parameter error");
          break;
        case 4:
          setErrorMessage("Undefined error");
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleGetProjects({
        page,
        pageSize,
        sortOrder: orderIsDescending,
        sortBy: activeColumn,
        searchString,
        isManuallyTriggered: false,
        showClosedProjects,
      });
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, [page, pageSize, orderIsDescending, activeColumn, searchString, showClosedProjects]);

  const [,] = useDebounceAfterMount(
    () => {
      handleGetProjects({
        page,
        pageSize,
        sortOrder: orderIsDescending,
        sortBy: activeColumn,
        searchString,
        isManuallyTriggered: true,
        showClosedProjects,
      });
    },
    1000,
    [searchString, activeColumn, orderIsDescending, showClosedProjects]
  );

  const handleSearchInput = (event) => {
    setSearchString(event.target.value);
  };

  const handleSelectPageSize = (pageSize) => {
    setPageSize(pageSize);
    handleGetProjects({ page, pageSize, sortOrder: orderIsDescending, sortBy: activeColumn, searchString, showClosedProjects });
  };

  const handlePageChange = (goToPage) => {
    setPage(goToPage);
    handleGetProjects({ page: goToPage, pageSize, sortOrder: orderIsDescending, sortBy: activeColumn, searchString, showClosedProjects });
  };

  const handleSelectSortingOrder = ({ selectedColumn }) => {
    setTableIsLoading(true);
    //First part of if statement toggles the arrows on the selected column
    if (activeColumn === selectedColumn) {
      setOrderIsDescending(!orderIsDescending);
    }
    //This sets the default arrow to ASCENDING when the focus is changed to another column
    else {
      setActiveColumn(selectedColumn);
      setOrderIsDescending(false);
    }
  };

  const handleModalOpen = (setShowModal) => {
    setShowModal(true);
  };

  const handleModalClose = (setShowModal, setErrorMessage, getProjects = false) => {
    setShowModal(false);
    setErrorMessage("");

    if (getProjects) {
      handleGetProjects({ page, pageSize, sortOrder: orderIsDescending, sortBy: activeColumn, searchString, showClosedProjects });
    }
  };

  const createProjectWrapper = async () => {
    let createProjectResult = await createProject();
    if (createProjectResult.data) {
      switch (createProjectResult.data.result) {
        //Successfully created blank project
        case 1:
          changeRoute(ROUTES.createProject(), {
            draftId: createProjectResult.data.projectId,
          });
          break;
        case 6:
          //TODO: Maybe logout and reroute the user to a special screen saying their account does not exist anymore?
          //TODO: Create a function
          setErrorMessage(`This account has been deleted. You will be redirected to the login screen in ${redirectTimeout} seconds.`);
          break;
        case 7:
          setErrorMessage("Undefined error");
          break;
        default:
          break;
      }
    }
    //TODO: Handle axios error
  };

  const handleAddNewProject = async () => {
    setIsLoading(true);
    let getDraftsResult = await getDrafts();
    setIsLoading(false);
    if (getDraftsResult.data) {
      switch (getDraftsResult.data.result) {
        //Draft found
        case 0:
          setDraft({
            initialWizardStep: getDraftsResult.data.projectStep,
            draftId: getDraftsResult.data.draftId,
            messages: getDraftsResult.data.messages,
            quotaGroups: getDraftsResult.data.quotaGroups,
            reach: getDraftsResult.data.reach,
          });
          break;
        //NO drafts found -> new project is created and user is routed to first step of the wizard
        case 1:
          createProjectWrapper();
          break;
        //Current user is not the same user that created the draft
        case 2:
          setErrorMessage("You are not authorized to access this draft");
          break;
        //User does not exist
        case 3:
          //TODO: Maybe logout and reroute the user to a special screen saying their account does not exist anymore?
          //TODO: Create a function
          setErrorMessage(`This account has been deleted. You will be redirected to the login screen in ${redirectTimeout} seconds.`);
          break;
        case 4:
          setErrorMessage("Undefined error");
          break;
        default:
          break;
      }
    }
    //TODO: Handle axios error
  };

  const handleDiscardDraft = async () => {
    let deleteProjectResult = await deleteProject(draft.draftId);
    if (deleteProjectResult.data) {
      switch (deleteProjectResult.data.result) {
        //Draft deleted successfully -> new project is created and user is routed to first step of the wizard
        case 0:
          createProjectWrapper();
          break;
        case 1:
          setErrorMessage("You are not authorized to delete this draft");
          break;
        case 2:
          //TODO: Maybe logout and reroute the user to a special screen saying their account does not exist anymore?
          //TODO: Create a function
          setErrorMessage(`This account has been deleted. You will be redirected to the login screen in ${redirectTimeout} seconds`);
          break;
        case 3:
          setErrorMessage("The draft you are trying to delete does not exist");
          break;
        case 4:
          setErrorMessage("Undefined error");
          break;
        default:
          break;
      }
    }
  };

  const handleUseDraft = () => {
    changeRoute(ROUTES.createProject(), draft);
  };

  const handleDeleteProject = async (projectId, setIsLoading, setIsDeleteSuccessful, setErrorMessage) => {
    setIsLoading(true);
    let deleteProjectResult = await deleteProject(projectId);
    setIsLoading(false);
    if (deleteProjectResult.data) {
      switch (deleteProjectResult.data.result) {
        //Draft deleted successfully -> new project is created and user is routed to first step of the wizard
        case 0:
          setIsDeleteSuccessful(true);
          break;
        case 1:
          setErrorMessage("You are not authorized to delete this project");
          break;
        case 2:
          //TODO: Maybe logout and reroute the user to a special screen saying their account does not exist anymore?
          //TODO: Create a function
          setErrorMessage(`This account has been deleted. You will be redirected to the login screen in ${redirectTimeout} seconds`);
          break;
        case 3:
          setErrorMessage("The project you are trying to delete does not exist");
          break;
        case 4:
          setErrorMessage("Undefined error");
          break;
        default:
          break;
      }
    }
  };

  //Initial fetch of projects data with default values and ordering
  useEffectOnce(() => {
    handleGetProjects({ page: page, pageSize, sortOrder: true, sortBy: 2 });
  });

  return (
    <>
      <CreateNewProjectModal
        showModal={showModal}
        onModalClose={() => handleModalClose(setShowModal, setErrorMessage)}
        onDiscardDraft={handleDiscardDraft}
        onUseDraft={handleUseDraft}
        errorMessage={errorMessage}
      />
      <div className="container-fluid">
        <Row>
          <Col sm="12">
            <div className="page-title-box">
              <div className="float-right">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item active">Projects</li>
                </ol>
              </div>
              <h4 className="page-title">Projects</h4>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <Card className="card">
              <Card.Body>
                <Row className="mx-0 mb-2">
                  <Col sm={12} md={6} className="px-0">
                    <div>
                      {user && user.currentUser && user.currentUser.userType !== 2 && (
                        <div className="d-flex">
                          <Button
                            className="btn btn-primary px-4 mt-0 mb-3"
                            onClick={() => {
                              setErrorMessage("");
                              handleModalOpen(setShowModal);
                              handleAddNewProject();
                            }}
                          >
                            {!isLoading && <i className="mdi mdi-plus-circle-outline mr-2"></i>}
                            {isLoading ? <Spinner as="div" size="sm" animation="border" variant="light" /> : "Add New Project"}
                          </Button>
                          {errorMessage && <ErrorText text={errorMessage} className="mt-2 ml-3" />}
                        </div>
                      )}
                    </div>
                    <CustomEntriesSelect onChange={handleSelectPageSize} page={page} pageSize={pageSize} />
                  </Col>
                  <Col sm={12} md={6} className="px-0 d-flex flex-column justify-content-between align-items-end">
                    {user && user.currentUser && user.currentUser.userType !== 2 && (
                      <FormCheck
                        id="enableSwitch"
                        type="switch"
                        label="Show closed projects"
                        checked={showClosedProjects}
                        onChange={handleToggleClosedProjects}
                      />
                    )}
                    <CustomSearchInputField onChange={handleSearchInput} />
                  </Col>
                </Row>

                <ProjectsTable
                  projects={projects}
                  handleSelectSortingOrder={handleSelectSortingOrder}
                  activeColumn={activeColumn}
                  orderIsDescending={orderIsDescending}
                  handleDeleteProject={handleDeleteProject}
                  onModalOpen={handleModalOpen}
                  onModalClose={handleModalClose}
                  tableIsLoading={tableIsLoading}
                />
                <div className="d-flex justify-content-between">
                  <CustomEntriesDisplay totalEntries={totalProjectsCount} page={page} pageSize={pageSize} />
                  <TablePagination
                    currentPage={page}
                    pageSize={pageSize}
                    sortingOrder={orderIsDescending}
                    sortBy={activeColumn}
                    totalEntries={totalProjectsCount}
                    onChange={handlePageChange}
                  />
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps, null)(Projects);
// export default withRouter(Projects);
